import { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import logo from "../brand/logo.png";
import { InputField, Button, Text, Image, SVG } from "./UI";
import icons from "../assets/icons";

import { useAuth, useModal, useNotification, useStore } from "../hooks";
import InputModal from "./modal/InputModal";
import { sendResetPasswordEmail } from "../firebase/auth";
import { NEW_IDENTIFIER } from "../store";

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
	}
`;

const SLogin = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${(p) => p.theme.colors.main.primary};

    display: grid;
    place-items: center;
`;

const SContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    min-height: 10rem;
    min-width: 10rem;
`;

const SPanel = styled.div`
    padding: 1.25rem;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 0.625rem;

    display: grid;
    grid-gap: 1.375rem;
    width: 18.875rem;

    // Animation.
    animation: ${fadeInAnimation} ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

const SFeedback = styled.div`
    padding: 0.5rem 1.25rem;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 0.625rem;

    display: grid;
    min-height: 4rem;
    width: 18.875rem;

    grid-template-columns: 2.25rem auto;
    grid-gap: 1.25rem;
    align-items: center;

    // Animation.
    animation: ${fadeInAnimation} ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

const STop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 3.438rem;
`;

const STopButton = styled.div<{ selected?: boolean }>`
    border-bottom: 0.1rem solid
        ${(p) =>
            p.selected
                ? p.theme.colors.main.primary
                : p.theme.colors.neutral["10"]};
    cursor: pointer;
    :hover {
        border-bottom: 0.1rem solid ${(p) => p.theme.colors.main.primary};
    }
`;

const SLogo = styled.div`
    width: 11rem;
    height: 3rem;
    margin: auto;
`;

const SClose = styled.div`
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const SError = styled.div`
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.information.error};
`;

const spinAnimation = keyframes`
	0% { transform: scale(.4);}
	100% { transform: scale(.6); }
`;

export const SIcon = styled.div`
    height: 100%;
    animation: ${spinAnimation} alternate 1s;
    animation-iteration-count: infinite;
    opacity: 0.9;
`;

interface IFeedback {
    title: string;
    message: string;
}

interface IState {
    email: string;
    password: string;
    confirmPassword: string;
    loading: boolean;
    feedback?: IFeedback;
}

const Login: FC = () => {
    const [state, set] = useState<IState>({
        email: "",
        password: "",
        confirmPassword: "",
        loading: false,
    });
    const [tab, setTab] = useState<"login" | "register">("login");
    const auth = useAuth();
    const modal = useModal();
    const notification = useNotification();
    const store = useStore();

    useEffect(() => {
        set((p) => ({ ...p, feedback: undefined }));
    }, [auth.state.isPanelActive]);

    async function confirmHandler(): Promise<void> {
        const feedbackTitle = `${
            tab === "login" ? "Login" : "Register"
        } failed`;
        const feedback = (message: string) =>
            set((p) => ({ ...p, feedback: { title: feedbackTitle, message } }));

        if (state.email === "" || state.password === "") {
            feedback("Please complete all fields.");
            return;
        }
        if (state.password.length < 6) {
            feedback("Password must be at least 6 characters long.");
            return;
        }

        // REGISTER.
        if (tab === "register") {
            if (state.confirmPassword != state.password) {
                feedback("Passwords don't match");
                return;
            }

            set((p) => ({ ...p, loading: true }));

            auth.logOut();
            try {
                const newUser = await auth.register(
                    { id: NEW_IDENTIFIER, email: state.email },
                    state.password
                );
                store.dispatch({
                    type: "user",
                    action: "set",
                    object: newUser,
                    onAdd: async (addedUser) => {
                        await auth.logIn(state.email, state.password);
                        set((p) => ({ ...p, loading: false }));
                    },
                });
            } catch {
                set((p) => ({ ...p, loading: false }));
            }
        }

        // LOGIN.
        else {
            set((p) => ({ ...p, loading: true }));
            try {
                await auth.logIn(state.email, state.password);
            } catch (error) {
                try {
                    //@ts-ignore
                    feedback(error?.message);
                } catch {}
            }
            set((p) => ({ ...p, loading: false }));
        }

        set((p) => ({ ...p, email: "", password: "", confirmPassword: "" }));
    }

    function resetPasswordHandler(): void {
        modal.setContent(
            <InputModal
                icon={icons.key}
                title="Reset password"
                subTitle="Please enter your email address to request a password reset"
                confirmHandler={async (email) => {
                    notification.notify("Sending email...", "loading");
                    try {
                        await sendResetPasswordEmail(email);
                        notification.notify("Email sent.", "ok");
                    } catch {
                        notification.notify("Couldn't send email", "error");
                    }
                    notification.killType("loading");
                }}
            />
        );

        modal.setActive(true);
    }

    if (!auth.state.isPanelActive && !auth.state.isLoading) return null;

    return (
        <SLogin>
            {auth.state.isLoading || state.loading ? (
                <SContainer>
                    <SIcon>
                        <SVG image={icons.vrArDevice} contain light />
                    </SIcon>
                </SContainer>
            ) : (
                <SContainer>
                    <SLogo>
                        <Image contain image={logo} />
                    </SLogo>
                    <SPanel>
                        <InputField
                            onEnter={confirmHandler}
                            grayIcon
                            title="Email"
                            type="email"
                            icon={icons.envelope}
                            value={state.email}
                            onChange={(v) => set((p) => ({ ...p, email: v }))}
                        />
                        <InputField
                            onEnter={confirmHandler}
                            grayIcon
                            title="Password"
                            type="password"
                            icon={icons.key}
                            value={state.password}
                            onChange={(v) =>
                                set((p) => ({ ...p, password: v }))
                            }
                        />
                        {tab === "register" && (
                            <InputField
                                onEnter={confirmHandler}
                                grayIcon
                                title="Confirm Password"
                                type="password"
                                icon={icons.key}
                                value={state.confirmPassword}
                                onChange={(v) =>
                                    set((p) => ({ ...p, confirmPassword: v }))
                                }
                            />
                        )}
                        {tab === "login" && (
                            <Text
                                center
                                semiBold
                                H5
                                onClick={() => resetPasswordHandler()}
                                oneline
                                primary
                            >
                                Forgot your password?
                            </Text>
                        )}
                        <Button onClick={() => confirmHandler()}>
                            {tab === "login" ? "Login" : "Register"}
                        </Button>
                    </SPanel>

                    {state.feedback && (
                        <SFeedback>
                            <SError>
                                <SVG image={icons.close} light contain />
                            </SError>
                            <div>
                                <Text H5 semiBold oneline error>
                                    {state?.feedback?.title}
                                </Text>
                                <Text H6 semiBold medium>
                                    {state?.feedback?.message}
                                </Text>
                            </div>
                        </SFeedback>
                    )}
                </SContainer>
            )}
        </SLogin>
    );
};

export default Login;
