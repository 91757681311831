export const firebaseConfig = {
    apiKey: "AIzaSyDUaDCcg-vCnBFlGa5LvPjIcbleYc1Pdmk",
    authDomain: "tdxrframework.firebaseapp.com",
    databaseURL:
        "https://tdxrframework-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "tdxrframework",
    storageBucket: "tdxrframework.appspot.com",
    messagingSenderId: "1012879424850",
    appId: "1:1012879424850:web:03916a18da544e43740e8b",
    measurementId: "G-8V9XDT3XSK",
};

export const hlsConvertConfig = {
    cloudFunction:
        "https://us-central1-tdxrframework.cloudfunctions.net/createHLSVideo ",

    getHlsUrl: (root: string, id: string) =>
        `https://storage.googleapis.com/${firebaseConfig.projectId}.appspot.com/${root}videos/${id}/playlist.m3u8`,
    getDestinationPath: (root: string, id: string) =>
        `${firebaseConfig.projectId}.appspot.com/${root}videos/${id}`,
};
