import { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { SVG } from "./UI";
import icons from "../assets/icons";

import { useStore } from "../hooks";

const SLoading = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${(p) => p.theme.colors.main.primary};

    display: grid;
    place-items: center;
`;

const SContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    min-height: 10rem;
    min-width: 10rem;
`;

const spinAnimation = keyframes`
	0% { transform: scale(.4);}
	100% { transform: scale(.6); }
`;

export const SIcon = styled.div`
    height: 100%;
    animation: ${spinAnimation} alternate 1s;
    animation-iteration-count: infinite;
    opacity: 0.9;
`;

const Loading: FC = () => {
    const [hide, setHide] = useState(false);
    const store = useStore();

    useEffect(() => {
        let timeOut: any;

        if (store.state.loading) setHide(false);
        else timeOut = setTimeout(() => setHide(true), 1000);

        return () => {
            if (timeOut) clearTimeout(timeOut);
        };
    }, [store.state.loading]);

    return hide ? null : (
        <SLoading>
            <SContainer>
                <SIcon>
                    <SVG image={icons.vrArDevice} contain light />
                </SIcon>
            </SContainer>
        </SLoading>
    );
};

export default Loading;
