import React, { FC, useState } from 'react'
import { DefaultTheme } from 'styled-components'
import defaultTheme from '../brand/theme'

const Theme = React.createContext({})

export interface ITheme {
	theme: DefaultTheme,
	setTheme(theme: DefaultTheme): void,
}

export const ThemeContext: FC = (props) => {
	const [theme, setTheme] = useState<DefaultTheme>(defaultTheme)

	const value: ITheme = {
		theme,
		setTheme
	}

	return (
		<Theme.Provider value={value}>
			{props.children}
		</Theme.Provider>
	)
}

export default Theme