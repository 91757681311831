import { FC, ReactElement } from "react";
import styled from "styled-components";
import icons from "../assets/icons";
import { Paths } from "../context/RouterContext";
import { useRouter } from "../hooks";
import { SVG, Text } from "./UI";

const SNavbar = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    padding: 0 1.5rem;
`;

const SGrid = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    grid-auto-flow: column;
    height: 100%;
    width: min-content;
`;

const SBack = styled.div`
    display: grid;
    grid-template-columns: 1.5rem auto;
    height: 100%;
    cursor: pointer;

    :hover {
        ${Text} {
            color: ${(p) => p.theme.colors.main.primary};
        }
        ${SVG} {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }
`;

interface IProps {
    tabs?: { name: string; path: Paths }[]; // Tab names and paths.
    customTabs?: ReactElement[];
    backHandler?(): void; // Optional back handler function, to add back button.
    keepParams?: boolean; // Should navbar keep params on navegation ?.
}

const Navbar: FC<IProps> = (props) => {
    const router = useRouter();

    function clickHandler(path: Paths): void {
        router.setPath(path, props.keepParams);
    }

    function isSelected(path: Paths): boolean {
        return path === router.state.path;
    }

    return (
        <SNavbar>
            <SGrid>
                {props.backHandler && (
                    <SBack
                        onClick={() => props.backHandler && props.backHandler()}
                    >
                        <SVG image={icons.back} medium contain />
                        <Text H5 semiBold middle medium>
                            Back
                        </Text>
                    </SBack>
                )}
                {props.tabs &&
                    props.tabs.map((tab) => (
                        <Text
                            key={tab.name}
                            onClick={() => clickHandler(tab.path)}
                            H5
                            semiBold
                            middle
                            oneline
                            medium={!isSelected(tab.path)}
                        >
                            {tab.name}
                        </Text>
                    ))}
                {props.customTabs}
            </SGrid>
        </SNavbar>
    );
};

export default Navbar;
