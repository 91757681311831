import { Dictionary, IApplication } from "../store/scheme";

interface ISortParam {
    order?: number;
}
export function sort(a: ISortParam, b: ISortParam): number {
    const aO = a.order ?? 9999;
    const bO = b.order ?? 9999;
    return aO > bO ? 1 : -1;
}

export class Action<T> {
    private handlers: Array<(data: T) => void> = [];

    public on(handler: (data: T) => void): void {
        this.handlers.push(handler);
    }

    public off(handler: (data: T) => void): void {
        this.handlers = this.handlers.filter((h) => h !== handler);
    }

    public trigger(data: T): void {
        // Duplicate the array to avoid side effects during iteration.
        this.handlers.slice(0).forEach((h) => h(data));
    }
}
