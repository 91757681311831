import React, { FC, useRef } from 'react'
import useState from 'react-usestateref'
import styled from 'styled-components'
import { ChromePicker } from 'react-color'
import { Text } from './UI'
import { useOutsideAlerter } from '../hooks'
// import { apiColorToRealColor, rgbToHex } from '../utility'

const SColorPicker = styled.div`
	position: relative;
	width: 7.75rem;
	display: grid;
	grid-gap: .1rem;
`

const SPickerContainer = styled.div`
	position: absolute;
	z-index: 1;
	bottom: -.5rem;
	transform: translateY(100%);
`

const SLabel = styled.div`
	height: 1.875rem;	
	display: grid;
	grid-template-columns: 1.875rem auto;
	border-radius: 0.313rem;
	border: 0.063rem solid ${p => p.theme.colors.neutral['10']};
	overflow: hidden;

	cursor: pointer;
	:hover {
		${Text} {
			color: ${p => p.theme.colors.main.primary};
		}
	}
`

const ColorPicker: FC<{ title?: string, description?: string, color: string, onChange(color: string): void }> = (props) => {
    const [state, set, stateRef] = useState<{ active: boolean }>({
        active: false
    })
    const ref = useRef()

    useOutsideAlerter(ref, () => {
        if (stateRef?.current?.active) set(p => ({ ...p, active: false }))
    })

    return (
        // @ts-ignore
        <SColorPicker ref={ref}>
            {props.title && <Text H5 semiBold oneline>{props.title}</Text>}
            {props.description && <Text H6 semiBold medium oneline>{props.description}</Text>}
            <SLabel onClick={() => set(p => ({ ...p, active: !p.active }))}>
                <div style={{ backgroundColor: props.color }} />
                <Text middle center H5 semiBold>{props.color}</Text>
            </SLabel>
            {state.active && <SPickerContainer>
                <ChromePicker
                    color={props.color}
                    onChange={color => props.onChange(color.hex)}
                />
            </SPickerContainer>}
        </SColorPicker>
    )
}

export default ColorPicker
