import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { SPageHolder } from "..";
import icons from "../../assets/icons";
import ColorPicker from "../../components/ColorPicker";
import { Title } from "../../components/Common";
import ImageSelector from "../../components/ImageSelector";
import Confirm from "../../components/modal/Confirm";
import { Button, InputField, Text, TextArea } from "../../components/UI";
import { uploadFile } from "../../firebase/storage";
import { useModal, useNotification, useStore } from "../../hooks";
import { IApplication, IApplicationSkin } from "../../store/scheme";
import { SLine } from "../experience/overview";

const STheme = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    padding-bottom: 10rem;
`;

const SImages = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
`;

const SColors = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`;

const SColorRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    width: min-content;
`;

const SSaveButton = styled.div`
    width: 13.25rem;
    margin-left: auto;
`;

const Theme: FC<{ application: IApplication }> = (props) => {
    const [application, setApplication] = useState<IApplication>();
    const store = useStore();
    const modal = useModal();
    const notification = useNotification();

    useEffect(() => {
        setApplication(props.application);
    }, [props.application]);

    async function doSave(): Promise<void> {
        notification.notify("Saving changes", "loading", false);

        async function checkImage(
            key:
                | "primary"
                | "secondary"
                | "about"
                | "background-image"
                | "splash",
            local:
                | "localPrimary"
                | "localSecondary"
                | "localAbout"
                | "localBackgroundImage"
                | "localSplash"
        ) {
            const file = application?.skin?.logos?.[local];
            if (file) {
                const { url } = await uploadFile({
                    file,
                    path: "images",
                });
                //@ts-ignore
                application.skin.logos[key] = url;
                //@ts-ignore
                delete application.skin.logos[local];

                setApplication((p) => ({
                    ...p,
                    skin: {
                        ...(p?.skin ?? {}),
                        logos: {
                            ...(p?.skin?.logos ?? {}),
                            [key]: url,
                            [local]: null,
                        },
                    },
                }));
            }
        }

        // Check images to upload.
        await checkImage("primary", "localPrimary");
        await checkImage("secondary", "localSecondary");
        await checkImage("about", "localAbout");
        await checkImage("background-image", "localBackgroundImage");
        await checkImage("splash", "localSplash");

        store.dispatch({
            type: "application",
            action: "set",
            object: application,
        }); // Dispatch changes.
    }

    function saveHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Save Theme"
                subTitle={`Save "${application?.name}" theme changes`}
                icon={icons.checkmark}
                confirmHandler={() => doSave()}
            />
        );
        modal.setActive(true);
    }

    function getColors() {
        const colors = [
            [
                {
                    cat: "main",
                    key: "primary",
                    title: "Primary",
                    subTitle: "Headers, buttons.",
                },
                {
                    cat: "main",
                    key: "secondary",
                    title: "Secondary",
                    subTitle: "Icons",
                },
                {
                    cat: "main",
                    key: "tertiary",
                    title: "Tertiary",
                    subTitle: "Error, alerts.",
                },
                {
                    cat: "main",
                    key: "light",
                    title: "Light",
                    subTitle: "Windows and screens.",
                },
                {
                    cat: "main",
                    key: "dark",
                    title: "Dark",
                    subTitle: "Windows and screens.",
                },
            ],
            [
                {
                    cat: "texts",
                    key: "primary",
                    title: "Text Primary",
                    subTitle: "Headings, titles.",
                },
                {
                    cat: "texts",
                    key: "secondary",
                    title: "Text Secondary",
                    subTitle: "Subtitles, body text.",
                },
                {
                    cat: "texts",
                    key: "tertiary",
                    title: "Text Tertiary",
                    subTitle: "Others",
                },
            ],
            [
                {
                    cat: "icons",
                    key: "primary",
                    title: "Icon Primary",
                    subTitle: "Hadings, titles",
                },
                {
                    cat: "icons",
                    key: "secondary",
                    title: "Icon Secondary",
                    subTitle: "Subtitules, body",
                },
                {
                    cat: "icons",
                    key: "tertiary",
                    title: "Icon Tertiary",
                    subTitle: "Others",
                },
            ],
        ];

        return colors.map((pack) => (
            <SColorRow>
                {pack.map((i) => (
                    <ColorPicker
                        key={i.cat + i.key}
                        color={
                            // @ts-ignore
                            application?.skin?.colors?.[i.cat]?.[i.key] ??
                            "#ffffff"
                        }
                        onChange={(color) =>
                            setApplication((p) => ({
                                ...p,
                                skin: {
                                    ...(p?.skin ?? { colors: {} }),
                                    colors: {
                                        ...(p?.skin?.colors ?? {}),
                                        [i.cat]: {
                                            //@ts-ignore
                                            ...(p?.skin?.colors?.[i.cat] ?? {}),
                                            [i.key]: color,
                                        },
                                    },
                                },
                            }))
                        }
                        title={i.title}
                        description={i.subTitle}
                    />
                ))}
            </SColorRow>
        ));
    }

    function getImages() {
        return [
            {
                title: "Primary Logo",
                key: "primary",
                local: "localPrimary",
            },
            {
                title: "Secondary Logo",
                key: "secondary",
                local: "localSecondary",
            },
            {
                title: "About Image",
                key: "about",
                local: "localAbout",
            },
            {
                title: "Background Image 360",
                key: "background-image",
                local: "localBackgroundImage",
            },
            {
                title: "Splash Image",
                key: "splash",
                local: "localSplash",
            },
        ].map((data) => (
            <ImageSelector
                key={data.key}
                title={data.title}
                subTitle="Pictures Max dimensions are 1024px height. PNG image, no background."
                height="7.688rem"
                //@ts-ignore
                image={application?.skin?.logos?.[data.key]}
                fileHandler={(file) =>
                    setApplication((p) => ({
                        ...p,
                        skin: {
                            ...(p?.skin ?? {}),
                            logos: {
                                ...(p?.skin?.logos ?? {}),
                                [data.local]: file,
                            },
                        },
                    }))
                }
                deleteHandler={() =>
                    setApplication((p) => ({
                        ...p,
                        skin: {
                            ...p?.skin,
                            logos: {
                                ...(p?.skin?.logos ?? {}),
                                [data.local]: null,
                                [data.key]: "",
                            },
                        },
                    }))
                }
            />
        ));
    }

    return (
        <SPageHolder>
            <STheme>
                <Title>Theme</Title>

                <SImages>{getImages()}</SImages>

                <Text oneline H5 semiBold>
                    Color Scheme
                </Text>

                <SColors> {getColors()} </SColors>

                <SLine />

                <Text oneline H5 semiBold>
                    Information
                </Text>

                <TextArea
                    title="About Us"
                    value={application?.skin?.aboutUs?.description ?? ""}
                    onChange={(description) =>
                        setApplication((p) => ({
                            ...p,
                            skin: {
                                ...(p?.skin ?? {}),
                                aboutUs: {
                                    ...(p?.skin?.aboutUs ?? {}),
                                    description,
                                },
                            },
                        }))
                    }
                />

                <InputField
                    title="Website"
                    value={application?.skin?.aboutUs?.websiteUrl ?? ""}
                    onChange={(websiteUrl) =>
                        setApplication((p) => ({
                            ...p,
                            skin: {
                                ...(p?.skin ?? {}),
                                aboutUs: {
                                    ...(p?.skin?.aboutUs ?? {}),
                                    websiteUrl,
                                },
                            },
                        }))
                    }
                />

                <SSaveButton>
                    <Button onClick={() => saveHandler()}>Save changes</Button>
                </SSaveButton>
            </STheme>
        </SPageHolder>
    );
};

export default Theme;
