import styled, { keyframes, css } from 'styled-components'

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateX(-2rem);
	}
	100% {
		opacity: 1;
	}
`

export const SPageHolder = styled.div`
	animation: ${fadeInAnimation} ease .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

	min-height: 0;
	height: inherit;
`
