import React, { FC, useState } from "react";
import styled from "styled-components";
import icons from "../../assets/icons";
import { useModal, useStore } from "../../hooks";
import { IState } from "../../store";
import { IAsset } from "../../store/scheme";
import { SScroll, Title } from "../Common";
import { InputField, Text } from "../UI";

const SAssetSelector = styled.div`
    width: 70vw;
    height: 60vh;
    display: grid;
    grid-template-rows: min-content min-content auto;
    padding: 0 2rem;
`;

const STopRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 0.5rem;
`;

const AssetSelector: FC<{
    title: string;
    storePath: keyof IState;
    onSelect(id: string): void;
}> = ({ title, storePath, onSelect }) => {
    const store = useStore();
    const modal = useModal();
    const [search, setSearch] = useState("");

    function getAssets() {
        return Object.values(store.state[storePath])
            .filter((a) => {
                if (search === "") return true;
                return a.name
                    ?.toLocaleLowerCase()
                    ?.includes(search?.toLocaleLowerCase());
            })
            .map((a) => (
                <Item
                    key={a.id}
                    onClick={() => {
                        onSelect(a.id);
                        modal.setActive(false);
                    }}
                    asset={a}
                />
            ));
    }

    return (
        <SAssetSelector>
            <Title small>{title}</Title>
            <STopRow>
                <Text middle H6 semiBold>
                    Name
                </Text>
                <Text middle H6 semiBold>
                    Size
                </Text>
                <Text middle H6 semiBold>
                    Date
                </Text>
                <InputField
                    value={search}
                    onChange={(v) => setSearch(v)}
                    placeholder="Search..."
                    icon={icons.search}
                />
            </STopRow>
            <SScroll padding="0">{getAssets()}</SScroll>
        </SAssetSelector>
    );
};

const SItem = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-gap: 0.5rem;
    grid-template-columns: auto 3rem;
    align-items: center;

    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;
    }
`;

const SVideoClick = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const SName = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: min-content auto;
`;

const Item: FC<{ asset: IAsset; onClick(): void }> = ({ asset, onClick }) => {
    return (
        <SItem>
            <SVideoClick onClick={() => onClick()}>
                <SName>
                    <Text middle H6>
                        {asset.name}
                    </Text>
                </SName>
                <Text middle H6>
                    {asset.size}
                </Text>
                <Text middle H6>
                    {asset.date}
                </Text>
            </SVideoClick>
        </SItem>
    );
};

export default AssetSelector;
