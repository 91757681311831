import "styled-components";
import { DefaultTheme } from "styled-components";

declare module "styled-components" {
    export interface DefaultTheme {
        colors: {
            main: {
                primary: string;
                secondary: string;
            };
            icon: {
                light: string;
                medium: string;
                dark: string;
            };
            text: {
                light: string;
                medium: string;
                dark: string;
            };
            information: {
                error: string;
                warning: string;
                success: string;
            };
            neutral: any;
        };

        fontSize: {
            H1: string;
            H2: string;
            H3: string;
            H4: string;
            H5: string;
            H6: string;
        };
    }
}

const Theme: DefaultTheme = {
    colors: {
        main: {
            primary: "#00ADEF",
            secondary: "#102136",
        },
        icon: {
            light: "#FFFFFF",
            medium: "#9A9A9A",
            dark: "#172E4B",
        },
        text: {
            light: "#FFFFFF",
            medium: "#9A9A9A",
            dark: "#172E4B",
        },
        neutral: {
            "00": "#FFFFFF",
            "02": "#FAFAFA",
            "05": "#F2F2F2",
            "10": "#E6E6E6",
            "20": "#CDCDCD",
            "40": "#9A9A9A",
            "60": "#686868",
            "70": "#3A3A3A",
            "80": "#282828",
        },
        information: {
            error: "#ff5c38",
            warning: "#ffcd1e",
            success: "#00d300",
        },
    },
    fontSize: {
        H1: "48px",
        H2: "32px",
        H3: "24px",
        H4: "18.6px",
        H5: "14.6px",
        H6: "12px",
    },
};

export default Theme;
