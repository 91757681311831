import addIcon from "./add.svg";
import arrowIcon from "./arrow.svg";
import backIcon from "./back.svg";
import changeIcon from "./change.svg";
import checkmarkIcon from "./checkmark.svg";
import closeIcon from "./close.svg";
import collection from "./collection.svg";
import copyIcon from "./copy.svg";
import dashboardIcon from "./dashboard.svg";
import deleteIcon from "./delete.svg";
import desktopIcon from "./desktop.svg";
import downIcon from "./down.svg";
import downloadIcon from "./download.svg";
import editIcon from "./edit.svg";
import envelopeIcon from "./envelope.svg";
import experienceIcon from "./experience.svg";
import eyeFalseIcon from "./eye-false.svg";
import eyeTrueIcon from "./eye-true.svg";
import favoriteFalseIcon from "./favorite-false.svg";
import favoriteTrueIcon from "./favorite-true.svg";
import ffIcon from "./ff.svg";
import fileIcon from "./file.svg";
import geoPinIcon from "./geo-pin.svg";
import handleIcon from "./handle.svg";
import helpIcon from "./help.svg";
import homeIcon from "./home.svg";
import imageIcon from "./image.svg";
import informationIcon from "./information.svg";
import interactive360Icon from "./interactive360.svg";
import keyIcon from "./key.svg";
import logoutIcon from "./logout.svg";
import menuIcon from "./menu.svg";
import microphoneFalseIcon from "./microphone-false.svg";
import microphoneTrueIcon from "./microphone-true.svg";
import mobileDeviceIcon from "./mobile-device.svg";
import pauseIcon from "./pause.svg";
import placeholderIcon from "./placeholder.svg";
import playIcon from "./play.svg";
import powerIcon from "./power.svg";
import profileIcon from "./profile.svg";
import quizIcon from "./quiz.svg";
import retryIcon from "./retry.svg";
import rwIcon from "./rw.svg";
import searchIcon from "./search.svg";
import settingsFalseIcon from "./settings-false.svg";
import settingsTrueIcon from "./settings-true.svg";
import shareIcon from "./share.svg";
import storeIcon from "./store.svg";
import switchUserIcon from "./switch-user.svg";
import trackableIcon from "./trackable.svg";
import usersIcon from "./users.svg";
import videoIcon from "./video.svg";
import vrArDeviceIcon from "./vr-ar-device.svg";

const icons = {
    add: addIcon,
    arrow: arrowIcon,
    back: backIcon,
    change: changeIcon,
    checkmark: checkmarkIcon,
    close: closeIcon,
    collection,
    copy: copyIcon,
    dashboard: dashboardIcon,
    delete: deleteIcon,
    desktop: desktopIcon,
    down: downIcon,
    download: downloadIcon,
    edit: editIcon,
    envelope: envelopeIcon,
    experience: experienceIcon,
    eyeFalse: eyeFalseIcon,
    eyeTrue: eyeTrueIcon,
    favoriteFalse: favoriteFalseIcon,
    favoriteTrue: favoriteTrueIcon,
    ff: ffIcon,
    file: fileIcon,
    geoPin: geoPinIcon,
    handle: handleIcon,
    help: helpIcon,
    home: homeIcon,
    image: imageIcon,
    information: informationIcon,
    interactive360: interactive360Icon,
    key: keyIcon,
    logout: logoutIcon,
    menu: menuIcon,
    microphoneFalse: microphoneFalseIcon,
    microphoneTrue: microphoneTrueIcon,
    mobileDevice: mobileDeviceIcon,
    pause: pauseIcon,
    placeholder: placeholderIcon,
    play: playIcon,
    power: powerIcon,
    profile: profileIcon,
    quiz: quizIcon,
    retry: retryIcon,
    rw: rwIcon,
    search: searchIcon,
    settingsFalse: settingsFalseIcon,
    settingsTrue: settingsTrueIcon,
    share: shareIcon,
    store: storeIcon,
    switchUser: switchUserIcon,
    trackable: trackableIcon,
    users: usersIcon,
    video: videoIcon,
    vrArDevice: vrArDeviceIcon,
};

export default icons;
