import styled from "styled-components";
import { SScroll, Title } from "../../components/Common";

import { Paths } from "../../context/RouterContext";
import { useRouter, useStore } from "../../hooks";
import { NEW_IDENTIFIER } from "../../store";
import { IApplication, ILicense } from "../../store/scheme";

import { SPageHolder } from "..";
import { Button, InputField, TextArea, Text } from "../../components/UI";
import LicenseExperiences from "./LicenseExperiences";
import { useMemo, useState } from "react";
import { key } from "firebase-key";
import { Action } from "../../utility";
import LicenseSeats from "./LicenseSeats";
import Navbar from "../../components/Navbar";
import useStateRef from "react-usestateref";

const SLicense = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const SContent = styled.div`
    display: grid;
    width: 100%;
    max-width: 566px;
    height: min-content;
    grid-gap: 16px;
`;

const SBottomButtons = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 94px 212px;
    margin-left: auto;
`;

const License = () => {
    const router = useRouter();
    const store = useStore();
    const onSave: Action<void> = useMemo(() => new Action(), []);

    function getApplication(): IApplication {
        const applicationId = router.getParam("application" ?? "");
        return store.state.application?.[applicationId] ?? {};
    }

    function getLicense(application: IApplication): ILicense {
        const licenseId = router.getParam("license") ?? "";

        try {
            if (licenseId.includes(NEW_IDENTIFIER))
                return {
                    id: NEW_IDENTIFIER,
                };
        } catch {
            return {};
        }

        return application?.licenses?.[licenseId] ?? {};
    }

    const application = getApplication();
    const [license, setLicense, licenseRef] = useStateRef(
        getLicense(application)
    );
    const isNew = useMemo(() => {
        const value = license.id === NEW_IDENTIFIER;
        if (value) setLicense((p) => ({ ...p, id: key() }));
        return value;
    }, []);

    function saveHandler(): void {
        const licenseId = license.id ?? "";

        const saveApplication: IApplication = { ...application };
        if (!saveApplication.licenses) saveApplication.licenses = {};

        saveApplication.licenses[licenseId] = license;

        onSave.trigger();

        store.dispatch({
            type: "application",
            action: "set",
            object: saveApplication,
        });

        back();
    }

    function back(): void {
        router.setParam("license");
        router.setPath(Paths.ApplicationLicenses, true);
    }

    return (
        <SLicense>
            {/* <div style={{ height: 48 }} /> */}
            <Navbar
                customTabs={[
                    <Text
                        key="back"
                        onClick={() => back()}
                        H5
                        semiBold
                        middle
                        oneline
                        medium
                    >
                        Back
                    </Text>,
                    <Text key="title" H5 semiBold middle oneline dark>
                        {isNew ? "New License" : license.name}
                    </Text>,
                ]}
                keepParams
            />
            {/* @ts-ignore */}
            <SScroll>
                <SPageHolder
                    style={{
                        display: "grid",
                        justifyItems: "center",
                    }}
                >
                    <SContent>
                        <Title>{isNew ? "New License" : license.name}</Title>
                        <div />
                        <InputField
                            title="Name"
                            value={license.name ?? ""}
                            placeholder="Enter name"
                            onChange={(name) =>
                                setLicense((p) => ({ ...p, name }))
                            }
                        />
                        <InputField
                            title="Code"
                            value={license.code ?? ""}
                            placeholder="Enter code"
                            onChange={(code) =>
                                setLicense((p) => ({ ...p, code }))
                            }
                            disabled={!isNew}
                        />
                        <InputField
                            title="Total Seats"
                            value={(license.seatsAvailable ?? 0).toString()}
                            placeholder="Enter code"
                            type="number"
                            onChange={(seatsAvailable) => {
                                const value =
                                    parseInt(seatsAvailable ?? "0") ?? 0;
                                setLicense((p) => ({
                                    ...p,
                                    seatsAvailable: value,
                                }));
                            }}
                        />
                        {!isNew && (
                            <LicenseSeats
                                license={licenseRef.current}
                                setLicense={setLicense}
                            />
                        )}
                        <LicenseExperiences
                            application={application}
                            license={license}
                            onSave={onSave}
                        />
                        <TextArea
                            title="Description"
                            onChange={(description) =>
                                setLicense((p) => ({ ...p, description }))
                            }
                            value={license.description ?? ""}
                            placeholder="Write a short description"
                        />
                        <SBottomButtons>
                            <Button secondary onClick={() => back()}>
                                Discard
                            </Button>
                            <Button onClick={() => saveHandler()}>
                                {isNew ? "Create" : "Save changes"}
                            </Button>
                        </SBottomButtons>
                        <div style={{ height: 100 }} />
                    </SContent>
                </SPageHolder>
            </SScroll>
        </SLicense>
    );
};

export default License;
