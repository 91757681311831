import styled from "styled-components";
import { SScroll } from "../../components/Common";

import Navbar from "../../components/Navbar";
import { Paths } from "../../context/RouterContext";
import { useRouter, useStore } from "../../hooks";
import { NEW_IDENTIFIER } from "../../store";
import { IApplication, ICollection } from "../../store/scheme";
import { Text } from "../../components/UI";

import Overview from "./Overview";
import Theme from "./Theme";
import Licenses from "./Licenses";

const SApplication = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const Application = () => {
    const router = useRouter();
    const store = useStore();

    function getApplication(): IApplication {
        const applicationId = router.getParam("application") ?? "";

        try {
            if (applicationId.includes(NEW_IDENTIFIER))
                return {
                    id: NEW_IDENTIFIER,
                };
        } catch {
            return {};
        }

        return store.state.application?.[applicationId] ?? {};
    }

    function getCollection(): ICollection | undefined {
        const collectionId = router.getParam("collection") ?? "";
        const collectionParentId = router.getParam("collection-parent");

        try {
            if (collectionId.includes(NEW_IDENTIFIER))
                return {
                    id: NEW_IDENTIFIER,
                    parent: collectionParentId,
                    type: "ICollection",
                };
        } catch {}

        return store.state.collection?.[collectionId] ?? {};
    }

    const application = getApplication();
    const collection = getCollection();

    const tabs = {
        [Paths.ApplicationOverview]: {
            name: "Overview",
            path: Paths.ApplicationOverview,
            content: (
                <Overview application={application} collection={collection} />
            ),
        },
        [Paths.ApplicationTheme]: {
            name: "Theme",
            path: Paths.ApplicationTheme,
            content: <Theme application={application} />,
        },
        [Paths.ApplicationLicenses]: {
            name: "Licenses",
            path: Paths.ApplicationLicenses,
            content: <Licenses application={application} />,
        },
    };

    function getNavbar() {
        if (collection?.id) {
            const tabs = [];

            let aux = collection;
            while (aux?.id) {
                const auxId = aux.id;

                tabs.push(
                    <Text
                        key={aux.id}
                        onClick={() => router.setParam("collection", auxId)}
                        H5
                        semiBold
                        middle
                        oneline
                        medium={collection.id != aux.id}
                        primary={collection.id === aux.id}
                    >
                        {aux.id === NEW_IDENTIFIER
                            ? "New Collection"
                            : aux.name}
                    </Text>
                );

                aux = store.state.collection[aux.parent ?? ""];
            }

            tabs.push(
                <Text
                    key="back"
                    onClick={() => router.setParam("collection")}
                    H5
                    semiBold
                    middle
                    oneline
                    medium
                >
                    Application
                </Text>
            );

            tabs.reverse();

            return <Navbar customTabs={tabs} />;
        }

        return <Navbar tabs={Object.values(tabs)} keepParams />;
    }

    return (
        <SApplication>
            {getNavbar()}
            {/* @ts-ignore */}
            <SScroll>{tabs?.[router.state.path]?.content}</SScroll>
        </SApplication>
    );
};

export default Application;
