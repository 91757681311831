import { FC, useRef, useState } from "react";
import styled from "styled-components";
import icons from "../../assets/icons";
import { SScroll, Title } from "../../components/Common";
import Confirm from "../../components/modal/Confirm";
import { InputField, SVG, Text } from "../../components/UI";
import { uploadFile } from "../../firebase/storage";
import { useModal, useNotification, useStore } from "../../hooks";
import { IAsset } from "../../store/scheme";

const SImages = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content min-content auto;
    min-height: 0;
`;

const STopRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-template-columns: 1fr 1fr 1fr 3rem;
    grid-gap: 0.5rem;
`;

const SSearch = styled.div`
    display: grid;
    grid-template-columns: auto 20rem;
    padding: 0.5rem;
`;

const Images: FC<{}> = ({}) => {
    const inputRef = useRef();
    const modal = useModal();
    const notification = useNotification();
    const store = useStore();
    const [search, setSearch] = useState("");

    async function createAsset(file: File): Promise<void> {
        notification.notify("Uploading asset", "loading", false);
        const { url, id } = await uploadFile({ file, path: "images" });

        const imageAsset: IAsset = {
            id,
            name: file.name,
            date: new Date().toDateString(),
            size: `${file.size * 0.000001} MB`,
            url: url,
        };

        store.dispatch({
            type: "asset-image",
            action: "set",
            object: imageAsset,
        });
    }

    function handleChange(file: File): void {
        modal.setContent(
            <Confirm
                title={`Upload ${file.name}?`}
                confirmHandler={() => createAsset(file)}
            />
        );
        modal.setActive(true);
    }

    function newHandler(): void {
        //@ts-ignore
        inputRef?.current?.click();
    }

    function getImages() {
        return Object.values(store.state.assetImage)
            .filter((image) => {
                if (search === "") return true;
                return image.name
                    ?.toLocaleLowerCase()
                    ?.includes(search?.toLocaleLowerCase());
            })
            .map((image) => <ImageItem key={image.id} image={image} />);
    }

    return (
        <SImages>
            <input
                type="file"
                // @ts-ignore
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => handleChange(e?.target?.files?.[0] as File)}
            />
            <Title buttonText="New" buttonAction={() => newHandler()}>
                Images
            </Title>
            <SSearch>
                <div />
                <InputField
                    value={search}
                    onChange={(v) => setSearch(v)}
                    placeholder="Search..."
                    icon={icons.search}
                />
            </SSearch>
            <STopRow>
                <Text middle H6 semiBold>
                    Name
                </Text>
                <Text middle H6 semiBold>
                    Size
                </Text>
                <Text middle H6 semiBold>
                    Date
                </Text>
                <Text middle H6 semiBold>
                    Delete
                </Text>
            </STopRow>
            <SScroll padding="0">
                {getImages()}
                <div style={{ height: "5rem" }} />
            </SScroll>
        </SImages>
    );
};

const SClose = styled.div`
    width: 3rem;
    height: 50%;
    cursor: pointer;
    opacity: 0;
`;

const SImageItem = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-gap: 0.5rem;
    grid-template-columns: auto 3rem;
    align-items: center;

    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;

        ${SClose} {
            opacity: 1;
        }
    }
`;

const SImageClick = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const SName = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: min-content auto;
`;

const ImageItem: FC<{ image: IAsset }> = ({ image }) => {
    const modal = useModal();
    const store = useStore();

    function clickHandler(): void {
        window.open(image.url);
    }

    function deleteHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Remove Image"
                subTitle={`Remove "${image.name}" from assets."`}
                icon={icons.delete}
                confirmHandler={() => {
                    store.dispatch({
                        action: "delete",
                        type: "asset-image",
                        object: image,
                    });
                }}
            />
        );
        modal.setActive(true);
    }

    return (
        <SImageItem>
            <SImageClick onClick={() => clickHandler()}>
                <SName>
                    <Text middle H6>
                        {image.name}
                    </Text>
                </SName>
                <Text middle H6>
                    {image.size}
                </Text>
                <Text middle H6>
                    {image.date}
                </Text>
            </SImageClick>
            <SClose onClick={() => deleteHandler()}>
                <SVG medium contain image={icons.close} />
            </SClose>
        </SImageItem>
    );
};

export default Images;
