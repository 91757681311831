import React, { FC, useState } from 'react'

const Profile = React.createContext({})

export interface IProfileState {
	active: boolean,
}

export interface IProfile {
	state: IProfileState,
	setActive(value: boolean): void,
}

export const ProfileContext: FC = (props) => {
	const [state, set] = useState<IProfileState>({
		active: false,
	})

	const value: IProfile = {
		state: state,
		setActive(value) {
			if (value) set(p => ({ ...p, active: value }))
			else set(p => ({ ...p, active: value }))
		},
	}

	return (
		<Profile.Provider value={value}>
			{props.children}
		</Profile.Provider>
	)
}

export default Profile