import React, { FC } from "react";
import styled from "styled-components";
import { Title } from "../../../components/Common";
import FileSelector, { STextRow } from "../../../components/FileSelector";
import ImageSelector from "../../../components/ImageSelector";
import AssetSelector from "../../../components/modal/AssetSelector";
import Confirm from "../../../components/modal/Confirm";
import { Button, Text, Toggle } from "../../../components/UI";
import { uploadFile } from "../../../firebase/storage";
import { useModal, useNotification, useStore } from "../../../hooks";
import { IAsset, IExperienceImage } from "../../../store/scheme";

const SExperienceImage = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`;

const ExperienceImage: FC<{
    experience: IExperienceImage;
    setExperience: React.Dispatch<React.SetStateAction<IExperienceImage>>;
}> = ({ experience, setExperience }) => {
    const store = useStore();
    const modal = useModal();
    const notification = useNotification();

    const image: IAsset = store.state.assetImage?.[experience?.imageId ?? ""];

    function assetSelectedHandler(imageId: string): void {
        setExperience((e) => ({ ...e, imageId }));
    }

    function onSelect() {
        modal.setContent(
            <AssetSelector
                storePath="assetImage"
                title="Select Image"
                onSelect={(id) => assetSelectedHandler(id)}
            />
        );
        modal.setActive(true);
    }

    //#region Upload Asset
    async function createAsset(file: File): Promise<void> {
        notification.notify("Uploading asset", "loading", false);
        const { url, id } = await uploadFile({ file, path: "images" });

        const imageAsset: IAsset = {
            id,
            name: file.name,
            date: new Date().toDateString(),
            size: `${file.size * 0.000001} MB`,
            url: url,
        };

        store.dispatch({
            type: "asset-image",
            action: "set",
            object: imageAsset,
        });

        assetSelectedHandler(id);
    }

    function handleChange(file: File): void {
        modal.setContent(
            <Confirm
                title={`Upload ${file.name} as new image asset?`}
                confirmHandler={() => createAsset(file)}
            />
        );
        modal.setActive(true);
    }
    //#endregion

    return (
        <SExperienceImage>
            <Title>Image</Title>
            <FileSelector
                title="Image Asset"
                fileHandler={(file) => handleChange(file)}
                fileName={image?.name}
                deleteHandler={() =>
                    setExperience((e) => ({ ...e, imageId: undefined }))
                }
            />
            <STextRow>
                <Text H6 bold medium>
                    Also, you can{" "}
                </Text>
                <Text H6 bold primary onClick={() => onSelect()}>
                    select an existing asset
                </Text>
            </STextRow>
            <Text H4 bold>
                360 Image
            </Text>
            <Toggle
                title="This is a 360 image"
                active={experience.is360}
                onClick={() =>
                    setExperience((p) => ({ ...p, is360: !p.is360 }))
                }
                type="checkbox"
            />
            <Text H4 bold>
                Source Type
            </Text>
            <Toggle
                title="Monoscopic"
                active={!experience.isStereo}
                onClick={() =>
                    setExperience((p) => ({ ...p, isStereo: false }))
                }
                type="circle"
            />
            <Toggle
                title="Stereoscopic"
                active={experience.isStereo}
                onClick={() => setExperience((p) => ({ ...p, isStereo: true }))}
                type="circle"
            />
        </SExperienceImage>
    );
};

export default ExperienceImage;
