import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import icons from '../assets/icons'
import { IUser } from '../context/AuthContext'
import { useAuth, useOutsideAlerter, useProfile, useStore } from '../hooks'
import { SVG, Image, Text, InputField, Button } from './UI'

const SProfile = styled.div<{ active?: boolean, changed?: boolean }>`
    position: fixed;
    width: 24.375rem;
    right: 1rem;
    border-radius: 10px;
    background-color: ${p => p.theme.colors.neutral['00']};
    overflow: hidden;

    bottom: ${p => p.active ? '1rem' : '2rem'};
    top: ${p => p.active ? '4rem' : '3rem'};
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-template-rows: ${p => p.changed ? '17.25rem auto min-content' : '17.25rem auto'};
    transition: .3s;
`

const STop = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
`

const SColor = styled.div`
    background-color: ${p => p.theme.colors.main.primary};
`

const SScroller = styled.div`
    overflow: auto;
    padding: 0 2rem;
    padding-bottom: 2rem;
`

const SGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding-bottom: 5rem;
`

const SProfilePic = styled.div`
    position: absolute;
    border-radius: 50%;
    width: 13rem;
    height: 13rem;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${p => p.theme.colors.neutral['10']};
`

const SButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

const Profile = () => {
    const auth = useAuth()
    const store = useStore()
    const [user, setUser] = useState<IUser>()
    const [changed, setChanged] = useState(false)
    const profile = useProfile()
    const ref = useRef()
    useOutsideAlerter(ref, () => profile.setActive(false))

    useEffect(() => {
        resetUser()
    }, [auth.state?.user?.id])

    function resetUser(): void {
        if (auth.state?.user?.id) {
            setUser(auth.state.user)
            setChanged(false)
        }
    }

    function saveHandler(): void {
        store.dispatch({ type: 'user', action: 'set', object: user })
        setChanged(false)
    }

    function editUser(key: keyof IUser, value: any): void {
        setUser(p => ({ ...p, [key]: value }))
        setChanged(true)
    }

    return (
        // @ts-ignore
        <SProfile ref={ref} active={profile.state.active} changed={changed}>
            <STop>
                <SColor />

                <SProfilePic>
                    {user?.profilePicture ? <Image image={user?.profilePicture} contain /> : <SVG image={icons.profile} light />}
                </SProfilePic>
            </STop>
            <SScroller>
                <SGrid>
                    <Text H2 center>{user?.firstName ?? 'No name'}</Text>
                    <InputField
                        value={user?.firstName ?? ''}
                        onChange={value => editUser('firstName', value)}
                        title='First name'
                        placeholder='Enter first name'
                    />
                    <InputField
                        value={user?.lastName ?? ''}
                        onChange={value => editUser('lastName', value)}
                        title='Last name'
                        placeholder='Enter last name'
                    />
                    <InputField
                        value={user?.phoneNo ?? ''}
                        onChange={value => editUser('phoneNo', value)}
                        title='Phone number'
                        placeholder='Enter phone number'
                    />
                </SGrid>
            </SScroller>
            {changed && <SButtons>
                <Button secondary onClick={() => resetUser()}>Discard</Button>
                <Button onClick={() => saveHandler()}>Save</Button>
            </SButtons>}
        </SProfile>
    )
}

export default Profile
