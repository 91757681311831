import { FC, useRef, useState } from "react";
import styled from "styled-components";
import icons from "../../assets/icons";
import { hlsConvertConfig } from "../../brand";
import { SScroll, Title } from "../../components/Common";
import Confirm from "../../components/modal/Confirm";
import { InputField, SVG, Text } from "../../components/UI";
import { INotification } from "../../context/NotificationContext";
import { ROOT } from "../../firebase/database";
import { uploadFile } from "../../firebase/storage";
import { useModal, useNotification, useStore } from "../../hooks";
import { IStore } from "../../store";
import { IAsset } from "../../store/scheme";

const SVideos = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content min-content min-content auto;
    min-height: 0;
`;

const SSearch = styled.div`
    display: grid;
    grid-template-columns: auto 20rem;
    padding: 0.5rem;
`;

const STopRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-template-columns: 3fr 1fr 1fr 1fr 3rem;
    grid-gap: 0.5rem;
`;

async function createHLSVideo(asset: IAsset, notification: INotification) {
    const response = await fetch(hlsConvertConfig.cloudFunction, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            source: asset.url,
            destination: hlsConvertConfig.getDestinationPath(
                ROOT,
                asset.id ?? ""
            ),
        }),
    });

    const text = await response.text();

    notification.notify(text, "ok", true);
}

export async function createAsset(
    file: File,
    notification: INotification,
    store: IStore
): Promise<IAsset> {
    notification.notify("Uploading asset", "loading", false);
    const { url, id } = await uploadFile({ file, path: "videos" });

    const videoAsset: IAsset = {
        id,
        name: file.name,
        date: new Date().toDateString(),
        size: `${file.size * 0.000001} MB`,
        url: url,
        hls: hlsConvertConfig.getHlsUrl(ROOT, id),
    };

    store.dispatch({
        type: "asset-video",
        action: "set",
        object: videoAsset,
    });

    createHLSVideo(videoAsset, notification);

    return videoAsset;
}

const Videos: FC<{}> = ({}) => {
    const inputRef = useRef();
    const modal = useModal();
    const notification = useNotification();
    const store = useStore();
    const [search, setSearch] = useState("");

    function handleChange(file: File): void {
        modal.setContent(
            <Confirm
                title={`Upload ${file.name}?`}
                confirmHandler={() => createAsset(file, notification, store)}
            />
        );
        modal.setActive(true);
    }

    function newHandler(): void {
        //@ts-ignore
        inputRef?.current?.click();
    }

    function getVideos() {
        return Object.values(store.state.assetVideo)
            .filter((video) => {
                if (search === "") return true;
                return video.name
                    ?.toLocaleLowerCase()
                    ?.includes(search?.toLocaleLowerCase());
            })
            .map((video) => <VideoItem key={video.id} video={video} />);
    }

    return (
        <SVideos>
            <input
                type="file"
                // @ts-ignore
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => handleChange(e?.target?.files?.[0] as File)}
            />
            <Title buttonText="New" buttonAction={() => newHandler()}>
                Videos
            </Title>
            <SSearch>
                <div />
                <InputField
                    value={search}
                    onChange={(v) => setSearch(v)}
                    placeholder="Search..."
                    icon={icons.search}
                />
            </SSearch>
            <STopRow>
                <Text middle H6 semiBold>
                    Name
                </Text>
                <Text middle H6 semiBold>
                    Size
                </Text>
                <Text middle H6 semiBold>
                    Date
                </Text>
                <Text middle H6 semiBold>
                    HLS
                </Text>
                <Text middle H6 semiBold>
                    Delete
                </Text>
            </STopRow>
            <SScroll padding="0">
                {getVideos()}
                <div style={{ height: "5rem" }} />
            </SScroll>
        </SVideos>
    );
};

const SClose = styled.div`
    width: 3rem;
    height: 50%;
    cursor: pointer;
    opacity: 0;
`;

const SVideoItem = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-gap: 0.5rem;
    grid-template-columns: 5fr 1fr 3rem;
    align-items: center;

    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;

        ${SClose} {
            opacity: 1;
        }
    }
`;

const SVideoClick = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const SName = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: min-content auto;
    max-width: 100%;
    overflow: hidden;
`;

const SRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: min-content;
`;

export function viewHlsHandler(video: IAsset) {
    const url = `https://www.hlsplayer.net/#type=m3u8&src=${encodeURI(
        video.hls ?? ""
    )}`;
    window.open(url);
}

export function copyHlsHandler(video: IAsset, notification: INotification) {
    navigator.clipboard.writeText(video.hls ?? "");
    notification.notify("HLS Link copied to clipboard", "ok");
}

const VideoItem: FC<{ video: IAsset }> = ({ video }) => {
    const modal = useModal();
    const store = useStore();
    const notification = useNotification();

    function clickHandler(): void {
        window.open(video.url);
    }

    function deleteHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Remove Video"
                subTitle={`Remove "${video.name}" from assets."`}
                icon={icons.delete}
                confirmHandler={() => {
                    store.dispatch({
                        action: "delete",
                        type: "asset-video",
                        object: video,
                    });
                }}
            />
        );
        modal.setActive(true);
    }

    return (
        <SVideoItem>
            <SVideoClick onClick={() => clickHandler()}>
                <SName>
                    <Text middle H6 oneline>
                        {video.name}
                    </Text>
                </SName>
                <Text middle H6>
                    {video.size}
                </Text>
                <Text middle H6>
                    {video.date}
                </Text>
            </SVideoClick>
            <SRow>
                <Text middle H6 oneline onClick={() => viewHlsHandler(video)}>
                    Preview HLS
                </Text>
                <Text
                    middle
                    H6
                    oneline
                    onClick={() => copyHlsHandler(video, notification)}
                >
                    Copy HLS Link
                </Text>
            </SRow>
            <SClose onClick={() => deleteHandler()}>
                <SVG medium contain image={icons.close} />
            </SClose>
        </SVideoItem>
    );
};

export default Videos;
