import { IStore } from "../store";
import { Dictionary, ICollection, TContentTypes } from "../store/scheme";

export function getSubCollections(
    collection: ICollection,
    store: IStore
): Dictionary<ICollection> {
    return Object.values(collection.experiences?.collections ?? {}).reduce(
        (obj, id) => ({ ...obj, [id]: store.state.collection[id] }),
        {}
    );
}

export function getSubExperiences(
    collection: ICollection,
    store: IStore
): Dictionary<TContentTypes> {
    const experiences: Dictionary<TContentTypes> = {};

    Object.values(collection.experiences?.images ?? {}).forEach(
        (id) => (experiences[id] = store.state.experienceImage[id])
    );
    Object.values(collection.experiences?.videos ?? {}).forEach(
        (id) => (experiences[id] = store.state.experienceVideo[id])
    );
    Object.values(collection.experiences?.interactives ?? {}).forEach(
        (id) => (experiences[id] = store.state.experienceInteractive[id])
    );
    Object.values(collection.experiences?.virtualTours ?? {}).forEach(
        (id) => (experiences[id] = store.state.experienceVirtualTour[id])
    );

    return experiences;
}

export function getRecoursiveSubContent(
    collection: ICollection,
    store: IStore
): Dictionary<TContentTypes> {
    let content: Dictionary<TContentTypes> = getSubExperiences(
        collection,
        store
    );

    if (collection.experiences?.collections) {
        const subCollections = getSubCollections(collection, store);
        content = {
            ...content,
            ...subCollections,
        };

        Object.values(subCollections).forEach(
            (subCollection) =>
                (content = {
                    ...content,
                    ...getRecoursiveSubContent(subCollection, store),
                })
        );
    }

    return content;
}
