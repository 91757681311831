import React from "react";
import styled from "styled-components";
import { SScroll } from "../../components/Common";

import Navbar from "../../components/Navbar";
import { Paths, TParam } from "../../context/RouterContext";
import { useRouter, useStore } from "../../hooks";
import { IState, NEW_IDENTIFIER } from "../../store";
import { TContentTypes } from "../../store/scheme";

import Overview from "./overview";
import Analytics from "./analytics";

const SExperience = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const PARAMS: TParam[] = [
    "experience-image",
    "experience-video",
    "experience-interactive",
    "experience-virtual-tour",
];

function getExperienceType(type: keyof IState) {
    if (type === "experienceImage") return "IExperienceImage";
    if (type === "experienceVideo") return "IExperienceVideo";
    if (type === "experienceInteractive") return "IExperienceInteractive";

    return "IExperienceVirtualTour"; // Default.
}

const Experience = () => {
    const router = useRouter();
    const store = useStore();

    function getExperience(): TContentTypes {
        function get(
            experienceId: string,
            type:
                | "experienceImage"
                | "experienceVideo"
                | "experienceInteractive"
                | "experienceVirtualTour"
        ): TContentTypes {
            // Is new experience.
            if (experienceId.includes(NEW_IDENTIFIER)) {
                return { id: NEW_IDENTIFIER, type: getExperienceType(type) };
            } else return store.state?.[type]?.[experienceId] ?? {};
        }

        if (router.getParam("experience-image"))
            return get(router.getParam("experience-image"), "experienceImage");
        if (router.getParam("experience-video"))
            return get(router.getParam("experience-video"), "experienceVideo");
        if (router.getParam("experience-interactive"))
            return get(
                router.getParam("experience-interactive"),
                "experienceInteractive"
            );
        if (router.getParam("experience-virtual-tour"))
            return get(
                router.getParam("experience-virtual-tour"),
                "experienceVirtualTour"
            );

        return { id: NEW_IDENTIFIER, type: "IExperienceImage" };
    }

    const experience = getExperience();
    const application =
        store.state.application?.[router.getParam("application") ?? ""] ?? {};

    const tabs = {
        [Paths.ExperienceOverview]: {
            name: "Overview",
            path: Paths.ExperienceOverview,
            content: (
                <Overview
                    experience={experience}
                    application={application}
                    backHandler={() => backHandler()}
                />
            ),
        },
        [Paths.ExperienceAnalitycs]: {
            name: "Analytics",
            path: Paths.ExperienceAnalitycs,
            content: <Analytics experience={experience} />,
        },
    };

    function backHandler(): void {
        PARAMS.forEach((p) => router.setParam(p)); // First clear experience param.
        router.setPath(Paths.ApplicationOverview, true); // Go back to application overview page.
    }

    return (
        <SExperience>
            <Navbar
                tabs={Object.values(tabs)}
                keepParams
                backHandler={() => backHandler()}
            />
            {/* @ts-ignore */}
            <SScroll>{tabs?.[router.state.path]?.content}</SScroll>
        </SExperience>
    );
};

export default Experience;
