import styled, { css, keyframes } from 'styled-components'
import icons from '../../assets/icons'
import { useNotification } from '../../hooks'
import { SVG, Text } from '../UI'
import theme from '../../brand/theme'
import { useEffect } from 'react'

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-2rem);
	}
	100% {
		opacity: 1;
	}
`

const spinAnimation = keyframes`
	0% { transform: rotate(360deg); }
	100% { transform: rotate(0deg); }
`

export const SIcon = styled.div`
	animation: ${spinAnimation} linear 2s;
	animation-iteration-count: infinite;
	opacity: .9;
`

const SNotifications = styled.div`
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 4.5rem;
	overflow: hidden;
`

const SCard = styled.div`
	height: 3.125rem;
	background-color: ${p => p.theme.colors.neutral['00']};
	border-radius: .625rem;
	border: .063rem solid ${p => p.theme.colors.neutral['10']};
	padding: 0 1.313rem;

	display: grid;
	grid-template-columns: 3rem auto;
	width: min-content;
	grid-gap: 1rem;

	// Animation.
	animation: ${fadeInAnimation} ease .5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
`

const SContainer = styled.div<{ center?: boolean }>`
	position: absolute;
	width: min-content;
	padding: 1.188rem;

	right: 0;
	top: 0;

	${p => p.center && css`
		left: 50%;
		transform: translateX(-50%);
	`}
`

const SColumn = styled.div<{ gap?: string }>`
	display: grid;
	grid-gap: ${p => p.gap ? p.gap : '1.5rem'};
	height: min-content;
`

const Notifications = () => {
	const notification = useNotification()

	useEffect(() => {
		window.Treeview.notification = notification
	}, [])

	function getCards() {
		const center: any[] = []
		const right: any[] = []

		const configurations = {
			'error': { icon: icons.close, color: '#FF7A00' },
			'loading': { icon: icons.retry, color: theme.colors.main.primary },
			'ok': { icon: icons.checkmark, color: '#26C9A8' },
		}

		// Get cards.
		Object.values(notification.state.notifications).forEach(notification => {
			const icon = (<SVG contain image={configurations[notification.type].icon} style={{ backgroundColor: configurations[notification.type].color }} />)
			const card = (
				<SCard key={notification.id}>
					{notification.type === 'loading' ? <SIcon>{icon}</SIcon> : icon}
					<Text oneline H5 semiBold middle>{notification.text}</Text>
				</SCard>
			)

			if (notification.type === 'error') right.push(card)
			else center.push(card)
		})

		return [center, right]
	}

	const [center, right] = getCards()

	return (
		<SNotifications>
			<SContainer center> <SColumn gap='.5rem'> {center} </SColumn> </SContainer>
			<SContainer> <SColumn> {right} </SColumn> </SContainer>
		</SNotifications>
	)
}

export default Notifications
