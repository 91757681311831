import React, { FC, useState } from 'react'

const Modal = React.createContext({})

export interface IModalState {
	active: boolean,
	content?: any,
	height?: string,
	width?: string
}

export interface IModal {
	state: IModalState,
	setActive(value: boolean): void,
	setContent(content: any, width?: string, height?: string): void
}

export const ModalContext: FC = (props) => {
	const [state, set] = useState<IModalState>({
		active: false,
	})

	const value: IModal = {
		state: state,
		setActive(value) {
			if (value) set(p => ({ ...p, active: value }))
			else set(p => ({ ...p, active: value, content: undefined, height: undefined, width: undefined }))
		},
		setContent(content, width, height) { set(p => ({ ...p, content, width, height })) }
	}

	return (
		<Modal.Provider value={value}>
			{props.children}
		</Modal.Provider>
	)
}

export default Modal