import { key } from 'firebase-key'
import React, { FC, useState } from 'react'
import { Dictionary } from '../store/scheme'

const Notification = React.createContext({})

interface INoty {
	id: string,
	text: string,
	type: TType
}

export interface INotificationState {
	notifications: Dictionary<INoty>
}

type TType = 'loading' | 'ok' | 'error'

export interface INotification {
	state: INotificationState,
	notify: (text: string, type: TType, timed?: boolean) => string,
	kill: (id: string) => void,
	killType: (type: TType) => void,
}

export const NotificationContext: FC = (props) => {
	const [state, set] = useState<INotificationState>({
		notifications: {}
	})

	const value: INotification = {
		state: state,
		notify(text, type, timed = true) {
			const notification: INoty = {
				id: key(),
				text,
				type
			}

			set(p => ({
				...p, notifications: {
					...p.notifications,
					[notification.id]: notification
				}
			}))

			if (timed) {
				setTimeout(() => {
					set(p => {
						delete p.notifications[notification.id]
						return { ...p }
					})
				}, 4000)
			}

			return notification.id
		},
		kill(id) {
			set(p => {
				delete p.notifications[id]
				return { ...p }
			})
		},
		killType(type) {
			set(p => {
				Object.keys(p.notifications).forEach(key => {
					if (p.notifications[key].type === type) delete p.notifications[key]
				})
				return { ...p }
			})
		}
	}

	return (
		<Notification.Provider value={value}>
			{props.children}
		</Notification.Provider>
	)
}

export default Notification