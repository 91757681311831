import { FC } from 'react'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import styled from 'styled-components'

const SItem = styled.button`
	background: none;
	border: none;
`

const SortableItem = SortableElement(({ value }: { value: any }) => <SItem> {value}</SItem>)

const SSortable = styled.div`
	display: grid;
	margin-top: 1rem;
	padding-bottom: 10rem;
`

export const Grid = SortableContainer(({ items }: { items: any }) => {
	return (
		<SSortable>
			{/* @ts-ignore */}
			{items !== undefined ? items.map((value, index) => (
				<SortableItem key={`item-${value.key}`} index={index} value={value} />
			)) : <div />}
		</SSortable>
	)
})

export const Sortable: FC<{ sortHandler(from: number, to: number): void, items: any, axis?: 'x' | 'y' | 'xy' }> = ({ sortHandler, items, axis }) => {
	return (
		<Grid
			items={items}
			axis={axis}
			distance={1}
			onSortEnd={({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => { sortHandler(oldIndex, newIndex) }}
		/>
	)
}

export default Sortable
