import firebase, { organizationStorage } from "./";
import { key } from "firebase-key";
import { ROOT } from "./database";

const storage = firebase.storage();

const getStorage = (organization: boolean) =>
    organization ? organizationStorage : storage;

// Get the whole url to asset.
export const getUrl = (path: string, organization: boolean = true) =>
    new Promise<string>(async (resolve, reject) => {
        try {
            const url = await getStorage(organization)
                .ref()
                .child(path)
                .getDownloadURL();
            resolve(url);
        } catch (error) {
            reject(error);
        }
    });

// Upload file to storage.
export const uploadFile = (
    data: {
        file: File;
        path?: "images" | "videos";
        name?: string;
        maxMBUpload?: number;
    },
    organization: boolean = true
) =>
    new Promise<{ id: string; path: string; url: string }>(
        async (resolve, reject) => {
            // Check if we exceed the maxMBUpload limit.
            if (data.file.size && data.maxMBUpload) {
                const mb = data.file.size / 1024 / 1024;
                if (mb > data.maxMBUpload) {
                    reject(`File can't be larger than ${data.maxMBUpload} mbs`);
                    return;
                }
            }

            try {
                // Upload with given name, or generate random key.
                const id = data.name ? data.name : key();

                // Set upload path.
                let path = data.path ? `${data.path}/${id}` : id;
                path = ROOT + path + "/file";

                // Upload...
                const uploadTask = await getStorage(organization)
                    .ref()
                    .child(path)
                    .put(data.file);
                const url = await getUrl(path);

                resolve({ id, path, url });
            } catch (error) {
                reject(error);
            }
        }
    );

// Check if file exists at storage path.
export async function fileExists(
    path: string,
    organization: boolean = true
): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
        try {
            await getStorage(organization)
                .ref(ROOT + path)
                .getMetadata();
            resolve(true);
        } catch {
            resolve(false);
        }
    });
}

// Delete file from storage.
export async function deleteFile(
    path: string,
    organization: boolean = true
): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
        try {
            await getStorage(organization)
                .ref(ROOT + path)
                .delete();
            resolve(true);
        } catch {
            resolve(false);
        }
    });
}
