import { FC, useState } from "react";
import styled, { useTheme } from "styled-components";
import { InputField, SVG, Text, Toggle } from "../../components/UI";
import { ESeatType, ILicense, ISeat } from "../../store/scheme";
import { useModal } from "../../hooks";
import icons from "../../assets/icons";
import Confirm from "../../components/modal/Confirm";
import { key } from "firebase-key";

const SContainer = styled.div`
    display: grid;
    height: min-content;
    grid-gap: 4px;
`;

const SSeats = styled.div`
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["10"]};
    border-radius: 1px;
    padding: 8px;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
`;

const SGrid = styled.div`
    display: grid;
    grid-gap: 16px;
`;

const SSeatItem = styled.div<{ active?: boolean }>`
    padding: 8px;
    background-color: ${(p) =>
        p.active ? p.theme.colors.neutral["00"] : p.theme.colors.neutral["05"]};
    border-radius: 4px;
    display: grid;
    grid-gap: 10px;

    cursor: pointer;
    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}50;
    }
`;

const SRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 30px;
    grid-gap: 8px;
`;

const SIconButton = styled.div`
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
        * {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }
`;

const LicenseSeats: FC<{
    license: ILicense;
    setLicense: React.Dispatch<React.SetStateAction<ILicense>>;
}> = ({ license, setLicense }) => {
    const modal = useModal();
    const theme = useTheme();
    const [newSeatId, setNewSeatId] = useState<string>("");

    function deleteHandler(seat: ISeat): void {
        modal.setContent(
            <Confirm
                title="Remove Seat"
                subTitle={`Remove ${seat.name} Seat - "${seat.seatNumber}"."`}
                icon={icons.delete}
                confirmHandler={() => {
                    setLicense((p) => {
                        const s = { ...p.seats };
                        if (seat.id) delete s[seat.id];
                        return { ...p, seats: s };
                    });
                }}
            />
        );

        modal.setActive(true);
    }

    function editSeat(seat: ISeat, key: keyof ISeat, value: any): void {
        setLicense((p) => {
            const payload = { ...p };
            //@ts-ignore
            payload.seats[seat.id][key] = value;
            return payload;
        });
    }

    function toggleActive(seat: ISeat): void {
        setLicense((p) => {
            const payload = { ...p };
            const type: ESeatType =
                //@ts-ignore
                p.seats[seat.id].type === ESeatType.valid
                    ? ESeatType.removedByCMS
                    : ESeatType.valid;

            //@ts-ignore
            payload.seats[seat.id].type = type;
            return { ...payload };
        });
    }

    function newSeatHandler(): void {
        setLicense((p) => {
            const payload = { ...p };

            const newSeat: ISeat = {
                id: key(),
                deviceId: "",
                error: 0,
                expirationDate: Math.floor(Date.now() / 86400000 + 365),
                licenseId: license.id ?? "",
                seatNumber: 1,
                type: ESeatType.removedByCMS,
                deviceType: "",
                name: "",
            };

            setNewSeatId(newSeat.id ?? "");

            while (true) {
                if (
                    Object.values(payload.seats ?? {})
                        .filter((s) => s.type === ESeatType.valid)
                        .find((s) => s.seatNumber == newSeat.seatNumber)
                ) {
                    newSeat.seatNumber++;
                    continue;
                } else break;
            }

            //@ts-ignore
            payload.seats[newSeat.id] = newSeat;
            return payload;
        });
    }

    function getSeats() {
        return Object.values(license?.seats ?? {})
            .sort((s) => (s.id === newSeatId ? 0 : 1))
            .map((seat) => (
                <SSeatItem active={seat.type === ESeatType.valid}>
                    <SRow style={{ gridTemplateColumns: "1fr 3fr 30px" }}>
                        <Text H5 semiBold middle oneline>
                            Nickname:
                        </Text>
                        <InputField
                            onChange={(v) => editSeat(seat, "name", v)}
                            value={seat.name ?? ""}
                            style={{ color: theme.colors.text.medium }}
                            placeholder="Enter nickname"
                        />
                        <SIconButton onClick={() => deleteHandler(seat)}>
                            <SVG medium contain image={icons.delete} />
                        </SIconButton>
                    </SRow>
                    <SRow>
                        <Text H5 semiBold middle oneline>
                            Device Type:
                        </Text>
                        <InputField
                            onChange={(v) => editSeat(seat, "deviceType", v)}
                            value={seat.deviceType ?? ""}
                            style={{ color: theme.colors.text.medium }}
                            disabled={seat.id != newSeatId}
                        />
                    </SRow>
                    <SRow>
                        <Text H5 semiBold middle oneline>
                            Device ID:
                        </Text>
                        <InputField
                            onChange={(v) => editSeat(seat, "deviceId", v)}
                            value={seat.deviceId ?? ""}
                            style={{ color: theme.colors.text.medium }}
                            disabled={seat.id != newSeatId}
                        />
                    </SRow>
                    <SRow>
                        <Text H5 semiBold middle oneline>
                            Active:
                        </Text>
                        <Toggle
                            active={seat.type === ESeatType.valid}
                            type="checkbox"
                            onClick={() => toggleActive(seat)}
                        />
                    </SRow>
                </SSeatItem>
            ));
    }

    return (
        <SContainer>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto min-content",
                    marginTop: 16,
                }}
            >
                <Text semiBold H5 oneline middle>
                    Seats Taken (
                    {`${
                        Object.values(license.seats ?? {}).filter(
                            (s) => s.type === ESeatType.valid
                        ).length
                    }/${license.seatsAvailable}`}
                    )
                </Text>
                <Text
                    oneline
                    middle
                    onClick={() => newSeatHandler()}
                    H5
                    medium
                    semiBold
                >
                    New Seat
                </Text>
                {/* <SIconButton onClick={() => {}}>
                    <SVG medium contain image={icons.add} />
                </SIconButton> */}
            </div>

            <SSeats>
                <SGrid>{getSeats()}</SGrid>
            </SSeats>
        </SContainer>
    );
};

export default LicenseSeats;
