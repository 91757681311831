import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { SPageHolder } from "../..";
import icons from "../../../assets/icons";
import { PageButton, Title } from "../../../components/Common";
import Confirm from "../../../components/modal/Confirm";
import {
    Button,
    InputField,
    SVG,
    Text,
    TextArea,
    Toggle,
} from "../../../components/UI";
import { useModal, useNotification, useRouter, useStore } from "../../../hooks";
import {
    IApplication,
    TContentTypes,
    getExperienceTypeData,
} from "../../../store/scheme";

import ExperienceImage from "./ExperienceImage";
import ExperienceVideo from "./ExperienceVideo";
import ExperienceInteractive from "./ExperienceInteractive";
import { NEW_IDENTIFIER } from "../../../store";
import ImageSelector from "../../../components/ImageSelector";
import { uploadFile } from "../../../firebase/storage";
import { getSortedExperiences } from "../../application/Overview";

const SExperience = styled.div`
    display: grid;
    grid-template-columns: 25rem auto;
    grid-gap: 1.5rem;
    padding-bottom: 10rem;
`;

const SSettings = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
    padding-bottom: 5rem;
`;

const SRight = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`;

const SSaveButton = styled.div`
    width: 13.25rem;
    margin-left: auto;
`;

const SRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;

const Overview: FC<{
    experience: TContentTypes;
    application: IApplication;
    backHandler(): void;
}> = (props) => {
    const [experience, setExperience] = useState<TContentTypes>(
        props.experience
    );
    const [state, set] = useState({
        loadingNew: false,
        tag: "",
    });
    const store = useStore();
    const modal = useModal();
    const router = useRouter();
    const notification = useNotification();

    const isNew = experience.id?.toString().includes(NEW_IDENTIFIER);

    useEffect(() => {
        if (props.experience.id?.includes(NEW_IDENTIFIER)) return; // Only update if its not a new experience.

        const newExperience = { ...props.experience };
        setExperience(newExperience); // Update local experience if it changes.
    }, [props.experience]);

    function getContentType() {
        if (experience.type == "IExperienceImage")
            return (
                <ExperienceImage
                    experience={experience}
                    //@ts-ignore
                    setExperience={setExperience}
                />
            );
        if (experience.type == "IExperienceVideo")
            return (
                <ExperienceVideo
                    experience={experience}
                    //@ts-ignore
                    setExperience={setExperience}
                />
            );
        if (experience.type == "IExperienceInteractive")
            return (
                <ExperienceInteractive
                    experience={experience}
                    //@ts-ignore
                    setExperience={setExperience}
                    title="Interactive"
                />
            );
        if (experience.type == "IExperienceVirtualTour")
            return (
                <ExperienceInteractive
                    experience={experience}
                    //@ts-ignore
                    setExperience={setExperience}
                    title="Virtual Tour"
                />
            );
        return <div>No Experience Type</div>;
    }

    async function doSave(): Promise<void> {
        notification.notify("Saving changes", "loading", false);
        // Set loading new if its new.
        if (isNew) set((p) => ({ ...p, loadingNew: true }));

        const payload: TContentTypes = { ...experience };

        // Check thumbnail.
        if (payload.localThumbnail) {
            const { url } = await uploadFile({
                file: payload.localThumbnail,
                path: "images",
            });
            payload.thumbnail = url;
            delete payload.localThumbnail;

            setExperience((p) => ({
                ...p,
                thumbnail: url,
                localThumbnail: undefined,
            }));
        }

        store.dispatch({
            action: "set",
            type: getExperienceTypeData(experience).action,
            object: payload,
            onAdd: (newExperience) => {
                const collection =
                    store.state.collection[router.getParam("collection")];
                if (collection) {
                    // Push new added experience to collection.
                    const updateCollection = { ...collection };

                    if (!updateCollection.experiences)
                        updateCollection.experiences = {
                            images: {},
                            videos: {},
                            interactives: {},
                            virtualTours: {},
                            collections: {},
                        };

                    const subPath =
                        getExperienceTypeData(newExperience).subPath;
                    if (!updateCollection.experiences[subPath])
                        updateCollection.experiences[subPath] = {};

                    updateCollection.experiences[subPath][newExperience.id] =
                        newExperience.id;

                    // Set order data.
                    {
                        const experienceList = getSortedExperiences(
                            props.application,
                            store,
                            collection
                        );
                        updateCollection.experiencesOrder = {};
                        experienceList.forEach((e, i) => {
                            //@ts-ignore
                            updateCollection.experiencesOrder[e.id ?? ""] = i;
                        });
                    }

                    // Save new experience to application.
                    store.dispatch({
                        action: "set",
                        type: "collection",
                        object: updateCollection,
                    });
                } else {
                    // Instead push new added experience to application.
                    const updateApplication = { ...props.application };

                    if (!updateApplication.experiences)
                        updateApplication.experiences = {
                            images: {},
                            videos: {},
                            interactives: {},
                            virtualTours: {},
                            collections: {},
                        };

                    const subPath =
                        getExperienceTypeData(newExperience).subPath;
                    if (!updateApplication.experiences[subPath])
                        updateApplication.experiences[subPath] = {};

                    updateApplication.experiences[
                        getExperienceTypeData(newExperience).subPath
                    ][newExperience.id] = newExperience.id;

                    // Set order data.
                    {
                        const experienceList = getSortedExperiences(
                            updateApplication,
                            store
                        );
                        updateApplication.experiencesOrder = {};
                        experienceList.forEach((e, i) => {
                            //@ts-ignore
                            updateApplication.experiencesOrder[e.id ?? ""] = i;
                        });
                    }

                    // Save new experience to application.
                    store.dispatch({
                        action: "set",
                        type: "application",
                        object: updateApplication,
                    });
                }

                set((p) => ({ ...p, loadingNew: false }));

                // Update url param.
                router.setParam(
                    getExperienceTypeData(newExperience).param,
                    newExperience.id
                );
            },
        });
    }

    function saveHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Save Experience"
                subTitle={`Save "${experience.name}" changes`}
                icon={icons.checkmark}
                confirmHandler={() => doSave()}
            />
        );
        modal.setActive(true);
    }

    function deleteHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Delete Experience"
                subTitle={`Delete "${experience.name}, this action cannot be undone."`}
                icon={icons.delete}
                confirmHandler={() => {
                    const collection =
                        store.state.collection[router.getParam("collection")];

                    if (collection) {
                        // Remove experience from collection.
                        const updateCollection = { ...collection };

                        if (updateCollection.experiences && experience.id)
                            delete updateCollection.experiences[
                                getExperienceTypeData(experience).subPath
                            ][experience.id];

                        // Set order data.
                        {
                            const experienceList = getSortedExperiences(
                                props.application,
                                store,
                                updateCollection
                            );
                            updateCollection.experiencesOrder = {};
                            experienceList.forEach((e, i) => {
                                //@ts-ignore
                                updateCollection.experiencesOrder[e.id ?? ""] =
                                    i;
                            });
                        }

                        store.dispatch({
                            type: "collection",
                            action: "set",
                            object: updateCollection,
                        });
                    } else {
                        // Remove experience from application.
                        const updateApplication = { ...props.application };

                        if (updateApplication.experiences && experience.id)
                            delete updateApplication.experiences[
                                getExperienceTypeData(experience).subPath
                            ][experience.id];

                        // Set order data.
                        {
                            const experienceList = getSortedExperiences(
                                updateApplication,
                                store
                            );
                            updateApplication.experiencesOrder = {};
                            experienceList.forEach((e, i) => {
                                //@ts-ignore
                                updateApplication.experiencesOrder[e.id ?? ""] =
                                    i;
                            });
                        }

                        store.dispatch({
                            type: "application",
                            action: "set",
                            object: updateApplication,
                        });
                    }

                    // Delete experience.
                    store.dispatch({
                        type: getExperienceTypeData(experience).action,
                        action: "delete",
                        object: experience,
                    });

                    // Go back to experience.
                    props.backHandler();
                }}
            />
        );
        modal.setActive(true);
    }

    function deleteTag(tag: string): void {
        setExperience((p) => ({ ...p, tags: p.tags?.filter((t) => t != tag) }));
    }

    return (
        <SPageHolder>
            <SExperience>
                <SSettings>
                    <Title>Settings</Title>
                    <ImageSelector
                        height="25rem"
                        image={experience.thumbnail}
                        fileHandler={(localThumbnail) =>
                            setExperience((p) => ({ ...p, localThumbnail }))
                        }
                        deleteHandler={() =>
                            setExperience((p) => {
                                const a = { ...p };
                                a.thumbnail = "";
                                delete a.localThumbnail;
                                return a;
                            })
                        }
                    />
                    <InputField
                        title="Name"
                        onChange={(name) =>
                            setExperience((p) => ({ ...p, name }))
                        }
                        value={experience.name ?? ""}
                        placeholder="Enter experience name"
                    />
                    <TextArea
                        title="Description"
                        onChange={(description) =>
                            setExperience((p) => ({ ...p, description }))
                        }
                        value={experience.description ?? ""}
                        placeholder="Enter experience description"
                    />

                    <InputField
                        title="Tags"
                        onChange={(tag) => set((p) => ({ ...p, tag }))}
                        value={state.tag}
                        placeholder="Enter new tag"
                        onEnter={() => {
                            // Add new tag to experience.
                            setExperience((p) => ({
                                ...p,
                                tags: [...(p.tags ?? []), state.tag],
                            }));
                            // Clear input.
                            set((p) => ({ ...p, tag: "" }));
                        }}
                    />

                    <STags>
                        <STagsGrid>
                            {experience?.tags?.length != 0 &&
                                experience.tags?.map((t) => (
                                    <STag key={t}>
                                        <Text middle H6 semiBold oneline light>
                                            {t}
                                        </Text>
                                        <SVG
                                            style={{ cursor: "pointer" }}
                                            onClick={() => deleteTag(t)}
                                            image={icons.close}
                                            light
                                        />
                                    </STag>
                                ))}
                        </STagsGrid>
                    </STags>

                    <SLine />

                    <Text H4 bold>
                        Configuration
                    </Text>

                    <SRow>
                        <InputField
                            title="Latitude"
                            onChange={(latitude) =>
                                setExperience((p) => ({
                                    ...p,
                                    location: {
                                        ...(p.location ?? {}),
                                        latitude,
                                    },
                                }))
                            }
                            value={experience?.location?.latitude ?? ""}
                            placeholder="Enter experience latitude"
                        />
                        <InputField
                            title="Longitude"
                            onChange={(longitude) =>
                                setExperience((p) => ({
                                    ...p,
                                    location: {
                                        ...(p.location ?? {}),
                                        longitude,
                                    },
                                }))
                            }
                            value={experience?.location?.longitude ?? ""}
                            placeholder="Enter experience latitude"
                        />
                    </SRow>

                    <InputField
                        title="Analytics Url"
                        onChange={(analyticsUrl) =>
                            setExperience((p) => ({ ...p, analyticsUrl }))
                        }
                        value={experience?.analyticsUrl ?? ""}
                        placeholder="Enter analytics Url"
                    />
                    <SLine />
                    {!isNew && (
                        <PageButton
                            onClick={() => {
                                notification.notify(
                                    "ID added to clipboard",
                                    "ok",
                                    true
                                );
                                navigator.clipboard.writeText(
                                    experience?.id ?? ""
                                );
                            }}
                            icon={icons.share}
                        >
                            ID: {experience.id}
                        </PageButton>
                    )}
                    {!isNew && (
                        <PageButton
                            onClick={() => deleteHandler()}
                            icon={icons.delete}
                        >
                            Delete experience
                        </PageButton>
                    )}
                </SSettings>

                <SRight>
                    {getContentType()}
                    <SSettings style={{ marginTop: 16 }}>
                        <Text H4 bold>
                            Availability
                        </Text>
                        <Toggle
                            title="Set as Coming Soon"
                            active={experience.isComingSoon}
                            onClick={() =>
                                setExperience((p) => ({
                                    ...p,
                                    isComingSoon: !p.isComingSoon,
                                }))
                            }
                            type="checkbox"
                        />
                        <Toggle
                            title="Public Access"
                            active={!experience.isPremium}
                            onClick={() =>
                                setExperience((p) => ({
                                    ...p,
                                    isPremium: false,
                                }))
                            }
                            type="checkbox"
                        />
                        <Toggle
                            title="Premium (License Code Required)"
                            active={experience.isPremium}
                            onClick={() =>
                                setExperience((p) => ({
                                    ...p,
                                    isPremium: true,
                                }))
                            }
                            type="checkbox"
                        />
                        <Toggle
                            title="Private"
                            active={experience.isPrivate}
                            onClick={() =>
                                setExperience((p) => ({
                                    ...p,
                                    isPrivate: !p.isPrivate,
                                }))
                            }
                            type="checkbox"
                        />
                    </SSettings>

                    <InputField
                        title="Learn More"
                        onChange={(learnMoreUrl) =>
                            setExperience((p) => ({
                                ...p,
                                learnMoreUrl,
                            }))
                        }
                        value={experience?.learnMoreUrl ?? ""}
                        placeholder="Enter URL"
                    />

                    <SSaveButton>
                        <Button
                            disabled={state.loadingNew}
                            onClick={() => saveHandler()}
                        >
                            {isNew
                                ? state.loadingNew
                                    ? "Processing..."
                                    : "Create"
                                : "Save"}
                        </Button>
                    </SSaveButton>
                </SRight>
            </SExperience>
        </SPageHolder>
    );
};

const STags = styled.div`
    height: 5.75rem;
    overflow: auto;
    overflow-x: hidden;
`;

const STagsGrid = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    width: 100%;
`;

const STag = styled.div`
    width: min-content;
    height: 1.688rem;
    padding: 0 0.37rem;
    display: grid;
    grid-gap: 0.1rem;

    grid-template-columns: min-content 1rem;
    background-color: ${(p) => p.theme.colors.main.primary};
    border-radius: 5px;
`;

export const SLine = styled.div`
    height: 0.063rem;
    background-color: ${(p) => p.theme.colors.neutral["10"]};
`;

const STargetPlatforms = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.188rem;
`;

export default Overview;
