import React, { FC, useState, useRef } from "react";
import styled from "styled-components";
import icons from "../assets/icons";
import { useOutsideAlerter } from "../hooks";
import { Text, Button, SVG } from "./UI";

const SAnalyticsCount = styled.div`
    display: grid;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    padding: 0.5rem 1.25rem;
    border-radius: 10px;
`;

export const AnalyticsCount: FC<{ amount: number; hole?: boolean }> = ({
    children,
    amount,
    hole,
}) => {
    return (
        <SAnalyticsCount>
            <Text oneline H4 semiBold medium>
                {children}
            </Text>
            <Text oneline H2>
                {amount.toFixed(hole ? 0 : 2)}
            </Text>
        </SAnalyticsCount>
    );
};

const SPageButton = styled.div`
    display: grid;
    grid-template-columns: auto 1.5rem;
    padding: 0.75rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};

    ${SVG} {
        height: 1.5rem;
    }

    :hover {
        ${SVG} {
            background-color: ${(p) => p.theme.colors.main.primary};
        }

        ${Text} {
            color: ${(p) => p.theme.colors.main.primary};
        }
    }

    cursor: pointer;
`;

export const PageButton: FC<{ icon: string; onClick(): void }> = (props) => {
    return (
        <SPageButton onClick={() => props.onClick()}>
            <Text middle oneline H5 bold>
                {props.children}
            </Text>
            <SVG image={props.icon} />
        </SPageButton>
    );
};

const STitle = styled.div`
    height: 4.375rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
`;

export const Title: FC<{
    buttonAction?(): void;
    buttonText?: string;
    options?: IOption[];
    small?: boolean;
}> = (props) => {
    const [optionsState, setOptionsState] = useState(false);
    const ref = useRef();
    useOutsideAlerter(ref, () => setOptionsState(false));

    // Check if it has options menu or not.
    function clickHandler() {
        if (props.options)
            setOptionsState(!optionsState); // Toggle options state.
        else if (props.buttonAction) props.buttonAction(); // Just call buttonAction function.
    }

    return (
        <STitle>
            <Text H2={!props.small} H3={props.small} middle>
                {props.children}
            </Text>
            {props.buttonText && (
                //@ts-ignore
                <div ref={ref}>
                    <Button
                        style={{ position: "relative" }}
                        reverse
                        icon={icons.add}
                        onClick={() => clickHandler()}
                    >
                        {props.buttonText}
                        {props.options && (
                            <OptionsMenu
                                active={optionsState}
                                options={props.options}
                            />
                        )}
                    </Button>
                </div>
            )}
        </STitle>
    );
};

export const SScroll = styled.div<{ padding?: string }>`
    overflow: auto;
    overflow-x: hidden;
    padding: 0 ${(p) => p.padding ?? "8.875rem"};
    height: inherit;
`;

const SOptionsMenu = styled.div<{ active: boolean }>`
    background-color: ${(p) => p.theme.colors.neutral["60"]};
    border-radius: 5px;
    padding: 0.625rem 0.813rem;

    transition: 0.3s;
    opacity: ${(p) => (p.active ? 1 : 0)};
    transform: scale(${(p) => (p.active ? 1 : 0.95)})
        translateY(${(p) => (p.active ? "100%" : "98%")});
    pointer-events: ${(p) => (p.active ? "all" : "none")};

    position: absolute;
    right: 0;
    bottom: -0.188rem;
`;

const SOption = styled.div`
    cursor: pointer;
    :hover {
        ${Text} {
            color: ${(p) => p.theme.colors.main.primary};
        }
        ${SVG} {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }

    display: grid;
    grid-template-columns: 1.5rem auto;
    grid-gap: 0.438rem;
    height: 2rem;
    width: min-content;
`;

interface IOption {
    icon: string;
    text: string;
    action(): void;
}

const OptionsMenu: FC<{ active: boolean; options: IOption[] }> = (props) => {
    return (
        <SOptionsMenu {...props}>
            {props.options.map((o) => (
                <SOption key={o.text} onClick={() => o.action()}>
                    <SVG image={o.icon} light contain />
                    <Text oneline H5 light>
                        {o.text}
                    </Text>
                </SOption>
            ))}
        </SOptionsMenu>
    );
};

const SBackRow = styled.div`
    height: 4.5rem;
    display: grid;
    align-items: center;
    padding: 0 8.75rem;
    grid-gap: 0.5rem;

    grid-template-columns: min-content auto min-content min-content;
`;

const SBack = styled.div`
    display: grid;
    grid-template-columns: 2.25rem min-content;
    height: 2.25rem;
    cursor: pointer;
    :hover {
        ${Text} {
            color: ${(p) => p.theme.colors.main.primary};
        }
        ${SVG} {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }
`;

export const BackRow: FC<{
    onClick(): void;
    nextHandler?(): void;
    nextText?: string;
    secondText?: string;
    secondHandler?(): void;
}> = (props) => {
    return (
        <SBackRow>
            <SBack {...props}>
                <SVG image={icons.back} dark />
                <Text middle H4 semiBold oneline>
                    Back
                </Text>
            </SBack>

            <div />

            {props.secondHandler && (
                <Button
                    width="12rem"
                    reverse
                    icon={icons.desktop}
                    onClick={() => props.secondHandler && props.secondHandler()}
                    secondary
                >
                    {props.secondText ?? "Second Text"}
                </Button>
            )}

            {props.nextHandler && (
                <Button
                    width="12rem"
                    reverse
                    icon={icons.arrow}
                    onClick={() => props.nextHandler && props.nextHandler()}
                >
                    {props.nextText ?? "Next activity"}
                </Button>
            )}
        </SBackRow>
    );
};
