import React, { FC } from "react";
import styled from "styled-components";
import { Title } from "../../../components/Common";
import FileSelector, { STextRow } from "../../../components/FileSelector";
import AssetSelector from "../../../components/modal/AssetSelector";
import Confirm from "../../../components/modal/Confirm";
import { Button, Text, Toggle } from "../../../components/UI";
import { uploadFile } from "../../../firebase/storage";
import { useModal, useNotification, useStore } from "../../../hooks";
import { IAsset, IExperienceVideo } from "../../../store/scheme";
import {
    copyHlsHandler,
    createAsset,
    viewHlsHandler,
} from "../../assets/Videos";

const SExperienceVideo = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`;

const SHLSRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
`;

const ExperienceVideo: FC<{
    experience: IExperienceVideo;
    setExperience: React.Dispatch<React.SetStateAction<IExperienceVideo>>;
}> = ({ experience, setExperience }) => {
    const store = useStore();
    const modal = useModal();
    const notification = useNotification();
    const video: IAsset = store.state.assetVideo?.[experience?.videoId ?? ""];

    function assetSelectedHandler(videoId: string): void {
        setExperience((e) => ({ ...e, videoId }));
    }

    function onSelect(): void {
        modal.setContent(
            <AssetSelector
                onSelect={(id) => assetSelectedHandler(id)}
                storePath="assetVideo"
                title="Select Video"
            />
        );
        modal.setActive(true);
    }

    //#region Upload Asset
    function handleChange(file: File): void {
        modal.setContent(
            <Confirm
                title={`Upload ${file.name} as new video asset?`}
                confirmHandler={async () => {
                    const newAsset: IAsset = await createAsset(
                        file,
                        notification,
                        store
                    );
                    if (newAsset?.id) assetSelectedHandler(newAsset.id);
                }}
            />
        );
        modal.setActive(true);
    }
    //#endregion

    return (
        <SExperienceVideo>
            <Title>Video</Title>
            <FileSelector
                title="Video Asset"
                fileHandler={(file) => handleChange(file)}
                fileName={video?.name}
                deleteHandler={() =>
                    setExperience((e) => ({ ...e, videoId: undefined }))
                }
            />
            <STextRow>
                <Text H6 bold medium>
                    Also, you can{" "}
                </Text>
                <Text H6 bold primary onClick={() => onSelect()}>
                    select an existing asset
                </Text>
            </STextRow>
            {video?.hls && (
                <SHLSRow>
                    <Button onClick={() => viewHlsHandler(video)}>
                        Preview HLS
                    </Button>
                    <Button onClick={() => copyHlsHandler(video, notification)}>
                        Copy HLS Link
                    </Button>
                </SHLSRow>
            )}
            <div />
            <Text H4 bold>
                360 video
            </Text>
            <Toggle
                title="This is a 360 video"
                active={experience.is360}
                onClick={() =>
                    setExperience((p) => ({ ...p, is360: !p.is360 }))
                }
                type="checkbox"
            />
            <Text H4 bold>
                Source type
            </Text>
            <Toggle
                title="Monoscopic"
                active={!experience.isStereo}
                onClick={() =>
                    setExperience((p) => ({ ...p, isStereo: false }))
                }
                type="circle"
            />
            <Toggle
                title="Stereoscopic"
                active={experience.isStereo}
                onClick={() => setExperience((p) => ({ ...p, isStereo: true }))}
                type="circle"
            />
        </SExperienceVideo> //
    );
};

export default ExperienceVideo;
