import { FC, useEffect, useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import styled from "styled-components";
import { SPageHolder } from ".";
import icons from "../assets/icons";
import { SScroll } from "../components/Common";
import Sortable from "../components/Sortable";
import { Button, Image, Text } from "../components/UI";
import { getExperienceParam, Paths } from "../context/RouterContext";
import { useOrganization, useRouter, useStore } from "../hooks";
import { NEW_IDENTIFIER } from "../store";
import {
    IExperience,
    IApplication,
    IOrganization,
    Dictionary,
} from "../store/scheme";

const SApplications = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content auto;
`;

const STitle = styled.div`
    height: 3rem;
    padding: 0 1.438rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
`;

const SList = styled.div`
    display: grid;
    padding: 2rem 0;
`;

const Applications = () => {
    const organization = useOrganization();
    const router = useRouter();
    const store = useStore();

    function getApplications() {
        // Get applications list.
        return Object.values(organization.applications ?? {}).map((e) => (
            <ApplicationTag application={e} key={e.id} />
        ));
    }

    function newHandler(): void {
        router.setParam("application", NEW_IDENTIFIER);
        router.setPath(Paths.ApplicationOverview, true);
    }

    function sortHandler(from: number, to: number): void {
        const applications = Object.values(organization.applications ?? {});
        const sorted = arrayMove(applications, from, to);

        const editOrg: IOrganization = {
            ...organization.organization,
            applications: sorted.reduce(
                (obj, app) => ({ ...obj, [app?.id ?? "id"]: app?.id ?? "id" }),
                {}
            ) as Dictionary<string>,
        };

        if (editOrg.id)
            store.dispatch({
                type: "organization",
                action: "set",
                object: editOrg,
            });
    }

    return (
        <SApplications>
            <STitle>
                <Text H5 bold middle>
                    Applications
                </Text>
                <div />
                <Button icon={icons.add} onClick={() => newHandler()} reverse>
                    New
                </Button>
            </STitle>
            <SScroll padding="3rem">
                <SPageHolder>
                    <Sortable
                        axis="y"
                        items={getApplications()}
                        sortHandler={sortHandler}
                    />
                </SPageHolder>
            </SScroll>
        </SApplications>
    );
};

const SApplicationTag = styled.div`
    height: 16.688rem;
    cursor: pointer;
    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;
    }
    border-radius: 10px;
    padding: 1rem 1.5rem;
`;

const SApplicationPanel = styled.div`
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 10px;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 4.563rem;
    padding: 1.5rem;
`;

const SCard = styled.div<{ big?: boolean }>`
    display: grid;
    grid-template-rows: auto min-content min-content;
    grid-gap: 0.5rem;
`;

const ApplicationTag: FC<{ application: IApplication }> = ({ application }) => {
    const router = useRouter();
    const experienceCount =
        Object.values(application?.experiences?.images ?? {}).length +
        Object.values(application?.experiences?.videos ?? {}).length +
        Object.values(application?.experiences?.interactives ?? {}).length +
        Object.values(application?.experiences?.virtualTours ?? {}).length;

    const store = useStore();

    function getExperiences() {
        if (experienceCount === 0) return [];
        // return application?.experiences
        //     ?.map((experienceId) => store.state.activity[experienceId]) // Get Activities.
        //     .filter((a) => a)
        //     .slice(0, 3) // Get First 3 only.
        //     .map((a) => (
        //         <SCard key={a.id}>
        //             <Image image={a.thumbnail} round />
        //             <Text H4 primary semiBold>
        //                 {a.name ?? "Unnamed"}
        //             </Text>
        //             <Text H5 oneline>
        //                 {getType(a)}
        //             </Text>
        //         </SCard>
        //     ));
    }

    return (
        <SApplicationTag>
            <SApplicationPanel
                onClick={() => {
                    router.setParam("application", application.id); // Select application.
                    router.setPath(Paths.ApplicationOverview, true); // And go to overview page.
                }}
            >
                <SCard big>
                    <Image image={application.thumbnail} round />
                    <Text H4 primary semiBold>
                        {application.name ?? "Unnamed"}
                    </Text>
                    <Text H5 oneline>{`${experienceCount} ${
                        experienceCount === 1 ? "Activity" : "Activities"
                    }`}</Text>
                </SCard>

                {getExperiences()}
            </SApplicationPanel>
        </SApplicationTag>
    );
};

export default Applications;
