import firebase, { userRegisterApp } from "./";

// Login to firebase auth with email and password.
export const logIn = (email: string, password: string) =>
    new Promise(async (resolve, reject) => {
        try {
            await firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            const response = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

// Logout from firebase auth.
export const logOut = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await firebase.auth().signOut();
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

// Register to firebase auth.
export const register = (
    email: string,
    password: string,
    autoLogin: boolean = true
) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await (autoLogin ? firebase : userRegisterApp)
                .auth()
                .createUserWithEmailAndPassword(email, password);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

// Listen to auth user state changes.
export const trackAuthState = (observer: any) => {
    return firebase.auth().onAuthStateChanged(observer);
};

// Send reset password email.
export const sendResetPasswordEmail = (email: string) =>
    new Promise((resolve, reject) => {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(resolve)
            .catch((e) => reject(e.message));
    });

// Get current logged user from cache.
export const getCurrentUser = () => firebase.auth().currentUser;

export const signInAnonymously = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await firebase.auth().signInAnonymously();
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
