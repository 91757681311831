import React from "react";
import styled from "styled-components";
import { SScroll } from "../components/Common";

import Navbar from "../components/Navbar";
import { Paths } from "../context/RouterContext";
import { useOrganization, useRouter } from "../hooks";
import Images from "./assets/Images";
import Videos from "./assets/Videos";

const SAssets = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const Assets = () => {
    const router = useRouter();
    const organizationContext = useOrganization();
    const organization = organizationContext.organization ?? {};

    const tabs = {
        [Paths.AssetsVideos]: {
            name: "Videos",
            path: Paths.AssetsVideos,
            content: <Videos />,
        },
        [Paths.AssetsImages]: {
            name: "Images",
            path: Paths.AssetsImages,
            content: <Images />,
        },
    };

    return (
        <SAssets>
            <Navbar tabs={Object.values(tabs)} />
            {/* @ts-ignore */}
            <SScroll>{tabs?.[router.state.path]?.content}</SScroll>
        </SAssets>
    );
};

export default Assets;
