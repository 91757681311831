import React, { FC } from "react";
import styled from "styled-components";
import { Title } from "../../../components/Common";
import { InputField } from "../../../components/UI";
import {
    IExperienceInteractive,
    IExperienceVirtualTour,
} from "../../../store/scheme";

const SExperienceInteractive = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`;

const ExperienceInteractive: FC<{
    experience: IExperienceInteractive | IExperienceVirtualTour;
    setExperience: React.Dispatch<
        React.SetStateAction<IExperienceInteractive | IExperienceVirtualTour>
    >;
    title: string;
}> = ({ experience, setExperience, title }) => {
    return (
        <SExperienceInteractive>
            <Title>{title}</Title>
            <InputField
                title="Unity Scene Name"
                onChange={(unitySceneName) =>
                    setExperience((p) => ({ ...p, unitySceneName }))
                }
                value={experience.unitySceneName ?? ""}
            />
            <InputField
                title="Version"
                onChange={(versionString) => {
                    const version: number = Number.parseInt(versionString) ?? 0;
                    setExperience((p) => ({ ...p, version }));
                }}
                type="number"
                value={experience.version?.toString() ?? ""}
            />
        </SExperienceInteractive>
    );
};

export default ExperienceInteractive;
