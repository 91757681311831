import React, { FC, useState, useEffect } from "react";
import { setOrganizationApp } from "../firebase";
import { useAuth, useStore, useTheme } from "../hooks";
import {
    Dictionary,
    ERole,
    IApplication,
    IOrganization,
    RoleData,
} from "../store/scheme";
import { useRouterZuState } from "./RouterContext";

const Organization = React.createContext({});

export interface IOrganizationContext {
    organization?: IOrganization;
    applications?: Dictionary<IApplication>;
    accessLevel: number;
    isPanelActive: boolean;
    setIsPanelActive(value: boolean): void;
}

export const OrganizationContext: FC = (props) => {
    const [isPanelActive, setIsPanelActive] = useState(false);
    const [accessLevel, setAccessLevel] = useState(-1);
    const auth = useAuth();
    const store = useStore();
    const theme = useTheme();
    const routerZuState = useRouterZuState();
    const [selected, select] = useState<string>();

    useEffect(() => {
        if (routerZuState.state?.organization) {
            select(routerZuState.state.organization.substring(1));
            setIsPanelActive(false);
        } else if (!isPanelActive) setIsPanelActive(true);
    }, [routerZuState.state?.organization]);

    useEffect(() => {
        const defaultColor = "#00ADEF";
        const updateTheme = { ...theme.theme };

        try {
            updateTheme.colors.main.primary =
                store.state.organization[selected ?? ""].skin?.colors?.main
                    ?.primary ?? defaultColor;
        } catch {
            updateTheme.colors.main.primary = defaultColor;
        }

        theme.setTheme(updateTheme);
    }, [selected, store.state.organization[selected ?? ""]]);

    useEffect(() => {
        const organization = store.state.organization[selected ?? ""];
        if (organization?.id) {
            setOrganizationApp(organization); // Set firebase organization app (database and storage).
            store.localActions.init();
        }
    }, [selected, store.state.organization]);

    const value: IOrganizationContext = {
        organization: store.state.organization[selected ?? ""],
        applications: Object.values(
            store.state.organization[selected ?? ""]?.applications ?? {}
        )
            .filter((id) => store.state.application[id])
            .reduce(
                (obj, id) => ({
                    ...obj,
                    [id]: store.state.application[id],
                }),
                {}
            ),
        accessLevel,
        isPanelActive,
        setIsPanelActive,
    };

    return (
        <Organization.Provider value={value}>
            {props.children}
        </Organization.Provider>
    );
};

export default Organization;
