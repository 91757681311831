import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";

import { Image, Text } from "./UI";

import ProfileMenu from "./ProfileMenu";
import { useAuth, useOrganization, useRouter, useStore } from "../hooks";
import { ISkin } from "../store/scheme";
import { Paths } from "../context/RouterContext";

const STopbar = styled.div`
    background-color: ${(p) => p.theme.colors.main.primary};
    grid-column: 1/3;
    grid-row: 1/2;

    padding: 0 1.5rem;
    display: grid;
    /* grid-template-columns: 7.563rem auto 6rem; */
    grid-template-columns: 7.563rem auto 13rem;
    place-items: center;
`;

const SLogo = styled.div`
    width: 7.563rem;
    height: 2rem;
    cursor: pointer;
`;

const SLoginButton = styled.div`
    :hover {
        transform: scale(1.02);
    }
    transition: 0.3s;

    cursor: pointer;
`;

const Topbar: FC = (props) => {
    const organization = useOrganization();
    const router = useRouter();
    const store = useStore();
    const auth = useAuth();

    function loginHandler(): void {
        auth.set((p) => ({ ...p, isPanelActive: true }));
    }

    return (
        <STopbar>
            <SLogo onClick={() => router.setPath(Paths.Applications)}>
                <Image
                    image={organization?.organization?.skin?.logos?.primary}
                    contain
                />
            </SLogo>
            <div />
            <SLoginButton onClick={() => router.setOrganization()}>
                <Text middle H4 semiBold light oneline>
                    Change Organisation
                </Text>
            </SLoginButton>
            {/* {auth.state.user?.userId ? (
                <ProfileMenu />
            ) : (
                <SLoginButton onClick={() => loginHandler()}>
                    <Text middle H4 semiBold light>
                        Login
                    </Text>
                </SLoginButton>
            )} */}
        </STopbar>
    );
};

export default Topbar;
