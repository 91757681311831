import React, { useState, useEffect, useRef, FC } from "react";
import {
    logIn,
    trackAuthState,
    register,
    logOut as firebaseLogOut,
    signInAnonymously,
} from "../firebase/auth";
import { useStore } from "../hooks";

const Auth = React.createContext({});

export interface IUser {
    id?: string;
    email?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    phoneNo?: string;
    profilePicture?: string;
    organizationRoles?: string[];

    root?: boolean;
}

export interface IAuth {
    state: IAuthState;
    set: React.Dispatch<React.SetStateAction<IAuthState>>;
    logIn(email: string, password: string): Promise<IUser>;
    register(
        user: IUser,
        password: string,
        autoLogin?: boolean
    ): Promise<IUser>;
    logOut(): void;
}

export interface IAuthState {
    user?: IUser;
    isPanelActive: boolean;
    isLoading: boolean;
    isTrackingAuthState?: boolean;
    loginCallback?(user: IUser): void;
}

export const AuthContext: FC = (props) => {
    const store = useStore();
    const [state, set] = useState<IAuthState>({
        isPanelActive: true,
        isLoading: true,
    });

    const stateRef = useRef(state);
    stateRef.current = state;

    // Attemp to login, set loginCallback for later usage.
    function logInHandler(email: string, password: string): Promise<IUser> {
        return new Promise(async (resolve, reject) => {
            try {
                set((prev) => ({
                    ...prev,
                    loginCallback: function (user: IUser) {
                        resolve(user);
                    },
                }));
                await logIn(email, password);
            } catch (error) {
                reject(error);
            }
        });
    }

    // Clear user from state, and logout from firebase.
    function logOutHandler(): void {
        set((prev) => ({
            ...prev,
            user: undefined,
            isTrackingAuthState: false,
        }));
        firebaseLogOut();
    }

    // Register to firebase auth, and write new user to database.
    function registerHandler(
        user: IUser,
        password: string,
        autoLogin: boolean = true
    ): Promise<IUser> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!user.email) return;
                const response = (await register(
                    user.email,
                    password,
                    autoLogin
                )) as any;
                // Return user with generated uid.
                resolve({ ...user, id: response.user.uid });
            } catch (error) {
                reject(error);
            }
        });
    }

    const auth: IAuth = {
        state,
        set,
        logIn: logInHandler,
        register: registerHandler,
        logOut: logOutHandler,
    };

    useEffect(() => {
        async function handleStateChange(localUser: any) {
            if (localUser) {
                set((p) => {
                    const user = { email: localUser.email, id: localUser.uid };
                    if (p.loginCallback) p.loginCallback(user);
                    return {
                        ...p,
                        user,
                        isTrackingAuthState: true,
                        isLoading: false,
                        isPanelActive: false,
                    };
                });

                // Initialize store.
                store.localActions.getOrganizations();
            } else {
                set((p) => ({
                    ...p,
                    user: undefined,
                    isTrackingAuthState: true,
                    isLoading: false,
                    isPanelActive: true,
                }));
            }
        }

        if (!state.isTrackingAuthState) trackAuthState(handleStateChange);
    }, [state.isTrackingAuthState]);

    return <Auth.Provider value={auth}>{props.children}</Auth.Provider>;
};

export default Auth;
