import { useState, useEffect, useContext } from 'react'
import { fileExists, getUrl } from '../firebase/storage'

import Router, { IRouter } from '../context/RouterContext'
import Theme, { ITheme } from '../context/ThemeContext'
import Auth, { IAuth } from '../context/AuthContext'
import Organization, { IOrganizationContext } from '../context/OrganizationContext'
import Store, { IStore, NEW_IDENTIFIER } from '../store'
import Modal, { IModal } from '../context/ModalContext'
import Profile, { IProfile } from '../context/ProfileContext'
import Notification, { INotification } from '../context/NotificationContext'
import { IExperience } from '../store/scheme'

export function useAwakeBool() {
	const [state, set] = useState(false)
	useEffect(() => { if (!state) set(true) }, [state])
	return state
}

export function useUrl(path: string) {
	const [url, setUrl] = useState<string>()

	useEffect(() => {
		if (path) getUrl(path).then(setUrl)
	}, [path])

	return url
}

export function useFileExists(path: string, version: number = 1) {
	const [state, set] = useState<{ loading: boolean, value: boolean }>({
		loading: true,
		value: false
	})

	useEffect(() => {
		set({ loading: true, value: false })
		fileExists(path).then(v => set({ loading: false, value: v }))
	}, [path, version])

	return state
}

export function useRouter() {
	const router = useContext(Router) as IRouter
	return router
}

export function useTheme() {
	const theme = useContext(Theme) as ITheme
	return theme
}

export function useAuth() {
	const auth = useContext(Auth) as IAuth
	return auth
}

export function useOrganization() {
	const organization = useContext(Organization) as IOrganizationContext
	return organization
}

export function useStore() {
	const store = useContext(Store) as IStore
	return store
}

export function useModal() {
	const modal = useContext(Modal) as IModal
	return modal
}

export function useProfile() {
	const proifle = useContext(Profile) as IProfile
	return proifle
}

export function useNotification() {
	const notification = useContext(Notification) as INotification
	return notification
}

export function useOutsideAlerter(ref: any, callback: () => void) {
	useEffect(() => {
		// Alert if clicked on outside of element
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback()
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [ref])
}

export function useValidator() {
	const notification = useNotification()

	function validate(fields: { value: any, message: string, custom?: boolean }[]): boolean {
		for (const field of fields) {
			if (field.custom || !field.value || field.value === '') {
				notification.notify(field.message, 'error')
				return false
			}
		}

		return true
	}

	return validate
}
