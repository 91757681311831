import { useEffect, useRef, FC, useState } from "react";
import styled, { css } from "styled-components";
import icons from "../assets/icons";

import { useUrl } from "../hooks";
import { SIcon } from "./Login";

const SImageContainer = styled.div<{ round?: boolean }>`
    height: 100%;
    border-radius: ${(p) => (p.round ? "10px" : 0)};
    overflow: hidden;
`;

const SImage = styled.div<{
    image?: string;
    placeholder?: string;
    contain?: boolean;
    fit?: boolean;
}>`
    background-image: url(${(p) => p.image}), url(${(p) => p.placeholder});
    background-size: ${(p) =>
        p.contain ? "contain" : p.fit ? "100% 100%" : "cover"};
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
`;

interface IImageProps {
    url?: string;
    image?: string;
    placeholder?: string;
    contain?: boolean;
    fit?: boolean;
    round?: boolean;
}

export const Image: FC<IImageProps> = (props) => {
    const url = useUrl(props.url ?? "");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.image) {
            const preloader = document.createElement("img");
            preloader.src = props.image;
            preloader.addEventListener("load", (event) => {
                setLoading(false);
                preloader.remove();
            });
        } else setLoading(false);
    }, [props.image]);

    return (
        <SImageContainer {...props}>
            {loading ? (
                <SIcon>
                    <SVG contain image={icons.vrArDevice} primary />
                </SIcon>
            ) : (
                <SImage {...props} image={url ? url : props.image}>
                    {" "}
                    {props.children}{" "}
                </SImage>
            )}
        </SImageContainer>
    );
};

interface ITextProps {
    H1?: boolean;
    H2?: boolean;
    H3?: boolean;
    H4?: boolean;
    H5?: boolean;
    H6?: boolean;

    bold?: boolean;
    semiBold?: boolean;
    oneline?: boolean;
    center?: boolean;
    middle?: boolean;
    bottom?: boolean;
    onClick?(): void;

    light?: boolean;
    medium?: boolean;
    dark?: boolean;
    primary?: boolean;
    error?: boolean;
    warning?: boolean;
    success?: boolean;
}

export const Text = styled.div<ITextProps>`
    box-sizing: border-box;
    font-size: ${(p) => {
        if (p.H1) return p.theme.fontSize.H1;
        if (p.H2) return p.theme.fontSize.H2;
        if (p.H3) return p.theme.fontSize.H3;
        if (p.H4) return p.theme.fontSize.H4;
        if (p.H5) return p.theme.fontSize.H5;
        if (p.H6) return p.theme.fontSize.H6;
        // Default
        return p.theme.fontSize.H3;
    }};
    font-weight: ${(p) => (p.bold ? "bold" : p.semiBold ? 600 : "normal")};
    color: ${(p) => {
        if (p.light) return p.theme.colors.text.light;
        if (p.medium) return p.theme.colors.text.medium;
        if (p.dark) return p.theme.colors.text.dark;
        if (p.primary) return p.theme.colors.main.primary;
        if (p.error) return p.theme.colors.information.error;
        if (p.warning) return p.theme.colors.information.warning;
        if (p.success) return p.theme.colors.information.success;
        // Default dark
        return p.theme.colors.text.dark;
    }};
    height: 100%;
    white-space: ${(p) => (p.oneline ? "nowrap" : "pre-line")};

    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    ${(p) =>
        p.onClick &&
        css`
            cursor: pointer;
            :hover {
                color: ${(p) => p.theme.colors.main.primary};
            }
        `}

    ${(p) =>
        p.middle &&
        css`
            display: flex;
            align-items: center;

            ${p.center &&
            css`
                justify-content: center;
            `}
        `}

    ${(p) =>
        p.bottom &&
        css`
            display: flex;
            align-items: flex-end;

            ${p.center &&
            css`
                justify-content: center;
            `}
        `}
`;

export const SVG = styled.div<{
    image: string;
    contain?: boolean;
    light?: boolean;
    medium?: boolean;
    dark?: boolean;
    primary?: boolean;
}>`
    mask-image: url(${(p) => p.image});
    mask-size: ${(p) => (p.contain ? "contain" : "cover")};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${(p) => {
        if (p.light) return p.theme.colors.icon.light;
        if (p.medium) return p.theme.colors.icon.medium;
        if (p.dark) return p.theme.colors.icon.dark;
        if (p.primary) return p.theme.colors.main.primary;
        // Default
        return p.theme.colors.icon.dark;
    }};

    height: 100%;
`;

const SInputField = styled.input<{ center?: boolean }>`
    font-size: 0.813rem;
    ::placeholder {
        color: ${(p) => p.theme.colors.main.secondary};
    }
    font-weight: 600;
    color: ${(p) => p.theme.colors.text.dark};
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}
    width: 100%;
    height: 100%;
    border: none;
    padding: 0.75rem;
    border-radius: 0.313rem;
    display: grid;
`;

const SInputFieldContainer = styled.div<{ icon?: string; disabled?: boolean }>`
    box-sizing: border-box;
    height: 1.875rem;
    background-color: ${(p) =>
        p.disabled
            ? p.theme.colors.neutral["10"]
            : p.theme.colors.neutral["00"]};
    position: relative;

    ${(p) =>
        !p.disabled &&
        css`
            :hover {
                filter: brightness(98%);
            }
        `}

    border-radius: .313rem;
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["05"]};

    ${(p) =>
        p.icon &&
        css`
            ${SVG} {
                position: absolute;
                left: 0;
                width: 1.875rem;
                height: 1.875rem;
            }

            ${SInputField} {
                padding-left: 1.875rem;
            }
        `}
`;

const SInputFieldWithTitle = styled.div`
    display: grid;
    grid-gap: 0.188rem;
`;

interface IInputFieldProps {
    autoSelect?: boolean;
    onKeyDown?(e: any): void;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    onEnter?(): void;
    type?: string;
    icon?: string;
    grayIcon?: boolean;
    style?: any;
    title?: string;
    disabled?: boolean;
}

export const InputField: FC<IInputFieldProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <SInputFieldContainer icon={props.icon} disabled={props.disabled}>
            {props.icon && <SVG image={props.icon} medium={props.grayIcon} />}
            <SInputField
                //@ts-ignore
                ref={inputRef}
                {...props}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && props.onEnter) props.onEnter();
                }}
                value={props.value ? props.value : ""}
                onChange={
                    props.disabled
                        ? () => {}
                        : (e) => props.onChange(e.target.value)
                }
                type={props.type ? props.type : "text"}
            />
        </SInputFieldContainer>
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text semiBold H5>
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};

const STextArea = styled.textarea<{ center?: boolean }>`
    height: 5.75rem;
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["10"]};
    font-size: 11pt;

    background-color: ${(p) => p.theme.colors.neutral["00"]};
    padding: 0.688rem;
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    color: ${(p) => p.theme.colors.text.dark};

    :hover {
        filter: brightness(98%);
    }

    border-radius: 5px;
    resize: none;
`;

interface ITextAreaProps {
    autoSelect?: boolean;
    onKeyDown?(e: any): void;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    type?: string;
    style?: any;
    rows?: number;
    cols?: number;
    title?: string;
}

export const TextArea: FC<ITextAreaProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <STextArea
            //@ts-ignore
            ref={inputRef}
            {...props}
            value={props.value ? props.value : ""}
            onChange={(e) => props.onChange(e.target.value)}
        />
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text semiBold H5>
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};

const ButtonContainer = styled.div<{
    center?: boolean;
    secondary?: boolean;
    icon?: boolean;
    reverse?: boolean;
    transparent?: boolean;
    disabled?: boolean;
    onlyIcon?: boolean;
    width?: string;
}>`
    box-sizing: border-box;
    height: 1.87rem;
    background-color: ${(p) =>
        p.secondary
            ? p.theme.colors.neutral["00"]
            : p.transparent
            ? "none"
            : p.theme.colors.main.primary};
    ${(p) =>
        p.secondary &&
        css`
            border: 0.063rem solid ${(p) => p.theme.colors.main.primary};
        `}

    width: ${(p) => p.width};

    ${(p) =>
        !p.disabled
            ? css`
                  :hover {
                      filter: brightness(120%);

                      ${Text} {
                          color: ${p.secondary
                              ? p.theme.colors.main.primary
                              : p.theme.colors.text.light};
                      }
                  }
                  cursor: pointer;
              `
            : css`
                  opacity: 0.3;
              `}

    ${Text} {
        display: flex;
        align-items: center;
        ${(p) =>
            p.center &&
            css`
                justify-content: center;
            `}
    }

    padding: 1rem 1.5rem;

    border-radius: 5px;

    ${(p) =>
        p.icon &&
        css`
            padding: 0 0.5rem;
            display: grid;
            align-items: center;
            /* justify-items: center; */
            grid-gap: 1.5rem;

            grid-template-columns: ${p.reverse ? `auto 1.5rem` : `1.5rem auto`};

            ${!p.disabled &&
            p.secondary &&
            css`
                :hover {
                    ${SVG} {
                        background-color: ${(p) => p.theme.colors.main.primary};
                    }
                }
            `}

            ${p.onlyIcon &&
            css`
                width: 1.87rem;
                grid-template-columns: 1.87rem;
                padding: 0;
            `}
        `}
`;

type TButtonProps = {
    onClick(): void;
    secondary?: boolean;
    transparent?: boolean;
    icon?: any;
    disabled?: boolean;
    left?: boolean;
    reverse?: boolean;
    style?: any;
    width?: string;
};

export const Button: FC<TButtonProps> = (props) => {
    function getContent() {
        let list = [];

        if (props.icon)
            list.push(
                <SVG
                    style={
                        props.children
                            ? {
                                  marginRight: ".375rem",
                                  height: "1.5rem",
                                  width: "1.5rem",
                              }
                            : {
                                  margin: "auto",
                                  height: "1.87rem",
                                  width: "1.87rem",
                              }
                    }
                    contain
                    key="icon"
                    light={!props.secondary}
                    dark={props.secondary}
                    image={props.icon}
                />
            );
        if (props.children)
            list.push(
                <Text
                    key="text"
                    H5
                    oneline
                    semiBold
                    light={!props.secondary}
                    dark={props.secondary}
                >
                    {" "}
                    {props.children}{" "}
                </Text>
            );
        if (props.reverse) list = list.reverse();

        return list;
    }

    return (
        <ButtonContainer
            {...props}
            center={!props.icon}
            onlyIcon={!props.children}
            onClick={props.disabled ? () => false : () => props.onClick()}
        >
            {getContent()}
        </ButtonContainer>
    );
};

const SRoundIconButton = styled.div`
    height: 80%;
    width: 80%;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.main.primary};
    :hover {
        filter: brightness(120%);
    }
    cursor: pointer;

    display: grid;
    place-items: center;
    * {
        width: 100%;
        height: 100%;
    }
`;

export function RoundIconButton(props: { icon: string; onClick(): void }) {
    return (
        <SRoundIconButton onClick={() => props.onClick()}>
            <SVG contain image={props.icon} />
        </SRoundIconButton>
    );
}

const SToggle = styled.div<{ active?: boolean }>`
    width: 1.87rem;
    height: 0.938rem;
    cursor: pointer;
    background-color: ${(p) =>
        p.active ? p.theme.colors.main.primary : p.theme.colors.neutral["00"]};
    border-radius: 1.87rem;
    border: 0.125rem solid ${(p) => p.theme.colors.main.primary};
    box-sizing: border-box;
    display: grid;
    place-items: center;

    padding: 0 0.1rem;
    justify-content: ${(p) => (p.active ? "right" : "left")};

    * {
        width: ${(p) => (p.active ? ".688rem" : ".563rem")};
        height: ${(p) => (p.active ? ".688rem" : ".563rem")};
        background-color: ${(p) =>
            p.active
                ? p.theme.colors.neutral["00"]
                : p.theme.colors.main.primary};
    }

    :hover {
        filter: brightness(120%);
    }
`;

const SToggleHandler = styled.div`
    border-radius: 50%;
`;

const SCheckbox = styled.div<{ active?: boolean }>`
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-color: ${(p) =>
        p.active ? p.theme.colors.main.primary : p.theme.colors.neutral["00"]};
    border-radius: 0.156rem;
    border: 0.063rem solid ${(p) => p.theme.colors.main.primary};
    box-sizing: border-box;
    display: grid;
    place-items: center;

    :hover {
        filter: brightness(120%);
    }
`;

const SCheck = styled.div`
    display: inline-block;
    transform: translateY(-8%) rotate(45deg);
    height: 60%;
    width: 20%;
    border-bottom: 0.12rem solid ${(p) => p.theme.colors.neutral["00"]};
    border-right: 0.12rem solid ${(p) => p.theme.colors.neutral["00"]};
`;

const SCheckboxRec = styled.div`
    border-radius: 0.156rem;
    border: 0.063rem solid ${(p) => p.theme.colors.main.primary};
    box-sizing: border-box;
`;

const SCheckboxGrid = styled.div<{ active?: boolean; grid?: boolean }>`
    width: 1.125rem;
    height: 1.125rem;
    display: grid;
    grid-gap: 0.125rem;
    cursor: pointer;
    box-sizing: border-box;

    grid-template-rows: repeat(2, 1fr);
    ${(p) =>
        p.grid &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}

    * {
        background-color: ${(p) =>
            p.active
                ? p.theme.colors.main.primary
                : p.theme.colors.neutral["00"]};
    }
    :hover {
        filter: brightness(120%);
    }
`;

const SToggleContainer = styled.div`
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 0.563rem;
    width: min-content;
`;

const SCheckCircleContainer = styled.div`
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 50%;
    border: 0.063rem solid ${(p) => p.theme.colors.main.primary};
    box-sizing: border-box;
    display: grid;
    place-items: center;

    :hover {
        filter: brightness(120%);
    }
`;

const SCheckCircle = styled.div`
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.main.primary};
`;

interface IToggle {
    active?: boolean;
    onClick(): void;
    type?: "checkbox" | "list" | "grid" | "circle";
    title?: string;
}

export const Toggle: FC<IToggle> = (props) => {
    const types = {
        toggle: (
            <SToggle {...props}>
                <SToggleHandler />
            </SToggle>
        ),
        checkbox: (
            <SCheckbox {...props}>{props.active && <SCheck />}</SCheckbox>
        ),
        circle: (
            <SCheckCircleContainer {...props}>
                {props.active && <SCheckCircle />}
            </SCheckCircleContainer>
        ),
        list: (
            <SCheckboxGrid {...props}>
                <SCheckboxRec /> <SCheckboxRec />
            </SCheckboxGrid>
        ),
        grid: (
            <SCheckboxGrid {...props} grid>
                <SCheckboxRec /> <SCheckboxRec />
                <SCheckboxRec /> <SCheckboxRec />
            </SCheckboxGrid>
        ),
    };

    const toggle = types[props.type ?? "toggle"];

    if (props.title)
        return (
            <SToggleContainer>
                {toggle}
                <Text oneline H5 middle>
                    {props.title}
                </Text>
            </SToggleContainer>
        );

    return toggle;
};
