import React, { FC } from 'react'
import styled from 'styled-components'

import { SVG, Text, Button } from '../UI'
import { useModal } from '../../hooks'

const SConfirm = styled.div`
	display: grid;
	grid-gap: 0.688rem;
`

const SRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.313rem;
`

const SIcon = styled.div`
	height: 5.688rem;
`

interface IProps {
	icon?: string,
	title?: string,
	subTitle?: string,
	confirmHandler(): void
}

const Confirm: FC<IProps> = (props) => {
	const modal = useModal()

	function confirmHandler(): void {
		props.confirmHandler()
		modal.setActive(false)
	}

	return (
		<SConfirm>
			{props.icon && <SIcon><SVG image={props.icon} primary contain /></SIcon>}
			{props.title && <Text H3 semiBold dark center>{props.title}</Text>}
			{props.subTitle && <Text H5 medium center>{props.subTitle}</Text>}
			<div /> <div />
			<SRow>
				<Button secondary onClick={() => modal.setActive(false)}>Cancel</Button>
				<Button onClick={() => confirmHandler()}>OK</Button>
			</SRow>
		</SConfirm>
	)
}

export default Confirm
