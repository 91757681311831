import React from "react";
import styled from "styled-components";
import { SScroll } from "../../components/Common";

import Navbar from "../../components/Navbar";
import { IUser } from "../../context/AuthContext";
import { Paths } from "../../context/RouterContext";
import { useRouter, useStore } from "../../hooks";
import { NEW_IDENTIFIER } from "../../store";
import Profile from "./Profile";

const SUser = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const User = () => {
    const router = useRouter();
    const store = useStore();

    function getUser(): IUser {
        const userId = router.getParam("user") ?? "";

        try {
            if (userId.includes(NEW_IDENTIFIER)) return { id: userId };
        } catch {}

        return store.state.user?.[userId] ?? {};
    }

    const user = getUser();

    const tabs = {
        [Paths.UserProfile]: {
            name: "Profile",
            path: Paths.UserProfile,
            content: <Profile user={user} backHandler={() => backHandler()} />,
        },
    };

    function backHandler(): void {
        router.setPath(Paths.UserManagment); // Go back to user management page.
    }

    return (
        <SUser>
            <Navbar
                tabs={Object.values(tabs)}
                keepParams
                backHandler={() => backHandler()}
            />
            {/* @ts-ignore */}
            <SScroll>{tabs?.[router.state.path]?.content}</SScroll>
        </SUser>
    );
};

export default User;
