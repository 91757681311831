import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Text, Toggle } from "../../components/UI";
import {
    Dictionary,
    getExperienceTypeData,
    IApplication,
    IExperience,
    ILicense,
    TContentTypes,
} from "../../store/scheme";
import { useStore } from "../../hooks";
import { getSortedExperiences } from "./Overview";
import { Action } from "../../utility";
import useStateRef from "react-usestateref";
import { getRecoursiveSubContent } from "../../utility/collectionUtil";

const SContainer = styled.div`
    display: grid;
    height: min-content;
    grid-gap: 4px;
`;

const SExperiences = styled.div`
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["10"]};
    border-radius: 1px;
    padding: 8px;
    height: 203px;
    overflow: hidden;
    overflow-y: auto;
`;

const SGrid = styled.div`
    display: grid;
`;

const SExperienceItem = styled.div`
    padding: 8px 0;
    display: grid;
    grid-template-columns: 28px auto;
    grid-gap: 8px;

    cursor: pointer;
    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}50;
    }
`;

const LicenseExperiences: FC<{
    license: ILicense;
    application: IApplication;
    onSave: Action<void>;
}> = ({ license, application, onSave }) => {
    const store = useStore();
    const [experiences, setExperiences, experiencesRef] = useStateRef<
        Dictionary<TContentTypes>
    >({});

    function onSaveHandler(): void {
        if (experiencesRef.current)
            Object.values(experiencesRef.current).forEach((e) => {
                store.dispatch({
                    action: "set",
                    type: getExperienceTypeData(e).action,
                    object: e,
                });
            });
    }

    useEffect(() => {
        onSave.on(onSaveHandler);
        return () => onSave.off(onSaveHandler);
    }, []);

    useEffect(() => {
        setExperiences(
            getSortedExperiences(application, store).reduce(
                (obj, experience) => ({
                    ...obj,
                    [experience.id ?? ""]: experience,
                }),
                {}
            )
        );
    }, [store?.state]);

    function toggleExperience(experience: TContentTypes): void {
        const editedExperience = { ...experience };
        const licenseId = license.id ?? "";
        if (!editedExperience.licenseIds) editedExperience.licenseIds = {};
        if (licenseId in editedExperience.licenseIds)
            delete editedExperience.licenseIds?.[licenseId];
        else editedExperience.licenseIds[licenseId] = licenseId;

        setExperiences((p) => ({
            ...p,
            [experience.id ?? ""]: editedExperience,
        }));
    }

    function getExperiences() {
        return (Object.values(experiences) as TContentTypes[]).map((e) => (
            <SExperienceItem key={e.id} onClick={() => toggleExperience(e)}>
                <div style={{ display: "grid", placeItems: "center" }}>
                    <Toggle
                        onClick={() => {}}
                        active={(license.id ?? "") in (e.licenseIds ?? {})}
                        type="checkbox"
                    />
                </div>
                <Text H5 semiBold>
                    {e.name}
                </Text>
            </SExperienceItem>
        ));
    }

    return (
        <SContainer>
            <Text semiBold H5>
                Content
            </Text>

            <SExperiences>
                <SGrid>{getExperiences()}</SGrid>
            </SExperiences>
        </SContainer>
    );
};

export default LicenseExperiences;
