import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { SPageHolder } from "..";
import icons from "../../assets/icons";
import { Title } from "../../components/Common";
import Confirm from "../../components/modal/Confirm";
import { Button, InputField, Text } from "../../components/UI";
import { IUser } from "../../context/AuthContext";
import {
    useAuth,
    useModal,
    useNotification,
    useOrganization,
    useStore,
    useValidator,
} from "../../hooks";
import { NEW_IDENTIFIER } from "../../store";
import { ERole, IOrganizationRole, RoleData } from "../../store/scheme";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const SProfile = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    padding-bottom: 10rem;
`;
const SSaveButton = styled.div`
    width: 13.25rem;
    margin-left: auto;
`;

const Profile: FC<{ user: IUser; backHandler(): void }> = (props) => {
    const [user, setUser] = useState<IUser>({});
    const [organizationRole, setOrganizationRole] =
        useState<IOrganizationRole>();
    const [state, set] = useState<{
        password: string;
        confirmPassword: string;
        loadingNew: boolean;
        existingUser?: IUser;
    }>({
        password: "",
        confirmPassword: "",
        loadingNew: false,
    });
    const auth = useAuth();
    const store = useStore();
    const modal = useModal();
    const organization = useOrganization();
    const validator = useValidator();

    const isNew = user?.id?.toString()?.includes(NEW_IDENTIFIER); // Completely new user.
    const isExistingUser: boolean = state.existingUser != undefined; // Not in the organization.
    const canEditRole = organization.accessLevel >= 40;

    const roles = [
        {
            value: ERole.participant.toString(),
            label: RoleData[ERole.participant].label,
        },
        {
            value: ERole.educator.toString(),
            label: RoleData[ERole.educator].label,
        },
        { value: ERole.client.toString(), label: RoleData[ERole.client].label },
        {
            value: ERole.creator.toString(),
            label: RoleData[ERole.creator].label,
        },
        {
            value: ERole.administrator.toString(),
            label: RoleData[ERole.administrator].label,
        },
        {
            value: ERole.powerUser.toString(),
            label: RoleData[ERole.powerUser].label,
        },
    ];

    let selectedRole = ERole.participant.toString();
    if (organizationRole?.role) {
        for (const role of roles) {
            if (role.value == organizationRole.role.toString()) {
                selectedRole = role.value;
                break;
            }
        }
    }

    useEffect(() => {
        if (!organization?.organization?.id) return;

        if (state?.existingUser?.id || user?.id == NEW_IDENTIFIER) {
            // Create new role.
            const newRole: IOrganizationRole = {
                id: NEW_IDENTIFIER,
                refOrganization: parseInt(
                    organization?.organization?.id?.toString() ?? ""
                ),
                refUser: parseInt(state?.existingUser?.id?.toString() ?? ""),
                role: ERole.participant,
            };

            setOrganizationRole(newRole);
        } else if (user?.id) {
            // const role = Object.values(store.state.organizationRole ?? {}).find(
            //     (r) =>
            //         r.refOrganization == organization?.organization?.id &&
            //         r.refUser == user.id
            // );
            // if (role) setOrganizationRole(role);
        }
    }, [user?.id, state?.existingUser?.id]);

    useEffect(() => {
        if (props?.user?.id) setUser(props.user);
    }, [props.user]);

    async function register(): Promise<void> {
        // set((p) => ({ ...p, loadingNew: true }));
        // const newUser = await auth.register(user, state.password, false);
        // store.dispatch({
        //     type: "user",
        //     action: "set",
        //     object: newUser,
        //     onAdd: (addedUser) => {
        //         store.dispatch({
        //             type: "organizationRole",
        //             action: "set",
        //             object: {
        //                 ...organizationRole,
        //                 id: NEW_IDENTIFIER,
        //                 refUser: addedUser.id,
        //             },
        //             onAdd: (addedOrg) => {
        //                 set((p) => ({ ...p, loadingNew: false }));
        //                 props.backHandler();
        //             },
        //         });
        //     },
        // });
    }

    function onRoleChange({ value }: { value: string }): void {
        setOrganizationRole((p) => ({ ...p, role: parseInt(value ?? "0") }));
    }

    function addExistingUser(): void {
        // if (organization?.organization?.id) {
        //     set((p) => ({ ...p, loadingNew: true }));
        //     store.dispatch({
        //         action: "set",
        //         type: "organizationRole",
        //         object: organizationRole,
        //         onAdd: (added) => {
        //             set((p) => ({ ...p, loadingNew: false }));
        //             props.backHandler();
        //         },
        //     });
        // }
    }

    function saveHandler(): void {
        if (isExistingUser) {
            // Show confirmation panel.
            modal.setContent(
                <Confirm
                    title="Add user to organisation"
                    subTitle={`Add ${state.existingUser?.firstName} to organisation`}
                    icon={icons.add}
                    confirmHandler={() => addExistingUser()}
                />
            );
            modal.setActive(true);
            return;
        }

        // If its a new user, first validate data.
        if (
            isNew &&
            !validator([
                { value: user.email, message: "Email required" },
                { value: state.password, message: "Password required" },
                {
                    value: state.password,
                    message: "Password must be at least 6 characthers long",
                    custom: state.password.length < 6,
                },
                {
                    value: state.password,
                    message: "Passwords don't match",
                    custom: state.password != state.confirmPassword,
                },
                { value: user.firstName, message: "First name required" },
                { value: user.lastName, message: "Last name required" },
                { value: user.phoneNo, message: "Phone number required" },
            ])
        )
            return;

        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Save User"
                subTitle={`Save ${user?.firstName}'s profile changes`}
                icon={icons.checkmark}
                confirmHandler={() => {
                    if (isNew) register();
                    else {
                        store.dispatch({
                            type: "user",
                            action: "set",
                            object: user,
                        }); // Dispatch changes to store and flamelink.
                        // if (organizationRole?.id)
                        //     store.dispatch({
                        //         type: "organizationRole",
                        //         action: "set",
                        //         object: organizationRole,
                        //     });
                    }
                }}
            />
        );
        modal.setActive(true);
    }

    function editEmail(username: string): void {
        set((p) => ({ ...p, existingUser: undefined }));
        for (const user of Object.values(store.state.user)) {
            if (username === user.email) {
                set((p) => ({ ...p, existingUser: user }));
                break;
            }
        }

        setUser((p) => ({ ...p, email: username }));
    }

    return (
        <SPageHolder>
            <SProfile>
                <Title>Profile</Title>

                {isNew && isExistingUser && (
                    <Text primary H4 semiBold>
                        Registered user found
                    </Text>
                )}

                <InputField
                    title="Email"
                    value={user?.email ?? ""}
                    onChange={(username) => editEmail(username)}
                    placeholder="Enter email"
                    disabled={!isNew}
                />

                {isNew && !isExistingUser && (
                    <InputField
                        title="Password"
                        value={state.password}
                        onChange={(password) =>
                            set((p) => ({ ...p, password }))
                        }
                        placeholder="Password"
                        type="password"
                    />
                )}

                {isNew && !isExistingUser && (
                    <InputField
                        title="Confirm Password"
                        value={state.confirmPassword}
                        onChange={(confirmPassword) =>
                            set((p) => ({ ...p, confirmPassword }))
                        }
                        placeholder="Password"
                        type="password"
                    />
                )}

                {canEditRole && (
                    <Text oneline H4 semiBold>
                        Role
                    </Text>
                )}
                {canEditRole && (
                    <Dropdown
                        options={roles}
                        placeholder="Select a role..."
                        value={selectedRole}
                        onChange={(role) => onRoleChange(role)}
                    />
                )}

                {!isExistingUser && (
                    <Text oneline H4 semiBold>
                        Extra info
                    </Text>
                )}
                {!isExistingUser && (
                    <InputField
                        title="First name"
                        value={user?.firstName ?? ""}
                        onChange={(firstName) =>
                            setUser((p) => ({ ...p, firstName }))
                        }
                        placeholder="Enter user first name"
                    />
                )}
                {!isExistingUser && (
                    <InputField
                        title="Last name"
                        value={user?.lastName ?? ""}
                        onChange={(lastName) =>
                            setUser((p) => ({ ...p, lastName }))
                        }
                        placeholder="Enter user last name"
                    />
                )}
                {!isExistingUser && (
                    <InputField
                        title="Phone number"
                        value={user?.phoneNo ?? ""}
                        onChange={(phoneNo) =>
                            setUser((p) => ({ ...p, phoneNo }))
                        }
                        placeholder="Enter user phone number"
                    />
                )}

                <SSaveButton>
                    <Button
                        disabled={state.loadingNew}
                        onClick={() => saveHandler()}
                    >
                        {state.loadingNew
                            ? "Processing..."
                            : isExistingUser
                            ? "Save changes"
                            : "Add user"}
                    </Button>
                </SSaveButton>
            </SProfile>
        </SPageHolder>
    );
};

export default Profile;
