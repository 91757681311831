import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { SPageHolder } from "..";
import icons from "../../assets/icons";
import { Title } from "../../components/Common";
import Confirm from "../../components/modal/Confirm";
import { Button, InputField } from "../../components/UI";
import { useModal, useStore } from "../../hooks";
import { IOrganization } from "../../store/scheme";

const SSettings = styled.div`
    display: grid;
    grid-template-columns: auto 20.563rem;
    grid-gap: 3rem;
`;

const SLeft = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`;

const SRight = styled.div``;

const SSaveButton = styled.div`
    width: 13.25rem;
    margin-left: auto;
`;

const Settings: FC<{ organization: IOrganization }> = (props) => {
    const [organization, setOrganization] = useState<IOrganization>({});
    const store = useStore();
    const modal = useModal();

    useEffect(() => {
        setOrganization(props.organization);
    }, [props.organization]);

    function saveHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Save Organization"
                subTitle={`Save "${organization.name}" changes`}
                icon={icons.checkmark}
                confirmHandler={() => {
                    store.dispatch({
                        type: "organization",
                        action: "set",
                        object: organization,
                    }); // Dispatch changes to store and flamelink.
                }}
            />
        );
        modal.setActive(true);
    }

    return (
        <SPageHolder>
            <SSettings>
                <SLeft>
                    <Title>Settings</Title>
                    <InputField
                        title="Organisation Name"
                        value={organization.name ?? ""}
                        onChange={(name) =>
                            setOrganization((p) => ({ ...p, name }))
                        }
                        placeholder="Organisation Name"
                    />
                    <InputField
                        title="Address"
                        value={organization.address ?? ""}
                        onChange={(address) =>
                            setOrganization((p) => ({ ...p, address }))
                        }
                        placeholder="Enter address"
                    />

                    <SSaveButton>
                        <Button onClick={() => saveHandler()}>Save</Button>
                    </SSaveButton>
                </SLeft>

                <SRight></SRight>
            </SSettings>
        </SPageHolder>
    );
};

export default Settings;
