import React, { FC, useState, useEffect, useMemo } from "react";
import { arrayMove } from "react-sortable-hoc";
import styled from "styled-components";
import { SPageHolder } from "..";
import icons from "../../assets/icons";
import { PageButton, SScroll, Title } from "../../components/Common";
import ImageSelector from "../../components/ImageSelector";
import Confirm from "../../components/modal/Confirm";
import Sortable from "../../components/Sortable";
import {
    Image,
    InputField,
    Text,
    TextArea,
    Button,
    Toggle,
} from "../../components/UI";
import { Paths, TParam } from "../../context/RouterContext";
import { uploadFile } from "../../firebase/storage";
import {
    useModal,
    useNotification,
    useOrganization,
    useRouter,
    useStore,
} from "../../hooks";
import { IState, IStore, NEW_IDENTIFIER } from "../../store";
import {
    IApplication,
    TContentTypes,
    IContent,
    getExperienceTypeData,
    ICollection,
} from "../../store/scheme";
import { SLine } from "../experience/overview";
import {
    getRecoursiveSubContent,
    getSubExperiences,
} from "../../utility/collectionUtil";

const SApplication = styled.div`
    display: grid;
    grid-template-columns: 25rem auto;
    grid-gap: 1.5rem;
    padding-bottom: 10rem;
`;

const SSettings = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`;

const SExperiences = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding-bottom: 5rem;
`;

const SSaveButton = styled.div`
    width: 13.25rem;
    margin-left: auto;
`;

export function getSortedExperiences(
    application: IApplication,
    store: IStore,
    collection?: ICollection // Optional collection.
): TContentTypes[] {
    let list: TContentTypes[] = [];

    const experiencesObject = collection?.id
        ? collection?.experiences
        : application?.experiences;

    const add = (subPath: keyof IContent, storePath: keyof IState) => {
        if (experiencesObject?.[subPath]) {
            const e = Object.values(experiencesObject[subPath]).map(
                //@ts-ignore
                (id) => store?.state?.[storePath]?.[id]
            );
            list = list.concat(e);
        }
    };

    add("images", "experienceImage");
    add("videos", "experienceVideo");
    add("interactives", "experienceInteractive");
    add("virtualTours", "experienceVirtualTour");
    add("collections", "collection");

    const order =
        (collection?.id
            ? collection.experiencesOrder
            : application.experiencesOrder) ?? {};

    return list.sort((a, b) => {
        const aOrder = order?.[a.id ?? ""] ?? Infinity;
        const bOrder = order?.[b.id ?? ""] ?? Infinity;

        return aOrder > bOrder ? 1 : -1;
    });
}

const Overview: FC<{ application: IApplication; collection?: ICollection }> = (
    props
) => {
    const [application, setApplication] = useState<IApplication>(
        props.application
    );
    const [state, set] = useState({
        loadingNew: false,
    });
    const store = useStore();
    const modal = useModal();
    const router = useRouter();
    const notification = useNotification();
    const organization = useOrganization();

    const [experiences, setExperiences] = useState<TContentTypes[]>([]);
    const [collection, setCollection] = useState<ICollection>(
        props.collection ?? { type: "ICollection" }
    );

    const isNew = application.id?.toString().includes(NEW_IDENTIFIER);
    const isCollectionNew = collection?.id
        ?.toString()
        ?.includes(NEW_IDENTIFIER);

    useEffect(() => {
        setCollection(props.collection ?? { type: "ICollection" });
    }, [props.collection]);

    useEffect(() => {
        setApplication(props.application); // Update local experience if it changes.
    }, [props.application]);

    useEffect(() => {
        setExperiences(getSortedExperiences(application, store, collection));
    }, [application, collection]);

    function getExperiences() {
        // map to react activity tag component.
        return getSortedExperiences(application, store, collection)
            .filter((e) => e)
            .map((e) => <ExperienceTag key={e.id} experience={e} />);
    }

    function newExperienceHandler(param: TParam): void {
        router.setParam(param, `${NEW_IDENTIFIER}${param}`);
        router.setPath(Paths.ExperienceOverview, true);
    }

    function newCollectionHandler(): void {
        router.setParam("collection-parent", collection.id);
        router.setParam("collection", NEW_IDENTIFIER);
    }

    const options = [
        {
            icon: icons.image,
            text: "Image",
            action: () => newExperienceHandler("experience-image"),
        },
        {
            icon: icons.video,
            text: "Video",
            action: () => newExperienceHandler("experience-video"),
        },
        {
            icon: icons.interactive360,
            text: "Interactive",
            action: () => newExperienceHandler("experience-interactive"),
        },
        {
            icon: icons.vrArDevice,
            text: "Virtual Tour",
            action: () => newExperienceHandler("experience-virtual-tour"),
        },
        {
            icon: icons.collection,
            text: "Collection",
            action: () => newCollectionHandler(),
        },
    ];

    async function saveApplication(): Promise<void> {
        // Set loading new if is new.
        if (isNew) set((p) => ({ ...p, loadingNew: true }));

        notification.notify("Saving changes", "loading", false);

        // Check thumbnail.
        if (application.localThumbnail) {
            const { url } = await uploadFile({
                file: application.localThumbnail,
                path: "images",
            });
            application.thumbnail = url;
            delete application.localThumbnail;

            setApplication((p) => ({
                ...p,
                thumbnail: url,
                localThumbnail: undefined,
            }));
        }

        // Do save changes on confirm.
        store.dispatch({
            action: "set",
            type: "application",
            object: application,
            onAdd: (newApplication) => {
                // Add application to organization.
                if (organization.organization) {
                    const org = { ...organization.organization };
                    if (!org.applications) org.applications = {};
                    org.applications[newApplication.id] = newApplication.id;

                    // Save organization.
                    store.dispatch({
                        action: "set",
                        type: "organization",
                        object: org,
                    });
                }

                set((p) => ({ ...p, loadingNew: false }));

                // Update url param.
                router.setParam("application", newApplication.id);
            },
        });
    }

    async function saveCollection(): Promise<void> {
        // Set loading new if is new.
        if (isCollectionNew) set((p) => ({ ...p, loadingNew: true }));

        notification.notify("Saving changes", "loading", false);

        // Check thumbnail.
        if (collection.localThumbnail) {
            const { url } = await uploadFile({
                file: collection.localThumbnail,
                path: "images",
            });
            collection.thumbnail = url;
            delete collection.localThumbnail;

            setCollection((p) => ({
                ...p,
                thumbnail: url,
                localThumbnail: undefined,
            }));
        }

        // Do save changes on confirm.
        store.dispatch({
            action: "set",
            type: "collection",
            object: collection,
            onAdd: (newCollection: ICollection) => {
                // Add collection to parent.
                if (newCollection.parent) {
                    const parentCollection: ICollection = {
                        ...store.state.collection[newCollection.parent],
                    };

                    if (!parentCollection.experiences)
                        parentCollection.experiences = {
                            collections: {},
                            images: {},
                            interactives: {},
                            videos: {},
                            virtualTours: {},
                        };
                    if (!parentCollection.experiences.collections)
                        parentCollection.experiences.collections = {};

                    parentCollection.experiences.collections[
                        newCollection.id ?? ""
                    ] = newCollection.id ?? "";

                    store.dispatch({
                        action: "set",
                        type: "collection",
                        object: parentCollection,
                    });
                } else {
                    const app = { ...application };
                    if (!app.experiences)
                        app.experiences = {
                            collections: {},
                            images: {},
                            interactives: {},
                            videos: {},
                            virtualTours: {},
                        };
                    if (!app.experiences.collections)
                        app.experiences.collections = {};

                    app.experiences.collections[newCollection.id ?? ""] =
                        newCollection.id ?? "";

                    store.dispatch({
                        type: "application",
                        action: "set",
                        object: app,
                    });
                }

                set((p) => ({ ...p, loadingNew: false }));

                // Update url param.
                router.setParam("collection", newCollection.id);
            },
        });
    }

    function saveHandler(): void {
        const content = collection.id ? (
            <Confirm
                title={`${isCollectionNew ? "Create" : "Save"} Collection`}
                subTitle={`Save "${
                    collection.name ?? "New Collection"
                }" changes`}
                icon={icons.checkmark}
                confirmHandler={() => saveCollection()}
            />
        ) : (
            <Confirm
                title={`${isNew ? "Create" : "Save"} Experience`}
                subTitle={`Save "${application.name}" changes`}
                icon={icons.checkmark}
                confirmHandler={() => saveApplication()}
            />
        );

        // Show confirmation panel.
        modal.setContent(content);
        modal.setActive(true);
    }

    function deleteHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Delete Application"
                subTitle={`Delete "${application.name}", this action cannot be undone.`}
                icon={icons.delete}
                confirmHandler={() => {
                    // Remove application from organization.
                    if (organization?.organization?.applications) {
                        const org = { ...organization.organization };

                        if (org?.applications && application?.id)
                            delete org?.applications[application?.id];

                        store.dispatch({
                            action: "set",
                            type: "organization",
                            object: org,
                        });
                    }

                    // Do delete on confirm.
                    store.dispatch({
                        action: "delete",
                        type: "application",
                        object: application,
                    });
                    router.setPath(Paths.Applications);
                }}
            />
        );
        modal.setActive(true);
    }

    function deleteCollectionHandler(): void {
        // Show confirmation panel.
        modal.setContent(
            <Confirm
                title="Delete Collection"
                subTitle={`Delete "${collection.name}", this action cannot be undone.`}
                icon={icons.delete}
                confirmHandler={() => {
                    // Remove collection from parent.
                    if (collection.parent) {
                        const parent = {
                            ...store.state.collection[collection.parent],
                        };
                        if (collection.id)
                            delete parent.experiences?.collections[
                                collection.id
                            ];
                        store.dispatch({
                            action: "set",
                            type: "collection",
                            object: parent,
                        });
                    } else {
                        const parentApp = { ...application };
                        if (collection.id)
                            delete parentApp.experiences?.collections[
                                collection.id
                            ];
                        store.dispatch({
                            action: "set",
                            type: "application",
                            object: parentApp,
                        });
                    }

                    // Do delete on confirm.
                    store.dispatch({
                        action: "delete",
                        type: "collection",
                        object: collection,
                    });

                    router.setParam("collection", collection.parent);

                    // Delete every children.
                    const children = getRecoursiveSubContent(collection, store);
                    Object.values(children ?? {}).forEach((child) =>
                        store.dispatch({
                            object: child,
                            type: getExperienceTypeData(child).action,
                            action: "delete",
                        })
                    );
                }}
            />
        );
        modal.setActive(true);
    }

    function sortHandler(from: number, to: number): void {
        const movedList = arrayMove(experiences, from, to);

        if (collection?.id)
            setCollection((p) => {
                p.experiencesOrder = {};
                movedList.forEach((e, i) => {
                    if (p.experiencesOrder) p.experiencesOrder[e.id ?? ""] = i;
                });

                return { ...p };
            });
        else
            setApplication((p) => {
                p.experiencesOrder = {};
                movedList.forEach((e, i) => {
                    if (p.experiencesOrder) p.experiencesOrder[e.id ?? ""] = i;
                });

                return { ...p };
            });
    }

    const applicationSettings = (
        <SSettings>
            <Title>Settings</Title>
            <ImageSelector
                key="application"
                height="25rem"
                image={application.thumbnail}
                fileHandler={(localThumbnail) =>
                    setApplication((p) => ({ ...p, localThumbnail }))
                }
                deleteHandler={() =>
                    setApplication((p) => {
                        const e = { ...p };
                        e.thumbnail = "";
                        delete e.localThumbnail;
                        return e;
                    })
                }
            />
            <InputField
                title="Name"
                onChange={(name) => setApplication((p) => ({ ...p, name }))}
                value={application.name ?? ""}
                placeholder="Enter experience name"
            />
            <TextArea
                title="Description"
                onChange={(description) =>
                    setApplication((p) => ({ ...p, description }))
                }
                value={application.description ?? ""}
                placeholder="Enter experience description"
            />
            <InputField
                title="PIN Code"
                onChange={(pinString) =>
                    setApplication((p) => ({
                        ...p,
                        pin: parseInt(pinString ?? ""),
                    }))
                }
                value={(application.pin ?? "").toString()}
                placeholder="Enter PIN code"
            />

            <SLine />

            <Text H4 bold>
                Configuration
            </Text>
            <Toggle
                title="Disable Video Deletion"
                active={application?.configuration?.disableVideoDeletion}
                onClick={() =>
                    setApplication((p) => ({
                        ...p,
                        configuration: {
                            ...(p.configuration ?? {}),
                            disableVideoDeletion:
                                !p?.configuration?.disableVideoDeletion,
                        },
                    }))
                }
                type="checkbox"
            />

            <SLine />

            {!isNew && (
                <PageButton
                    onClick={() => {
                        notification.notify(
                            "ID added to clipboard",
                            "ok",
                            true
                        );
                        navigator.clipboard.writeText(application?.id ?? "");
                    }}
                    icon={icons.share}
                >
                    ID: {application.id}
                </PageButton>
            )}
            {!isNew && (
                <PageButton onClick={() => deleteHandler()} icon={icons.delete}>
                    Delete application
                </PageButton>
            )}
        </SSettings>
    );

    const collectionSettings = (
        <SSettings>
            <Title>Settings</Title>
            <ImageSelector
                key="collection"
                height="25rem"
                image={collection?.thumbnail}
                fileHandler={(localThumbnail) => {
                    setCollection((p) => ({ ...p, localThumbnail }));
                }}
                deleteHandler={() =>
                    setCollection((p) => {
                        const e = { ...p };
                        e.thumbnail = "";
                        delete e.localThumbnail;
                        return e;
                    })
                }
            />
            <InputField
                title="Name"
                onChange={(name) => setCollection((p) => ({ ...p, name }))}
                value={collection?.name ?? ""}
                placeholder="Enter collection name"
            />

            <Text H4 bold>
                Availability
            </Text>
            <Toggle
                title="Set as Coming Soon"
                active={collection?.isComingSoon}
                onClick={() =>
                    setCollection((p) => ({
                        ...p,
                        isComingSoon: !p.isComingSoon,
                    }))
                }
                type="checkbox"
            />
            <Toggle
                title="Public Access"
                active={!collection.isPremium}
                onClick={() =>
                    setCollection((p) => ({
                        ...p,
                        isPremium: false,
                    }))
                }
                type="checkbox"
            />
            <Toggle
                title="Premium (License Code Required)"
                active={collection.isPremium}
                onClick={() =>
                    setCollection((p) => ({
                        ...p,
                        isPremium: true,
                    }))
                }
                type="checkbox"
            />
            <Toggle
                title="Private"
                active={collection.isPrivate}
                onClick={() =>
                    setCollection((p) => ({
                        ...p,
                        isPrivate: !p.isPrivate,
                    }))
                }
                type="checkbox"
            />
            {!isCollectionNew && (
                <PageButton
                    onClick={() => deleteCollectionHandler()}
                    icon={icons.delete}
                >
                    Delete collection
                </PageButton>
            )}
        </SSettings>
    );

    return (
        <SPageHolder>
            <SApplication>
                {collection.id ? collectionSettings : applicationSettings}
                <SExperiences>
                    <Title
                        buttonText={
                            !isNew && !isCollectionNew ? "New" : undefined
                        }
                        options={options}
                    >
                        {collection.id
                            ? collection?.name ?? "New Collection"
                            : "Experiences"}
                    </Title>
                    <SScroll style={{ height: "70vh" }} padding="0">
                        <Sortable
                            axis="y"
                            items={getExperiences()}
                            sortHandler={sortHandler}
                        />
                    </SScroll>
                    <SSaveButton>
                        <Button
                            disabled={state.loadingNew}
                            onClick={() => saveHandler()}
                        >
                            {isNew || isCollectionNew
                                ? state.loadingNew
                                    ? "Processing..."
                                    : "Create"
                                : "Save"}
                        </Button>
                    </SSaveButton>
                </SExperiences>
            </SApplication>
        </SPageHolder>
    );
};

const SExperienceTag = styled.div`
    height: 15rem;
    cursor: pointer;
    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;
    }
    border-radius: 10px;
    padding: 1rem 1.5rem;
    width: 100%;
`;

const SExperiencePanel = styled.div`
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 10px;
    height: 100%;
    display: grid;
    grid-template-columns: 15rem auto;
`;

const SExperienceText = styled.div`
    padding: 0 1.5rem;
    display: grid;
    height: min-content;
    margin: auto 0;
    * {
        height: min-content;
        width: min-content;
    }
`;

const ExperienceTag: FC<{ experience: TContentTypes }> = ({ experience }) => {
    const router = useRouter();
    const store = useStore();
    const isCollection = useMemo(
        () => experience.type === "ICollection",
        [experience]
    );

    // Select activity and go to activity page.
    function clickHandler(): void {
        if (isCollection) {
            router.setParam("collection", experience.id);
            return;
        }

        router.setParam(getExperienceTypeData(experience).param, experience.id);
        router.setPath(Paths.ExperienceOverview, true);
    }

    function getCollectionInfo() {
        if (!isCollection) return;
        const list = [<div key="gap" style={{ height: 10 }} />];
        const collections = Object.keys(
            (experience as ICollection).experiences?.collections ?? {}
        ).length;

        if (collections > 0) {
            list.push(
                <Text H5 medium semiBold oneline key="collections">
                    - {collections + ` Collection${collections > 1 ? "s" : ""}`}
                </Text>
            );
        }

        const experiences = Object.keys(
            getSubExperiences(experience as ICollection, store)
        ).length;

        if (experiences > 0) {
            list.push(
                <Text H5 medium semiBold oneline key="experiences">
                    - {experiences + ` experience${experiences > 1 ? "s" : ""}`}
                </Text>
            );
        }

        return list;
    }

    return (
        <SExperienceTag onClick={() => clickHandler()}>
            <SExperiencePanel>
                <Image round image={experience.thumbnail} />
                <SExperienceText>
                    <Text H4 primary semiBold oneline>
                        {experience.name ?? "Activity Name"}
                    </Text>
                    <Text H5 medium semiBold oneline>
                        {experience.isPrivate
                            ? "Private"
                            : experience.isPremium
                            ? "Premium"
                            : "Public"}{" "}
                        - {getExperienceTypeData(experience).label}
                    </Text>
                    {getCollectionInfo()}
                </SExperienceText>
            </SExperiencePanel>
        </SExperienceTag>
    );
};

export default Overview;
