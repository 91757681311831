import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { SVG, Text, Button, InputField } from '../UI'
import { useModal } from '../../hooks'

const SInputModal = styled.div`
	display: grid;
	grid-gap: 0.688rem;
`

const SRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.313rem;
`

const SIcon = styled.div`
	height: 5.688rem;
`

interface IProps {
	icon?: string,
	title?: string,
	subTitle?: string,
	confirmHandler(value: string): void
}

interface IState {
	input: string,
}

const InputModal: FC<IProps> = (props) => {
	const [state, set] = useState<IState>({
		input: ''
	})
	const modal = useModal()

	function confirmHandler(): void {
		props.confirmHandler(state.input)
		modal.setActive(false)
	}

	return (
		<SInputModal>
			{props.icon && <SIcon><SVG image={props.icon} primary contain /></SIcon>}
			{props.title && <Text H3 semiBold dark center>{props.title}</Text>}
			{props.subTitle && <Text H5 semiBold medium center>{props.subTitle}</Text>}
			<div /> <div />
			<InputField
				value={state.input}
				onChange={input => set(p => ({ ...p, input }))}
			/>
			<SRow>
				<Button secondary onClick={() => modal.setActive(false)}>Cancel</Button>
				<Button onClick={() => confirmHandler()}>OK</Button>
			</SRow>
		</SInputModal>
	)
}

export default InputModal
