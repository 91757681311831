import { FC } from "react";
import styled, { css } from "styled-components";
import icons from "../assets/icons";
import { useOrganization, useRouter, useStore } from "../hooks";
import { Paths } from "../context/RouterContext";

import { SVG, Text } from "./UI";

const SSidemenu = styled.div`
    width: 14.75rem;
    grid-column: 1/2;
    grid-row: 2/3;
    background-color: ${(p) => p.theme.colors.neutral["02"]};
    border-right: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};

    display: grid;
    grid-template-rows: min-content min-content auto min-content;

    min-height: 0;
`;

const SScroll = styled.div`
    overflow: auto;
    overflow-x: hidden;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
`;

const SList = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    padding: 1.5rem;
    overflow: hidden;
    * {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const SNav = styled.div`
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
`;

const navegation = {
    organization: {
        name: "Organisation",
        paths: [
            Paths.OrganizationAnalytics,
            Paths.OrganizationSettings,
            Paths.OrganizationTheme,
        ],
        icon: icons.dashboard,
    },
    // user: {
    //     name: "User Managment",
    //     paths: [Paths.UserManagment],
    //     icon: icons.users,
    // },
    asset: {
        name: "Assets",
        paths: [Paths.AssetsVideos, Paths.AssetsImages],
        icon: icons.file,
    },
    applications: {
        name: "Applications",
        paths: [Paths.Applications],
        icon: icons.experience,
    },
};

const Sidemenu = () => {
    const router = useRouter();
    const organization = useOrganization();

    const selectedApplicationId = router.getParam("application");

    function isSelected(key: keyof typeof navegation): boolean {
        return navegation[key].paths.includes(router.state.path);
    }

    function clickHandler(key: keyof typeof navegation): void {
        router.setPath(navegation[key].paths[0]);
    }

    function getApplications() {
        return Object.values(organization.applications ?? {}).map((e) => (
            <Text
                center
                key={e.id}
                primary={selectedApplicationId === e?.id?.toString()}
                onClick={() => {
                    router.setPath(Paths.ApplicationOverview); // Go to overview page.
                    router.setParam("application", e.id); // and select application.
                }}
                oneline
                H5
            >
                {e.name ?? "Unnamed"}
            </Text>
        ));
    }

    return (
        <SSidemenu>
            <SNav>
                {(Object.keys(navegation) as (keyof typeof navegation)[])
                    .slice(0, -1)
                    .map((key) => (
                        <NavButton
                            key={key}
                            icon={navegation[key].icon}
                            isSelected={isSelected(key)}
                            onClick={() => clickHandler(key)}
                        >
                            {navegation[key].name}
                        </NavButton>
                    ))}
            </SNav>

            <NavButton
                icon={icons.experience}
                isSelected={isSelected("applications")}
                onClick={() => clickHandler("applications")}
            >
                Applications
            </NavButton>

            <SScroll>
                <SList>{getApplications()}</SList>
            </SScroll>

            <NavButton
                icon={icons.help}
                isSelected={false}
                onClick={() => window.open("https://think.digital/")}
            >
                Help
            </NavButton>
        </SSidemenu>
    );
};

const SNavButton = styled.div<{ isSelected?: boolean }>`
    display: grid;
    padding: 0.875rem 1.25rem;
    grid-template-columns: 1.5rem auto;
    grid-gap: 0.75rem;
    height: 3rem;
    cursor: pointer;
    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;
    }
    ${(p) =>
        p.isSelected &&
        css`
            background-color: ${(p) => p.theme.colors.main.primary}10;
        `}
`;

const NavButton: FC<{ icon: string; isSelected: boolean; onClick(): void }> = (
    props
) => {
    return (
        <SNavButton {...props}>
            <SVG image={props.icon} />
            <Text middle H5 semiBold>
                {props.children}
            </Text>
        </SNavButton>
    );
};

export default Sidemenu;
