import { FC, useCallback, useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { Button, Text, SVG } from "./UI";
import { useDropzone } from "react-dropzone";
import icons from "../assets/icons";

const SFileSelector = styled.div<{ height?: string }>`
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["10"]};
    padding: 0.813rem;
    height: ${(p) => p.height ?? "min-content"};
    border-radius: 0.313rem;

    min-width: 0;
    overflow: hidden;
    width: inherit;
`;

export const STextRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.2rem;
    justify-content: center;
`;

const SDropFiles = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
`;

const SEditFile = styled.div<{ height?: string }>`
    display: grid;
    grid-template-columns: calc(${(p) => p.height ?? "3.75rem"} - 1.625rem) auto auto min-content min-content;
    height: 100%;
    align-items: center;
    grid-gap: 0.625rem;
    overflow: hidden;
    width: inherit;
    min-width: 0;
`;

const SFileSelectorContainer = styled.div`
    min-width: 0;
    overflow: hidden;
    display: grid;
    grid-gap: 0.5rem;
`;

interface IProps {
    title?: string;
    fileName?: string;
    fileHandler?(file: any): void;
    deleteHandler?(): void;
    height?: string;
}

const FileSelector: FC<IProps> = (props) => {
    const [fileName, setFileName] = useState(props.fileName);
    const inputRef = useRef();
    const onDrop = useCallback((acceptedFiles) => {
        handleChange(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        setFileName(props.fileName);
    }, [props.fileName]);

    function handleChange(file: any) {
        if (file) {
            const reader = new FileReader();
            //@ts-ignore
            reader.onloadend = function (e) {
                setFileName(file.name);
            };
            reader.readAsDataURL(file);

            if (props.fileHandler) props.fileHandler(file);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    function getContent() {
        if (fileName)
            return (
                <SEditFile>
                    <SVG image={icons.file} contain primary />
                    <Text
                        oneline
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                        middle
                        H6
                        bold
                    >
                        {fileName}
                    </Text>
                    <div />
                    <Button
                        icon={icons.edit}
                        onClick={() => {
                            //@ts-ignore
                            inputRef?.current?.click();
                        }}
                    />
                    <input
                        type="file"
                        //@ts-ignore
                        ref={inputRef}
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e?.target?.files?.[0])}
                    />
                    <Button
                        icon={icons.delete}
                        onClick={() =>
                            props.deleteHandler && props.deleteHandler()
                        }
                    />
                </SEditFile>
            );

        return (
            <SDropFiles {...getRootProps()}>
                <div>
                    <input {...getInputProps()} />
                    <Text H6 bold center>
                        Drag and drop
                    </Text>
                    <STextRow>
                        <Text H6 bold medium>
                            or
                        </Text>
                        <Text H6 bold primary onClick={() => {}}>
                            browse
                        </Text>
                        <Text H6 bold medium>
                            to choose file
                        </Text>
                    </STextRow>
                </div>
            </SDropFiles>
        );
    }

    return (
        <SFileSelectorContainer>
            {props.title && (
                <Text H5 bold oneline>
                    {props.title}
                </Text>
            )}
            <SFileSelector {...props}>{getContent()}</SFileSelector>
        </SFileSelectorContainer>
    );
};

export default FileSelector;
