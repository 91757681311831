import firebase from "./";
import { organizationDatabase } from "./";

const database = firebase.database();

export const ROOT = "tdxr-v1/";

const getDatabase = (organization: boolean) =>
    organization ? organizationDatabase : database;

// Read once content from database at path.
export const getContent = (path: string, organization: boolean = true) =>
    new Promise(async (resolve, reject) => {
        try {
            const snapshot = await getDatabase(organization)
                .ref(ROOT + path)
                .once("value");
            resolve(snapshot.val());
        } catch (error) {
            reject(error);
        }
    });

// Subscribe to database changes at path.
export const subscribeToContent = (
    path: string,
    callback: (value: any) => void,
    organization: boolean = true
) => {
    return getDatabase(organization)
        .ref(ROOT + path)
        .on("value", (snapshot) => {
            if (snapshot) callback(snapshot.val());
        });
};

// Get a filtered dictionary from database.
export const getFiltered = (
    path: string,
    filter: string,
    value: any,
    organization: boolean = true
) =>
    new Promise(async (resolve, reject) => {
        try {
            const snapshot = await getDatabase(organization)
                .ref(ROOT + path)
                .orderByChild(filter)
                .equalTo(value)
                .once("value");
            resolve(snapshot.val());
        } catch (error) {
            reject(error);
        }
    });

// Write to database.
export const setContent = (
    path: string,
    payload: any,
    organization: boolean = true
) =>
    new Promise(async (resolve, reject) => {
        try {
            if (ROOT + path) {
                const snapshot = await getDatabase(organization)
                    .ref(ROOT + path)
                    .set(payload);
                resolve(snapshot);
            } else reject("null data");
        } catch (error) {
            reject(error);
        }
    });

// Set value to database at path when the user disconnects.
export const onDisconnect = (
    path: string,
    value: any,
    organization: boolean = true
) => {
    if (ROOT + path && value != null)
        getDatabase(organization)
            .ref(ROOT + path)
            .onDisconnect()
            .set(value);
};
