import { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Layout.
import Topbar from "./components/Topbar";
import Sidemenu from "./components/Sidemenu";

// Pages.
import Organization from "./pages/Organization";
import UserManagment from "./pages/UserManagment";
import Applications from "./pages/Applications";
import Experience from "./pages/experience";
import Application from "./pages/application";
import User from "./pages/user";

// Overlays.
import Login from "./components/Login";
import OrganizationSelector from "./components/OrganizationSelector";
import Modal from "./components/modal";
import Notifications from "./components/notifications";
import Loading from "./components/Loading";
import RoleCheck from "./components/RoleCheck";

// Contexts.
import { Paths, RouterContext } from "./context/RouterContext";
import { AuthContext } from "./context/AuthContext";
import { ProfileContext } from "./context/ProfileContext";
import { ModalContext } from "./context/ModalContext";
import {
    INotification,
    NotificationContext,
} from "./context/NotificationContext";
import { OrganizationContext } from "./context/OrganizationContext";
import { StoreContext } from "./store";
import { useAuth, useRouter } from "./hooks";
import Profile from "./components/Profile";
import Assets from "./pages/Assets";
import License from "./pages/application/License";

declare global {
    interface Window {
        Treeview: {
            notification?: INotification;
        };
    }
}

window.Treeview = {};

const SLayout = styled.div<{ blur?: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: 3.5rem auto;

    transition: 0.3s;
    ${(p) =>
        p.blur &&
        css`
            filter: blur(0.2rem);
        `}
    overflow: hidden;
`;

const OrganizationRoute: FC<{ path: Paths[]; exact?: boolean }> = ({
    path,
    exact,
    children,
}) => {
    const route = useRouter();

    const organizationPahts = path.map(
        (p) => (route.state.organization ?? "") + p
    );

    return (
        <Route path={organizationPahts} exact={exact}>
            {children}
        </Route>
    );
};

const Layout: FC = (props) => {
    const auth = useAuth();

    function shouldBlur(): boolean {
        if (auth.state.isPanelActive) return true;
        return false;
    }

    return (
        <SLayout blur={shouldBlur()}>
            <Topbar />
            <Sidemenu />

            <SContent>
                <OrganizationRoute
                    path={[
                        Paths.OrganizationAnalytics,
                        Paths.OrganizationSettings,
                        Paths.OrganizationTheme,
                    ]}
                >
                    <Organization />
                </OrganizationRoute>
                <OrganizationRoute path={[Paths.UserManagment]}>
                    <UserManagment />
                </OrganizationRoute>
                <OrganizationRoute
                    path={[Paths.AssetsVideos, Paths.AssetsImages]}
                >
                    <Assets />
                </OrganizationRoute>
                <OrganizationRoute path={[Paths.Applications]} exact>
                    <Applications />
                </OrganizationRoute>
                <OrganizationRoute
                    path={[
                        Paths.ApplicationOverview,
                        Paths.ApplicationTheme,
                        Paths.ApplicationAnalytics,
                        Paths.ApplicationLicenses,
                    ]}
                >
                    <Application />
                </OrganizationRoute>
                <OrganizationRoute path={[Paths.ApplicationLicense]}>
                    <License />
                </OrganizationRoute>
                <OrganizationRoute
                    path={[Paths.ExperienceOverview, Paths.ExperienceAnalitycs]}
                >
                    <Experience />
                </OrganizationRoute>
                <OrganizationRoute path={[Paths.UserProfile]}>
                    <User />
                </OrganizationRoute>
            </SContent>
        </SLayout>
    );
};

const SContent = styled.div`
    background-color: ${(p) => p.theme.colors.neutral["02"]};
    width: 100%;
    min-height: 0;

    height: inherit;
    overflow: hidden;
`;

const App: FC = () => {
    return (
        <Context>
            <Router>
                <Switch>
                    <RouterContext>
                        <Layout />

                        {/* Overlays */}
                        {/* <RoleCheck /> */}
                        <OrganizationSelector />
                        <Login />
                        <Modal />
                        <Notifications />
                        <Profile />
                        <Loading />
                    </RouterContext>
                </Switch>
            </Router>
        </Context>
    );
};

const Context: FC = ({ children }) => {
    return (
        <ModalContext>
            <NotificationContext>
                <StoreContext>
                    <AuthContext>
                        <OrganizationContext>
                            <ProfileContext>{children}</ProfileContext>
                        </OrganizationContext>
                    </AuthContext>
                </StoreContext>
            </NotificationContext>
        </ModalContext>
    );
};

export default App;
