import { key } from "firebase-key";
import { NEW_IDENTIFIER } from ".";
import { TParam } from "../context/RouterContext";
import { TAction } from "./actions";

export interface Dictionary<T> {
    [key: string]: T;
}

export interface IBase {
    id?: string;
    name?: string;
    order?: number;
}

export interface IAvailability {
    isComingSoon?: boolean;
    isPremium?: boolean;
    isPrivate?: boolean;

    licenseIds?: Dictionary<string>;
}

//#region Experience
export interface IExperience extends IBase, IAvailability {
    description?: string;
    thumbnail?: string;
    tags?: string[];

    location?: {
        latitude?: string;
        longitude?: string;
    };

    analyticsUrl?: string;
    learnMoreUrl?: string;

    // Local.
    localThumbnail?: File;
}

export type TContentTypes =
    | IExperienceImage
    | IExperienceVideo
    | IExperienceInteractive
    | IExperienceVirtualTour
    | ICollection;

interface IExperienceTypeData {
    action: TAction;
    param: TParam;
    subPath: keyof IContent;
    label: string;
}

const EXPERIENCE_TYPE_DATA: Dictionary<IExperienceTypeData> = {
    IExperienceImage: {
        action: "experience-image",
        param: "experience-image",
        subPath: "images",
        label: "Image",
    },
    IExperienceVideo: {
        action: "experience-video",
        param: "experience-video",
        subPath: "videos",
        label: "Video",
    },
    IExperienceInteractive: {
        action: "experience-interactive",
        param: "experience-interactive",
        subPath: "interactives",
        label: "Interactive",
    },
    IExperienceVirtualTour: {
        action: "experience-virtual-tour",
        param: "experience-virtual-tour",
        subPath: "virtualTours",
        label: "Virtual Tour",
    },
    ICollection: {
        action: "collection",
        param: "collection",
        subPath: "collections",
        label: "Collection",
    },
};

export const getExperienceTypeData = (
    experience: TContentTypes
): IExperienceTypeData => EXPERIENCE_TYPE_DATA[experience.type];

export interface IExperienceAudioVisual extends IExperience {
    is360?: boolean;
    isStereo?: boolean;
}

export interface IExperienceImage extends IExperienceAudioVisual {
    imageId?: string;
    // Typscript.
    type: "IExperienceImage";
}

export interface IExperienceVideo extends IExperienceAudioVisual {
    videoId?: string;

    // Local.
    localVideoURLDash?: string;
    localVideoFileSd?: string;
    localVideoFileHD?: string;

    // Typscript.
    type: "IExperienceVideo";
}

interface IExperienceUnity extends IExperience {
    unitySceneName?: string;
    version?: number;
}

export interface IExperienceInteractive extends IExperienceUnity {
    // Typscript.
    type: "IExperienceInteractive";
}

export interface IExperienceVirtualTour extends IExperienceUnity {
    // Typscript.
    type: "IExperienceVirtualTour";
}

//#endregion

export interface IContent {
    images: Dictionary<string>;
    videos: Dictionary<string>;
    interactives: Dictionary<string>;
    virtualTours: Dictionary<string>;
    collections: Dictionary<string>;
}

export interface IApplication extends IBase {
    experiences?: IContent;
    experiencesOrder?: Dictionary<number>;
    skin?: IApplicationSkin;
    thumbnail?: string;
    description?: string;
    disabled?: boolean;
    pin?: number;
    licenses?: Dictionary<ILicense>;

    configuration?: {
        disableVideoDeletion?: boolean;
    };

    // Local.
    localThumbnail?: File;
}

export interface IOrganization extends IBase {
    address?: string;
    applications?: Dictionary<string>;
    skin?: IOrganizationSkin;
    firebaseConfig?: {
        database?: string;
        storage?: string;
    };

    public?: boolean;
}

export interface ISkin extends IBase {
    logos?: {
        primary?: string;
        secondary?: string;
        about?: string;
        "background-image"?: string;
        splash?: string;

        // Local.
        localPrimary?: File;
        localSecondary?: File;
        localAbout?: File;
        localBackgroundImage?: File;
        localSplash?: File;
    };

    colors?: {
        main?: {
            primary?: string;
            secondary?: string;
            tertiary?: string;

            light?: string;
            dark?: string;
        };
        texts?: {
            primary?: string;
            secondary?: string;
            tertiary?: string;
        };
        icons?: {
            primary?: string;
            secondary?: string;
            tertiary?: string;
        };
    };
}

export interface IOrganizationSkin extends ISkin {}

export interface IApplicationSkin extends ISkin {
    aboutUs?: {
        description?: string;
        websiteUrl?: string;
    };
}

export enum ERole {
    participant = 0,
    client = 1,
    creator = 2,
    administrator = 3,
    educator = 4,
    powerUser = 5,
    companyDeviceUser = 6,
    demostrationUser = 7,
}

export const RoleData = {
    [ERole.participant]: { accessLevel: 0, label: "Participant" },
    [ERole.educator]: { accessLevel: 10, label: "Educator" },
    [ERole.client]: { accessLevel: 20, label: "Client" },
    [ERole.creator]: { accessLevel: 30, label: "Creator" },
    [ERole.administrator]: { accessLevel: 40, label: "Administrator" },
    [ERole.powerUser]: { accessLevel: 50, label: "Power User" },

    [ERole.companyDeviceUser]: {
        accessLevel: -2,
        label: "Company Device User",
    }, // Not used
    [ERole.demostrationUser]: { accessLevel: -2, label: "Demostration User" }, // Not used
};

export interface IOrganizationRole extends IBase {
    refUser?: number;
    refOrganization?: number;
    role?: ERole;
}

export interface IAsset extends IBase {
    url: string;
    hls?: string;
    date: string;
    size: string;
}

//#region License

export interface ILicense extends IBase {
    code?: string;
    description?: string;
    seatsAvailable?: number;
    seats?: Dictionary<ISeat>;
    expirationDate?: Date;
}

export interface ISeat extends IBase {
    deviceId: string;
    seatNumber: number;
    deviceType?: string;
    expirationDate: number;
    licenseId: string;
    error: number;

    type: ESeatType;
}

export enum ESeatType {
    valid,
    expired,
    removedByUser,
    removedByCMS,
}

//#endregion

export interface ICollection extends IExperience {
    experiences?: IContent;
    parent?: string;
    type: "ICollection";
    experiencesOrder?: Dictionary<number>;
}
