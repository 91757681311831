import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Image, Button, Text } from "./UI";
import { useDropzone } from "react-dropzone";
import icons from "../assets/icons";

const SImageSelectorContainer = styled.div`
    display: grid;
    grid-gap: 0.125rem;
`;

const SImageSelector = styled.div<{ height?: string }>`
    border: 0.063rem solid ${(p) => p.theme.colors.neutral["10"]};
    padding: 0.813rem;
    height: ${(p) => p.height ?? "7.688rem"};
    border-radius: 0.313rem;
    position: relative;
`;

const SText = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.2rem;
`;

const SDropFiles = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
`;

const SEditImage = styled.div<{ height?: string }>`
    display: grid;
    grid-template-columns: auto min-content min-content;
    height: 100%;
    align-items: center;
    grid-gap: 0.625rem;
`;

const SImage = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: ${(p) => p.theme.colors.neutral["10"]};
`;

interface IProps {
    image?: string;
    fileHandler?(file: any): void;
    height?: string;
    title?: string;
    subTitle?: string;
    deleteHandler?(): void;
}

const ImageSelector: FC<IProps> = (props) => {
    const [image, setImage] = useState(props.image ?? "");
    const inputRef = useRef();
    const onDrop = useCallback((acceptedFiles) => {
        handleChange(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        setImage(props.image ?? "");
    }, [props.image]);

    function handleChange(file: any) {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = function (e) {
                //@ts-ignore
                setImage(reader.result);
            };

            reader.readAsDataURL(file);

            if (props.fileHandler) props.fileHandler(file);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    function getContent() {
        if (image)
            return (
                <SEditImage>
                    <SImage>
                        <Image image={image} contain />
                    </SImage>
                    <div />
                    <Button
                        icon={icons.edit}
                        onClick={() => {
                            //@ts-ignore
                            inputRef?.current?.click();
                        }}
                    />
                    <input
                        accept=".png,.jpg,.jpeg"
                        type="file"
                        //@ts-ignore
                        ref={inputRef}
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e?.target?.files?.[0])}
                    />
                    <Button
                        icon={icons.delete}
                        onClick={() => {
                            setImage("");
                            props.deleteHandler && props.deleteHandler();
                        }}
                    />
                </SEditImage>
            );

        return (
            <SDropFiles {...getRootProps()}>
                <div>
                    <input {...getInputProps()} />
                    <Text H5 semiBold center>
                        Drag and drop
                    </Text>
                    <SText>
                        <Text H5 bold medium>
                            or
                        </Text>
                        <Text H5 bold primary onClick={() => {}}>
                            browse
                        </Text>
                        <Text H5 bold medium>
                            to choose image
                        </Text>
                    </SText>
                </div>
            </SDropFiles>
        );
    }

    let content = <SImageSelector {...props}>{getContent()}</SImageSelector>;

    if (props.title || props.subTitle)
        content = (
            <SImageSelectorContainer>
                {props.title && (
                    <Text H5 semiBold oneline>
                        {props.title}
                    </Text>
                )}
                {props.subTitle && (
                    <Text H5 semiBold medium>
                        {props.subTitle}
                    </Text>
                )}
                {content}
            </SImageSelectorContainer>
        );

    return content;
};

export default ImageSelector;
