import firebase from "firebase";
import "firebase/auth";

import { firebaseConfig } from "../brand";
import { IOrganization } from "../store/scheme";

// Main Firebase App. Contains Organizations and Auth.
firebase.initializeApp(firebaseConfig);

// Organization specific firebase app.
export let organizationFirebaseApp: firebase.app.App;
export let organizationDatabase: firebase.database.Database;
export let organizationStorage: firebase.storage.Storage;

export function setOrganizationApp(organization: IOrganization) {
    const config = { ...firebaseConfig };
    config.databaseURL = `https://${organization?.firebaseConfig?.database}.firebaseio.com`;
    config.storageBucket = organization?.firebaseConfig?.storage ?? "";

    try {
        organizationFirebaseApp = firebase.app(organization.id);
    } catch {
        organizationFirebaseApp = firebase.initializeApp(
            config,
            organization.id
        );
    }

    organizationDatabase = organizationFirebaseApp.database();
    organizationStorage = organizationFirebaseApp.storage();
}

// App used for registering users.
export const userRegisterApp = firebase.initializeApp(
    firebaseConfig,
    "USER-REGISTER-APP"
);

export default firebase;
