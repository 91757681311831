import { FC } from "react";
import styled from "styled-components";
import { SPageHolder } from "..";
import { AnalyticsCount, Title } from "../../components/Common";
import { Text } from "../../components/UI";
import { useOrganization, useStore } from "../../hooks";
import { IOrganization } from "../../store/scheme";

const SOverview = styled.div`
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    padding-bottom: 5rem;
`;

export const SRow = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${(p) => p.columns}, 1fr);
    grid-gap: 1.5rem;
`;

const Overview: FC<{ organization: IOrganization }> = ({ organization }) => {
    const organizationManager = useOrganization();
    const store = useStore();

    return (
        <SPageHolder>
            <Title>{organization.name}</Title>

            <SOverview>
                <SRow columns={4}>
                    <AnalyticsCount
                        hole
                        amount={
                            Object.keys(organizationManager?.applications ?? {})
                                .length
                        }
                    >
                        Total applications
                    </AnalyticsCount>
                    <AnalyticsCount
                        hole
                        amount={
                            Object.values(
                                organizationManager?.applications ?? {}
                            ).length
                        }
                    >
                        Visible applications
                    </AnalyticsCount>
                    <AnalyticsCount
                        hole
                        amount={Object.keys(store.state.user).length}
                    >
                        Total users
                    </AnalyticsCount>
                    <AnalyticsCount hole amount={0}>
                        Time spent
                    </AnalyticsCount>
                </SRow>

                <SRow columns={2}>
                    <Installs />
                </SRow>
            </SOverview>
        </SPageHolder>
    );
};

const SCard = styled.div`
    display: grid;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    padding: 0.5rem 1.25rem;
    border-radius: 10px;
    padding-bottom: 2rem;
`;

const SInstallRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
`;

const SInstallRowGrid = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    padding: 0.75rem;
`;

const Installs: FC = (props) => {
    function getInstalls() {
        const TEMP = [
            { name: "Android", value: 0 },
            { name: "iOS", value: 0 },
            { name: "Desktop", value: 0 },
        ];

        return TEMP.map((platform) => (
            <SInstallRow key={platform.name}>
                <SInstallRowGrid>
                    <Text middle H6>
                        {platform.name}
                    </Text>
                    <Text middle H6>
                        {platform.value}
                    </Text>
                </SInstallRowGrid>
            </SInstallRow>
        ));
    }

    return (
        <SCard>
            <SInstallRow>
                <Text middle H4 semiBold>
                    Installs
                </Text>
            </SInstallRow>
            {getInstalls()}
        </SCard>
    );
};

export default Overview;
