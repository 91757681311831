import React, { FC, useEffect } from "react";
import useState from "react-usestateref";
import { useHistory } from "react-router-dom";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { TContentTypes } from "../store/scheme";

const Router = React.createContext({});

export enum Paths {
    home = "/",

    OrganizationAnalytics = "/organisation-analytics",
    OrganizationSettings = "/organisation-settings",
    OrganizationTheme = "/organisation-theme",
    UserManagment = "/user-managment",
    AssetsVideos = "/assets-videos",
    AssetsImages = "/assets-images",
    UserProfile = "/user-profile",
    Analytics = "/analytics",
    Store = "/store",
    Applications = "/applications",
    ApplicationOverview = "/application-overview",
    ApplicationTheme = "/application-theme",
    ApplicationAnalytics = "/application-analytics",
    ApplicationLicenses = "/application-licenses",
    ApplicationLicense = "/application-license",
    ExperienceOverview = "/experience-overview",
    ExperienceAnalitycs = "/experience-analitycs",
}

interface IState {
    path: Paths; // Current path.
    organization?: string; // Selected organization.
}

interface IRouterZuStand {
    state: IState;
    save: (s: IState) => void;
}

export const useRouterZuState = create<IRouterZuStand>()(
    devtools(
        persist(
            (set) => ({
                state: { path: Paths.home },
                save: (s) => set((state) => ({ ...state, state: s })),
            }),
            {
                name: "router-storage",
            }
        )
    )
);

export type TParam =
    | "application"
    | "license"
    | "collection"
    | "collection-parent"
    | "user"
    | "activity-resutl"
    | "experience-image"
    | "experience-video"
    | "experience-interactive"
    | "experience-virtual-tour";

export const getExperienceParam = (experience: TContentTypes): TParam => {
    if (experience.type == "IExperienceImage") return "experience-image";
    if (experience.type == "IExperienceVideo") return "experience-video";
    if (experience.type == "IExperienceInteractive")
        return "experience-interactive";
    if (experience.type == "IExperienceVirtualTour")
        return "experience-virtual-tour";

    return "experience-image";
};

export interface IRouter {
    state: IState;
    setPath(path: Paths, keepParams?: boolean): void; // Set new url path. (Can keep params or reset them)
    setOrganization(path?: string): void; // Sets organization path.
    getParam(param: TParam): string; // Get param by name.
    setParam(param: TParam, value?: string | number): void; // Set params.
    back(): void; // Same as browser back.
}

function getState(pathname: string): IState {
    const state: IState = { path: Paths.home };
    const parts = pathname.split("/");

    const get = (index: number) => `/${parts[index]}`;

    if (parts.length === 3) {
        state.path = get(2) as Paths;
        state.organization = get(1);
    } else state.path = get(1) as Paths;

    return state;
}

export const RouterContext: FC = (props) => {
    const history = useHistory();
    const [state, set, stateRef] = useState<IState>({
        // @ts-ignore
        path: history.location.pathname,
    });
    const zustate = useRouterZuState();

    useEffect(() => {
        set(getState(window.location.pathname));
    }, [window.location.pathname]);

    useEffect(() => {
        zustate.save(state);
    }, [state]);

    const value: IRouter = {
        state: stateRef?.current ?? {},
        setPath(path, keepParams = false) {
            let pathWithParams: string = path;

            if (keepParams) pathWithParams += history.location.search;

            history.push(
                (stateRef?.current?.organization ?? "") + pathWithParams
            );

            set((p) => ({ ...p, path }));
        },
        setOrganization(organization) {
            if (organization)
                history.push(`/${organization}${stateRef?.current?.path}`);
            else history.push("");
        },
        getParam(param) {
            try {
                return (
                    new URLSearchParams(history.location.search).get(param) ??
                    ""
                );
            } catch {
                return "";
            }
        },
        setParam(param, value) {
            try {
                const params = new URLSearchParams(history.location.search);
                if (value) params.set(param, value.toString());
                else params.delete(param);

                history.push(
                    `${history.location.pathname}?${params.toString()}`
                );
            } catch {}
        },
        back() {
            history.goBack();
        },
    };

    return <Router.Provider value={value}>{props.children}</Router.Provider>;
};

export default Router;
