import { FC, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import { Paths } from "../context/RouterContext";

import { useAuth, useOrganization, useRouter, useStore } from "../hooks";
import { IOrganization } from "../store/scheme";
import { Button, Image, Text } from "./UI";

import logoImage from "../brand/logo.png";

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
	}
`;

const SOrganizationSelector = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${(p) => p.theme.colors.main.primary};

    display: grid;
    place-items: center;
`;

const SContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
`;

const SPanel = styled.div`
    padding: 1.25rem;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 0.625rem;

    width: 18.875rem;
    overflow: auto;

    // Animation.
    animation: ${fadeInAnimation} ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    height: 50vh;
    display: grid;
    grid-template-rows: auto min-content;
`;

const SScroller = styled.div`
    overflow: auto;
    overflow-x: hidden;
`;

const SGrid = styled.div`
    display: grid;
    grid-gap: 1.375rem;
    height: min-content;
`;

const SOrganization = styled.div<{ selected: boolean }>`
    cursor: pointer;
    display: grid;

    transition: 0.3s;
    :hover {
        transform: translateX(1rem);
    }

    border-radius: 10px;
    padding: 0.5rem;

    ${(p) =>
        p.selected &&
        css`
            background-color: ${(p) => p.theme.colors.main.primary}20;
        `}
`;

const SLogo = styled.div`
    height: 3rem;
`;

const OrganizationSelector: FC = (props) => {
    const organization = useOrganization();
    const store = useStore();
    const router = useRouter();
    const ref = useRef();
    const auth = useAuth();

    function clickHandler(org: IOrganization): void {
        router.setPath(Paths.OrganizationAnalytics);

        router.setOrganization(org?.id?.toString() ?? "");

        organization.setIsPanelActive(false);
    }

    function getOrganizations() {
        return Object.values(store.state.organization ?? {}).map((org) => (
            <SOrganization
                key={org.id}
                onClick={() => clickHandler(org)}
                selected={organization?.organization?.id === org.id}
            >
                <Text primary H4 semiBold oneline>
                    {org.name}
                </Text>
                <Text medium H5 semiBold oneline>
                    {Object.values(org?.applications ?? {}).length ?? 0}{" "}
                    Application
                    {Object.values(org?.applications ?? {}).length != 1
                        ? "s"
                        : ""}
                </Text>
            </SOrganization>
        ));
    }

    if (!organization.isPanelActive) return null;

    return (
        <SOrganizationSelector>
            <SContainer>
                <SLogo>
                    <Image contain image={logoImage} />
                </SLogo>
                <Text oneline H4 light center>
                    Select Organisation
                </Text>
                {/* @ts-ignore */}
                <SPanel ref={ref}>
                    <SScroller>
                        <SGrid>{getOrganizations()}</SGrid>
                    </SScroller>
                    <Button onClick={() => auth.logOut()}>Log out</Button>
                </SPanel>
            </SContainer>
        </SOrganizationSelector>
    );
};

export default OrganizationSelector;
