import { FC, useRef, useState } from "react";
import styled from "styled-components";
import icons from "../../assets/icons";
import { SScroll, Title } from "../../components/Common";
import Confirm from "../../components/modal/Confirm";
import { InputField, SVG, Text } from "../../components/UI";
import { useModal, useNotification, useRouter, useStore } from "../../hooks";
import { NEW_IDENTIFIER } from "../../store";
import {
    ESeatType,
    getExperienceTypeData,
    IApplication,
    ILicense,
    TContentTypes,
} from "../../store/scheme";
import { SPageHolder } from "..";
import { Paths } from "../../context/RouterContext";
import { getSortedExperiences } from "./Overview";

const SLicenses = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content min-content min-content auto;
    min-height: 0;
`;

const SSearch = styled.div`
    display: grid;
    grid-template-columns: auto 20rem;
    padding: 0.5rem;
`;

const STopRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-template-columns: 2fr 1fr 2fr 1fr 3rem;
    grid-gap: 0.5rem;
`;

const Licenses: FC<{ application: IApplication }> = ({ application }) => {
    const [search, setSearch] = useState("");
    const router = useRouter();

    function newHandler() {
        router.setParam("license", NEW_IDENTIFIER);
        router.setPath(Paths.ApplicationLicense, true);
    }

    function getLicenses() {
        return Object.values(application.licenses ?? {}).map((license) => (
            <LicenseItem
                key={license.id}
                license={license}
                application={application}
            />
        ));
    }

    return (
        <SLicenses>
            <Title buttonText="New" buttonAction={() => newHandler()}>
                Licenses
            </Title>
            <SSearch>
                <div />
                <InputField
                    value={search}
                    onChange={(v) => setSearch(v)}
                    placeholder="Search code"
                    icon={icons.search}
                />
            </SSearch>
            <STopRow>
                <Text middle H6 semiBold>
                    Name ↓
                </Text>
                <Text middle H6 semiBold>
                    Code
                </Text>
                <Text middle H6 semiBold>
                    Experiences
                </Text>
                <Text middle H6 semiBold>
                    Seats
                </Text>
                <Text middle H6 semiBold>
                    Delete
                </Text>
            </STopRow>
            <SScroll padding="0">{getLicenses()}</SScroll>
        </SLicenses>
    );
};

const SIconButton = styled.div`
    width: 34px;
    height: 34px;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
        * {
            background-color: ${(p) => p.theme.colors.main.primary};
        }
    }
`;

const SLicenseItem = styled.div`
    min-height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 18px 0.75rem;
    grid-gap: 0.5rem;
    grid-template-columns: 6fr 3rem;
    align-items: center;

    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;

        ${SIconButton} {
            opacity: 1;
        }
    }
`;

const SLicenseClick = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const SRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: min-content;
    height: 100%;
    place-items: center;
`;

const LicenseItem: FC<{ license: ILicense; application: IApplication }> = ({
    license,
    application,
}) => {
    const modal = useModal();
    const store = useStore();
    const router = useRouter();

    function confirmDelete(): void {
        function removeLicenseFromExperiences(): void {
            const experiences: TContentTypes[] = getSortedExperiences(
                application,
                store
            );

            experiences.forEach((e) => {
                if ((license.id ?? "") in (e.licenseIds ?? {})) {
                    const payload: TContentTypes = { ...e };
                    delete (payload.licenseIds ?? {})[license.id ?? ""];
                    store.dispatch({
                        action: "set",
                        type: getExperienceTypeData(payload).action,
                        object: payload,
                    });
                }
            });
        }

        function removeLicenseFromApplication(): void {
            const payload = { ...application };
            delete (payload?.licenses ?? {})[license?.id ?? ""];
            store.dispatch({
                action: "set",
                type: "application",
                object: payload,
            });
        }

        removeLicenseFromExperiences();
        removeLicenseFromApplication();
    }

    function deleteHandler(): void {
        // Show confirmation panel.

        modal.setContent(
            <Confirm
                title="Remove License"
                subTitle={`Remove "${license.name}"."`}
                icon={icons.delete}
                confirmHandler={() => confirmDelete()}
            />
        );

        modal.setActive(true);
    }

    function editHandler(): void {
        router.setParam("license", license.id);
        router.setPath(Paths.ApplicationLicense, true);
    }

    function getExperiences(): string {
        const experiencesWithLicense: TContentTypes[] = getSortedExperiences(
            application,
            store
        ).filter((e) => (license.id ?? "") in (e.licenseIds ?? {}));

        let text = "";
        experiencesWithLicense.forEach((e) => (text += `- ${e.name}\n`));

        return text;
    }

    return (
        <SLicenseItem>
            <SLicenseClick onClick={() => editHandler()}>
                <Text middle H6 oneline>
                    {license.name}
                </Text>
                <Text middle H6>
                    {license.code}
                </Text>
                <Text middle H6>
                    {getExperiences()}
                </Text>
                <Text middle H6>
                    {
                        Object.values(license.seats ?? {}).filter(
                            (s) => s.type === ESeatType.valid
                        ).length
                    }
                    {" | "}
                    {license.seatsAvailable}
                </Text>
            </SLicenseClick>
            <SRow>
                <SIconButton onClick={() => deleteHandler()}>
                    <SVG medium contain image={icons.delete} />
                </SIconButton>
            </SRow>
        </SLicenseItem>
    );
};

export default Licenses;
