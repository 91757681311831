import React from "react";
import styled from "styled-components";
import { SScroll } from "../components/Common";

import Navbar from "../components/Navbar";
import { Paths } from "../context/RouterContext";
import { useOrganization, useRouter } from "../hooks";

import Overview from "./organization/Overview";
import Settings from "./organization/Settings";
import Theme from "./organization/Theme";

const SHome = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
`;

const Home = () => {
    const router = useRouter();
    const organizationContext = useOrganization();
    const organization = organizationContext.organization ?? {};

    const tabs = {
        [Paths.OrganizationAnalytics]: {
            name: "Overview",
            path: Paths.OrganizationAnalytics,
            content: <Overview organization={organization} />,
        },
        [Paths.OrganizationSettings]: {
            name: "Organisation Settings",
            path: Paths.OrganizationSettings,
            content: <Settings organization={organization} />,
        },
        [Paths.OrganizationTheme]: {
            name: "Theme",
            path: Paths.OrganizationTheme,
            content: <Theme organization={organization} />,
        },
    };

    return (
        <SHome>
            <Navbar tabs={Object.values(tabs)} />
            {/* @ts-ignore */}
            <SScroll>{tabs?.[router.state.path]?.content}</SScroll>
        </SHome>
    );
};

export default Home;
