import React, { useReducer, FC } from "react";
import IAction, { ICallbackAction } from "./actions";
import {
    Dictionary,
    IApplication,
    IOrganization,
    IExperienceImage,
    IExperienceVideo,
    IExperienceInteractive,
    IExperienceVirtualTour,
    IAsset,
    ICollection,
} from "./scheme";
import { IUser } from "../context/AuthContext";
import { getContent, setContent } from "../firebase/database";
import { key } from "firebase-key";

// Use to debug, if true, it wont save changes to the database.
const READ_ONLY = false;

export const NEW_IDENTIFIER = "new_";

export interface IState {
    loading: boolean;

    experienceImage: Dictionary<IExperienceImage>;
    experienceVideo: Dictionary<IExperienceVideo>;
    experienceInteractive: Dictionary<IExperienceInteractive>;
    experienceVirtualTour: Dictionary<IExperienceVirtualTour>;

    collection: Dictionary<ICollection>;

    assetVideo: Dictionary<IAsset>;
    assetImage: Dictionary<IAsset>;

    application: Dictionary<IApplication>;
    organization: Dictionary<IOrganization>;

    user: Dictionary<IUser>;
}

const defaultState: IState = {
    loading: false,

    experienceImage: {},
    experienceVideo: {},
    experienceInteractive: {},
    experienceVirtualTour: {},

    collection: {},

    assetVideo: {},
    assetImage: {},

    application: {},
    organization: {},
    user: {},
};

interface ILocalActions {
    init(): Promise<void>;
    getOrganizations(): Promise<void>;
}

export interface IStore {
    state: IState;
    dispatch(action: IAction): void;
    localActions: ILocalActions;
}

const Store = React.createContext<IStore>({
    state: defaultState,
    dispatch(action: IAction) {},
    localActions: {
        async init() {},
        async getOrganizations() {},
    },
});

// Handle dictionary object changes.
function dictionaryReducer(
    dictionary: Dictionary<any>,
    action: ICallbackAction,
    isOrganizationDatabase: boolean = true
): Dictionary<any> {
    function alert(message: string, type: "loading" | "ok" | "error"): void {
        window.Treeview?.notification?.killType("loading");
        window.Treeview?.notification?.notify(
            message.toString(),
            type,
            type != "loading"
        );
    }

    switch (action.action) {
        case "set":
            if (!action.local) {
                // Only if is not a local dispatch.
                if (
                    !action.object.id ||
                    action.object.id.includes(NEW_IDENTIFIER)
                ) {
                    // Is new object, use add.
                    // Add and save new generated id to local store.
                    if (!READ_ONLY) {
                        alert("Saving changes", "loading");

                        action.object.id = key(); // Assign new key.
                        setContent(
                            `${action.type}/${action.object.id}`,
                            action.object,
                            isOrganizationDatabase
                        ).then(() => {
                            alert("Changes saved", "ok");
                            // Return new added item.
                            if (action.onAdd) action.onAdd(action.object);
                        });
                    } else if (action.onAdd) {
                        // Debug when is read only.
                        alert("Changes saved", "ok");
                        action.object.id = key();
                        action.onAdd(action.object);
                    }
                } else if (!READ_ONLY) {
                    // Is an edit, use update.
                    alert("Saving changes", "loading");

                    setContent(
                        `${action.type}/${action.object.id}`,
                        action.object,
                        isOrganizationDatabase
                    ).then((item) => {
                        alert("Changes saved", "ok");
                        // Return new added item.
                        if (action.onUpdate) action.onUpdate(item);
                    });
                }
            }

            // Local
            if (action?.object?.id)
                dictionary[action.object.id] = action.object;

            break;
        case "delete":
            if (!action?.object?.id)
                alert("Can't delete object with no id", "error");
            else {
                // Api
                if (!READ_ONLY && !action.local) {
                    alert("Saving changes", "loading");

                    setContent(
                        `${action.type}/${action.object.id}`,
                        {},
                        isOrganizationDatabase
                    ).then(() => alert("Changes saved", "ok"));
                }

                // Local
                delete dictionary[action.object.id];
            }
            break;
    }

    return dictionary;
}

function reducer(state: IState, action: ICallbackAction): IState {
    switch (action.type) {
        case "init":
            return { ...state, ...action.object };
        case "application":
            return {
                ...state,
                application: dictionaryReducer(state.application, action),
            };
        case "experience-image":
            return {
                ...state,
                experienceImage: dictionaryReducer(
                    state.experienceImage,
                    action
                ),
            };
        case "experience-video":
            return {
                ...state,
                experienceVideo: dictionaryReducer(
                    state.experienceVideo,
                    action
                ),
            };
        case "experience-interactive":
            return {
                ...state,
                experienceInteractive: dictionaryReducer(
                    state.experienceInteractive,
                    action
                ),
            };
        case "experience-virtual-tour":
            return {
                ...state,
                experienceVirtualTour: dictionaryReducer(
                    state.experienceVirtualTour,
                    action
                ),
            };
        case "collection":
            return {
                ...state,
                collection: dictionaryReducer(state.collection, action),
            };
        case "asset-video":
            return {
                ...state,
                assetVideo: dictionaryReducer(state.assetVideo, action),
            };
        case "asset-image":
            return {
                ...state,
                assetImage: dictionaryReducer(state.assetImage, action),
            };
        case "organization":
            return {
                ...state,
                organization: dictionaryReducer(
                    state.organization,
                    action,
                    false
                ),
            };
        case "user":
            return { ...state, user: dictionaryReducer(state.user, action) };
    }
}

export const StoreContext: FC = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    function callbackDispatch(action: IAction) {
        const callbackAction: ICallbackAction = {
            ...action,
            dispatch,
        };

        dispatch(callbackAction);
    }

    const localActions: ILocalActions = {
        // Init store data.
        async init() {
            callbackDispatch({ type: "init", object: { loading: true } }); // Show fetching data.
            const [
                application,
                experienceImage,
                experienceVideo,
                experienceInteractive,
                experienceVirtualTour,
                collection,
                assetVideo,
                assetImage,
            ] = await Promise.all([
                getContent("application"),

                getContent("experience-image"),
                getContent("experience-video"),
                getContent("experience-interactive"),
                getContent("experience-virtual-tour"),

                getContent("collection"),

                getContent("asset-video"),
                getContent("asset-image"),
            ]);

            callbackDispatch({
                type: "init",
                object: {
                    loading: false,
                    application: application ?? {},

                    experienceImage: experienceImage ?? {},
                    experienceVideo: experienceVideo ?? {},
                    experienceInteractive: experienceInteractive ?? {},
                    experienceVirtualTour: experienceVirtualTour ?? {},

                    collection: collection ?? {},

                    assetVideo: assetVideo ?? {},
                    assetImage: assetImage ?? {},
                },
            });
        },
        async getOrganizations() {
            callbackDispatch({ type: "init", object: { loading: true } }); // Show fetching data.
            const [organization] = await Promise.all([
                getContent("organization", false),
            ]);
            callbackDispatch({
                type: "init",
                object: {
                    loading: false,
                    organization: organization ?? {},
                },
            });
        },
    };

    return (
        <Store.Provider
            value={{ state, dispatch: callbackDispatch, localActions }}
        >
            {props.children}
        </Store.Provider>
    );
};

export default Store;
