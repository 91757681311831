import React, { useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import { useModal, useOutsideAlerter } from "../../hooks";

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
	}
`;

const SModal = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${(p) => p.theme.colors.text.dark}90;

    display: grid;
    place-items: center;
`;

export const SPanel = styled.div`
    padding: 1.25rem;
    background-color: ${(p) => p.theme.colors.neutral["00"]};
    border-radius: 0.625rem;

    min-width: 19rem;

    // Animation.
    animation: ${fadeInAnimation} ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    overflow: hidden;
`;

const Modal = () => {
    const modal = useModal();
    const ref = useRef();
    useOutsideAlerter(ref, () => modal.setActive(false));

    const content = (
        <SModal>
            <SPanel
                style={{ height: modal.state.height, width: modal.state.width }}
                // @ts-ignore
                ref={ref}
            >
                {modal.state.content}
            </SPanel>
        </SModal>
    );

    return modal?.state?.active ? content : null;
};

export default Modal;
