import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

// Theme.
import { ThemeProvider } from 'styled-components'
import { ThemeContext } from './context/ThemeContext'
import { useTheme } from './hooks'

function Container() {
	const theme = useTheme()

	return (
		<ThemeProvider theme={theme.theme}>
			<App />
		</ThemeProvider>
	)
}

function ThemeContextContainer() {
	return (
		<ThemeContext>
			<Container />
		</ThemeContext>
	)
}

ReactDOM.render(<React.StrictMode> <ThemeContextContainer /> </React.StrictMode>, document.getElementById('root'))
