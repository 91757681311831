import React, { FC } from "react";
import styled from "styled-components";
import { SPageHolder } from ".";
import icons from "../assets/icons";
import { SScroll, Title } from "../components/Common";
import Confirm from "../components/modal/Confirm";
import { Image, SVG, Text } from "../components/UI";
import { IUser } from "../context/AuthContext";
import { Paths } from "../context/RouterContext";
import { useModal, useOrganization, useRouter, useStore } from "../hooks";
import { NEW_IDENTIFIER } from "../store";
import { ERole, IOrganizationRole, RoleData } from "../store/scheme";

const SUserManagment = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: min-content auto;
    min-height: 0;
`;

const SPage = styled.div`
    min-height: 0;
    height: inherit;
    padding: 0 8.875rem;
    display: grid;
    grid-template-rows: min-content min-content auto;
`;

const STopRow = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-template-columns: 1fr 1fr 1fr 3rem;
    grid-gap: 0.5rem;
`;

const STitle = styled.div`
    height: 3rem;
    padding: 0 1.438rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
`;

const UserManagment: FC = (props) => {
    const store = useStore();
    const router = useRouter();
    const organization = useOrganization();

    function newHandler(): void {
        router.setParam("user", NEW_IDENTIFIER);
        router.setPath(Paths.UserProfile, true);
    }

    function getUsers() {
        // if (organization?.organization?.id)
        //     return Object.values(store.state.organizationRole ?? {})
        //         .filter(
        //             (role) =>
        //                 //@ts-ignore
        //                 role.refOrganization == organization.organization.id
        //         )
        //         .map((role) => ({
        //             role,
        //             //@ts-ignore
        //             user: store.state.user[role.refUser],
        //         }))
        //         .filter((data) => data.user && !data.user.root)
        //         .map((data) => (
        //             <UserTag
        //                 key={data.user.id}
        //                 user={data.user}
        //                 role={data.role}
        //             />
        //         ));
    }

    return (
        <SUserManagment>
            <STitle>
                <Text H5 bold middle>
                    User Managment
                </Text>
            </STitle>
            <SPageHolder>
                <SPage>
                    <Title buttonText="New" buttonAction={() => newHandler()}>
                        Users
                    </Title>
                    <STopRow>
                        <Text middle H6 semiBold>
                            Name
                        </Text>
                        <Text middle H6 semiBold>
                            Email
                        </Text>
                        <Text middle H6 semiBold>
                            Role
                        </Text>
                        <Text middle H6 semiBold>
                            Delete
                        </Text>
                    </STopRow>
                    <SScroll padding="0">
                        {getUsers()}
                        <div style={{ height: "5rem" }} />
                    </SScroll>
                </SPage>
            </SPageHolder>
        </SUserManagment>
    );
};

const SClose = styled.div`
    width: 3rem;
    height: 50%;
    cursor: pointer;
    opacity: 0;
`;

const SUserTag = styled.div`
    height: 3rem;
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.neutral["20"]};
    display: grid;
    padding: 0 0.75rem;
    grid-gap: 0.5rem;
    grid-template-columns: auto 3rem;
    align-items: center;

    :hover {
        background-color: ${(p) => p.theme.colors.main.primary}10;

        ${SClose} {
            opacity: 1;
        }
    }
`;

const SUserClick = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

const SName = styled.div`
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: min-content auto;
`;

const SProfile = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${(p) => p.theme.colors.neutral["20"]};
`;

const UserTag: FC<{ user: IUser; role: IOrganizationRole }> = ({
    user,
    role,
}) => {
    const router = useRouter();
    const modal = useModal();
    const store = useStore();

    function clickHandler(): void {
        router.setParam("user", user.id);
        router.setPath(Paths.UserProfile, true);
    }

    function deleteHandler(): void {
        // Show confirmation panel.
        // modal.setContent(
        //     <Confirm
        //         title="Remove User"
        //         subTitle={`Remove "${user.firstName} ${user.lastName}" from the organisation."`}
        //         icon={icons.delete}
        //         confirmHandler={() => {
        //             store.dispatch({
        //                 action: "delete",
        //                 type: "organizationRole",
        //                 object: role,
        //             });
        //         }}
        //     />
        // );
        modal.setActive(true);
    }

    return (
        <SUserTag>
            <SUserClick onClick={() => clickHandler()}>
                <SName>
                    <SProfile>
                        {" "}
                        <Image image={user.profilePicture} />{" "}
                    </SProfile>
                    <Text
                        middle
                        H6
                        medium
                    >{`${user.firstName} ${user.lastName}`}</Text>
                </SName>
                <Text middle H6 medium>
                    {user.email}
                </Text>
                <Text middle H6 medium>
                    {RoleData[role?.role ?? 0].label}
                </Text>
            </SUserClick>
            <SClose onClick={() => deleteHandler()}>
                <SVG medium contain image={icons.close} />
            </SClose>
        </SUserTag>
    );
};

export default UserManagment;
