import React, { FC } from "react";
import styled from "styled-components";
import { IExperience } from "../../../store/scheme";

const SAnalytics = styled.div`
    display: grid;
    padding: 1rem 0;
`;

const SViewport = styled.div`
    height: 50rem;
    * {
        width: 100%;
        height: 100%;
    }
`;

const Analytics: FC<{ experience: IExperience }> = ({ experience }) => {
    return (
        <SAnalytics>
            <SViewport>
                <iframe src={experience.analyticsUrl} frameBorder="0" />
            </SViewport>
        </SAnalytics>
    );
};

export default Analytics;
